@import "../../../styles/mixins/global.scss";

.trade-agreement-problems-section {
    padding: 126px 0 118px 0;

    .trade-agreement-problem-alignment {
        .trade-agreement-problem-heading {
            text-align: center;

            h4 {
                color: #000;
                font-size: 40px;
                font-weight: 700;
                line-height: 46px;
                opacity: 0.8;
            }
        }

        .trade-agreement-problem-body-alignment {
            padding: 64px 0 0 0;

            .trade-agreement-problem-flex-alignment {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                align-items: center;
                justify-content: space-between;
                padding: 100px 0 0 0;

                @media (max-width: 668px) {
                    grid-template-columns: 1fr;
                }

                &:first-child {
                    padding: 0;
                }

                .trade-agreement-problem-box-alignment {
                    max-width: 407px;
                    height: 316px;
                    border-radius: 10px;
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                .trade-agreement-problem-all-detaild {
                    max-width: 578px;

                    h6 {
                        color: #000;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 36px;
                        opacity: 0.8;
                    }

                    p {
                        color: #000;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        text-transform: capitalize;
                        padding: 30px 0 0 0;
                    }
                }
            }
        }
    }
}