@import '../../../styles/mixins/global.scss';

.our-advantag-section {
    padding: 241px 0 0 0;
    @include breakpoint("max-sm") {
        padding: 40px 0 0 0;
    }
    .our-advantag-heading {
        h5 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 48px;
            text-align: center;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .our-advantag-details-alignment {
        padding: 121px 0 0 0;
        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }
        .our-advantag-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 80px;
            align-items: center;
            margin: 0 0 172px 0;
            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
                margin: 0 0 60px 0;
            }
            &:last-child {
                margin: 0;
            }

            .our-advantag-grid-item {
                .our-advantag-img {
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }

                }

                .our-advantag-all-child-details {
                    text-align: center;

                    h6 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 30px;
                        font-weight: 700;
                        line-height: 39px;

                        @include breakpoint("max-sm") {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    p {
                        padding: 24px 0 0 0;
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            line-height: 24px   ;
                        }
                    }
                }
            }
        }
    }
}