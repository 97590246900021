@import '../../../styles/mixins/global.scss';

.services-details-section {
    padding: 150px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 0;
    }

    .services-details-heading-alignment {
        h4 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 48px;
            text-align: center;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }


    .services-all-details-alignment {
        padding: 165px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 80px 20px;
        flex-wrap: wrap;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }

        .services-all-details-box {
            border-radius: 100px 100px 0px 0px;
            background: #FFF;
            box-shadow: 5px -5px 15px 0px rgba(0, 0, 0, 0.25) inset, -5px 5px 15px 0px rgba(0, 0, 0, 0.25) inset;
            width: 367px;
            min-width: 367px;
            max-width: 367px;
            height: 245px;
            padding: 14px 52px 21px 45px;
            text-align: center;

            @include breakpoint("max-sm") {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }


            h5 {
                padding: 14px 0 0 0;
                color: var(--theme-heading-text-color);
               font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
            }

            p {
                padding: 29px 0 0 0;
                color: var(--theme-heading-text-color);
                text-align: center;
               font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                text-align: center;
            }
        }
    }
}