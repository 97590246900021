@import "../../../styles/mixins/global.scss";

.logistic-map-option-alignment {
    .logistic-map-back-alignment {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        p {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .logistic-map-body-details-alignment {
        padding: 24px 8px 0 8px;

        .logistic-all-details-box {
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
            width: 100%;
            padding: 16px 12px;
            margin: 0 0 20px 0;

            .logistic-bottom-border-alignment {
                border-bottom: 1px solid rgba(0, 0, 0, 0.6);
                padding: 0 0 12px 0;

                .logistic-all-details-grid {
                    display: grid;
                    grid-template-columns: 98px 1fr;
                    gap: 60px;

                    .logistic-all-road-name-alignment {
                        h6 {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            padding: 0 0 14px 0;
                        }
                    }

                    .logistic-map-all-details-alignment {
                        .logistic-map-name-alignment {
                            display: flex;
                            align-items: center;
                            gap: 9px;

                            h6 {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                padding: 0;
                            }

                            .logistic-right-arrow {
                                width: 24px;
                                height: 24px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                }
                            }
                        }

                        .logistic-map-bottom-details {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 15px 0 0 0;

                            .logistic-date-alignemnt {
                                p {
                                    color: var(--theme-heading-text-color);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 10px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    border-radius: 15px;
                                    background: #D9D9D9;
                                    padding: 4px 9px;
                                }
                            }

                            .logistic-next-arrow {
                                margin: 0 10px 0 0;

                                img {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .logistic-map-details-alignment {
                    padding: 10px 0 0 0;

                    .logistic-map-box-alignment {
                        border-radius: 10px;
                        background: rgba(221, 246, 255, 0.50);
                        padding: 8px 6px;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        width: max-content;

                        p {
                            color: rgba(0, 174, 239, 0.8);
                            font-family: 'Poppins', sans-serif;
                            font-size: 8px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }

            .logistic-map-routes-details-alignment {
                padding: 20px 0 15px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.6);

                .logistic-map-rountes-heading-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .logistic-map-rountes-body-alignment {
                    padding: 18px 0 0 0;

                    .logistic-map-rountes-grid-alignment {
                        display: grid;
                        grid-template-columns: 20px 1fr;
                        gap: 21px;
                        margin: 0 0 52px 0;

                        &:last-child {
                            margin: 0;
                        }

                        .logistic-map-rountes-grid-item {
                            position: relative;

                            .logistic-map-left-side-alignment {
                                width: 20px;
                                height: 20px;
                                border: 2px solid #6F6F6F;
                                border-radius: 50%;
                            }

                            .logistic-line-alignment {
                                border-left: 1px dashed #6E6E6E;
                                height: 64px;
                                position: absolute;
                                top: 20px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            .logistic-second-line {
                                border-left: 3px solid #00AEEF;
                                height: 32px;
                                position: absolute;
                                top: 36px;
                                left: 50%;
                                transform: translateX(-50%);

                                &.green-line {
                                    border-left: 3px solid #00A651;
                                }
                            }

                            .logistic-map-right-side-alignment {

                                h5 {
                                    color: var(--theme-heading-text-color);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 159.1%;
                                }

                                p {
                                    color: var(--theme-heading-text-color);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 8px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 159.1%;
                                }
                            }
                        }

                    }
                }
            }

            .logistic-map-details-details {
                padding: 7px 0 40px 0;

                .logistic-map-box-alignment {
                    padding: 3px 9px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    border-radius: 10px;
                    background: rgba(221, 246, 255, 0.50);
                    max-width: 174px;

                    h6 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 159.1%;
                    }

                    p {
                        color: rgba(0, 0, 0, 0.80);
                        font-family: 'Poppins', sans-serif;
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 138.1%;
                    }
                }
            }

        }
    }
}