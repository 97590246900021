@import "../../styles/mixins/global.scss";

.press-room-section {
    .press-room-banner-alignment {
        background: #E5F3FF;

        .press-room-all-banner-details {

            width: 100%;
            height: 532px;
            display: flex;
            align-items: center;
            justify-content: space-between;


            @include breakpoint("max-sm") {
                height: auto;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
                padding: 40px 0;
            }

            .press-room-left-side-alignment {
                h4 {
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 48px;
                    text-transform: capitalize;


                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    padding: 24px 0 0 0;
                    max-width: 512px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 140.5%;


                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                        padding: 16px 0 0 0;
                    }
                }
            }

            .press-room-right-side-alignment {
                width: 334px;
                height: 354px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;


                @include breakpoint("max-sm") {
                    width: 100%;
                    height: 254px;
                }

                img {
                    height: 200px;
                    object-fit: contain;
                }
            }
        }
    }

    .press-room-success-heart-alignment {
        padding: 14px 0;

        .press-room-success-heart-flex-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 90px;


            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                display: grid;
                gap: 40px;
            }

            .press-room-success-heart-details {
                max-width: 653px;

                h6 {
                    color: #000;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 38px;
                    text-transform: capitalize;


                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    padding: 36px 0 0 0;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    .press-room-latest-news-alignment {
        @include breakpoint("max-sm") {
            padding: 40px 0;
        }

        .press-room-latest-news-flex-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 0 0 0;

            @include breakpoint("max-sm") {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            &:first-child {
                padding: 0;
            }

            .press-room-box-alignment {
                border-radius: 10px;
                border: 1px solid #00AEEF;
                background: #FFF;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 28px 24px;

                .press-room-bottom-details {
                    padding: 21px 0 0 0;

                    h6 {
                        color: #000;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                        text-transform: capitalize;
                    }

                    .date-location-alignment {
                        display: flex;
                        align-items: center;
                        gap: 36px;
                        padding: 12px 0 0 0;

                        p {
                            color: #000;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 24px;
                            text-transform: capitalize;
                            opacity: 0.8;
                        }
                    }
                }


                .press-other-details-alignment {
                    padding: 42px 0 0 0;

                    h6 {
                        color: #000;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 24px;
                    }

                    p {
                        padding: 20px 0 0 0;
                        color: #000;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 24px;
                    }

                    span {
                        display: block;
                        color: #000;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        text-transform: capitalize;
                        padding: 12px 0 0 0;
                    }
                }
            }
        }
    }
}