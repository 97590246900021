@import '../../../styles/mixins/global.scss';

.total-shipment-section {
    .total-shipment-box {
        border-radius: 8px;
        background: #EBF9FE;
        padding: 24px;

        .total-shipment-heading {
            h6 {
                color: #343434;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                text-transform: capitalize;
            }
        }

        .total-shipment-body-alignment {
            .total-shipment-top-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0 0 0;

                label {
                    color: #606060;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-transform: capitalize;
                }

                .total-shipment-left-side {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    border-radius: 4px;
                    border: 0.5px solid rgba(0, 174, 239, 0.2);
                    background: #FFF;
                    width: 114px;
                    height: 30px;

                    .total-shipment-box-details {
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        p {
                            color: #343434;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            text-transform: capitalize;
                        }
                    }

                    .kg-alignment {
                        background: rgba(0, 174, 239, 0.1);
                        border-radius: 0px 4px 4px 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;

                        p {
                            color: #343434;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            text-transform: uppercase;
                        }

                        .kg-dropdown-arrow {
                            width: 8px;
                            height: 5px;

                            img {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                                display: block;
                            }
                        }
                    }
                }
            }

            .total-shipment-range-alignment {
                padding: 30px 0 0 0;

                input[type="range"] {
                    width: 100%;
                }
            }
        }
    }
}