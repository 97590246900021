@import '../../../styles/mixins/global.scss';

.supplire-secure-payment-section {
    padding: 123px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 40px 0 0 0;
    }

    .supplire-secure-payment-heading {
        text-align: center;

        h4 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 48px;
            opacity: 0.8;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .supplire-secure-payment-details {
        padding: 94px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 94px;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
            gap: 40px;
            display: grid;
            grid-template-columns: repeat(1 , 1fr);
        }

        .supplire-secure-payment-child-details {
            .supplire-secure-box {
                width: 387px;
                max-width: 387px;
                min-width: 387px;
                height: 322px;
                border-radius: 10px;
                background: #C2E4F4;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                @include breakpoint("max-sm") {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            .supplire-secure-box-details {
                padding: 26px 0 0 0;

                p {
                    max-width: 470px;
                    margin: 0 auto;
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                .read-more-button {
                    text-align: center;
                    padding: 15px 0 0 0;

                    button {
                        border-radius: 10px;
                        background: #00AEEF;
                        width: 101px;
                        height: 30px;
                        color: #FFF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                        cursor: pointer;
                        border: none;
                    }
                }
            }
        }
    }
}