@import '../../../styles/mixins/global.scss';

.one-traking-system-section {
    padding: 60px 0 0 0;

    @include breakpoint("max-md") {
        padding: 60px 60px 0 60px;
    }

    @include breakpoint("max-sm") {
        padding: 30px 0 0 0;
    }

    .one-traking-system-grid-alignment {
        display: grid;
        grid-template-columns: 690px 1fr;
        gap: 102px;
        align-items: center;

        @media only screen and (max-width:"1099px") {
            grid-template-columns: 520px 1fr;
            gap: 70px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: 1fr;
            justify-items: center;
        }

        .one-traking-system-gridItem-alignment {
            .one-traking-system-box {
                border-radius: 8px;
                background: #FFF;
                box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.12);
                width: 100%;
                height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .one-traking-system-detaild-alignment {
                h5 {
                    color: #343434;
                   font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: normal;
                    max-width: 310px;

                    @include breakpoint("max-md") {
                        max-width: 100%;
                        text-align: center;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 24px;
                        max-width: 100%;
                    }

                }

                p {
                    padding: 24px 0 0 0;
                    color: #343434;
                   font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    opacity: 0.5;

                    @include breakpoint("max-md") {
                        text-align: center;
                    }

                    @include breakpoint("max-sm") {
                        padding: 16px 0 0 0;
                        font-size: 14px;
                        line-height: 20px;
                    }

                }
            }
        }
    }
}