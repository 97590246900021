@import "../../../styles/mixins/global.scss";


.communication-all-contnet-alignment {
    background: rgba(0, 174, 239, 0.12);
    padding: 40px 0;

    .grid {
        display: grid;
        grid-template-columns: 1fr 223px;
        gap: 97px;

        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1 , 1fr);
          gap: 40px;
          padding: 0 20px;
        }

        .grid-items {
            .mobile-image {
                img {
                    width: 100%;
                }
            }


            .all-accodin {
                .main-accodin:last-child {
                    margin: 0;
                }
                .main-accodin {
                    margin: 0 0 14px 0;
        
                    .hide {
                        max-height: 0px;
                        transition: .3s ease-in-out;
                        overflow: hidden;
                    }
        
                    .show {
                        max-height: 100vh;
                        transition: .3s ease-in-out;
                        overflow: hidden;
                    }
        
                    .rotate-img {
                        transform: rotate(45deg);
                        transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
        
                    .accodin-body {
                        span {
                            display: block;
                            padding: 10px 0 0 38px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 142.5%;
                            text-transform: capitalize;
                            color: #606060;
                            
                            
                        }
                    }
        
                    .accodin-header {
                    padding: 6px 8px;
                    background: rgba(0, 174, 239, 0.1);
                    border-radius: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                        img {
                        transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
        
                        }
                    .left-content {
                      display: grid;
                      grid-template-columns: 20px 1fr;
                      gap: 8px;
        
                      span {
                        display: block;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-transform: capitalize;
                        color: #00aeef;
                        @include breakpoint("max-sm") {
                          font-size: 14px;
                          line-height: 22px;
                        }
                      }
                    }
                  }
                }
              }
        
              .first-text {
                padding: 0 0 27px 0;
                span {
                  display: block;
                  font-weight: 500;
                  font-size: 16px;
                  padding: 0 0 12px 0;
                  line-height: 19px;
                  color: #00aeef;
                  @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
        
                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 23px;
                  text-transform: capitalize;
        
                  color: #606060;

                  @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
        
                h2 {
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 38px;
                  margin: 0 0 22px 0;
                  color: #343434;
                  @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                  }
                }
              }

        }
    }
}