@import '../../styles/mixins/global.scss';

.privacy-policy-all-contnet-alignment {
    padding: 80px 0;


    @include breakpoint("max-sm") {
        padding: 60px 0;
    }
 
    .same-text {
        padding: 0 0 40px 0;
        h4 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #222;
            margin: 0 0 20px 0;
            
            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 20px 0;
            color: #777;
            font-weight: 400;
            @include breakpoint("max-sm") {
                font-size: 14px;
            }
        }

        p:last-child {
            margin: 0;
        }
    }

    .main-text {
        padding: 0 0 60px 0;
        h1 {
            font-size: 24px;
            margin: 0 0 20px 0;
            color: #222;
            font-weight: 600;

            @include breakpoint("max-sm") {
                font-size: 20px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 20px 0;
            color: #777;
            font-weight: 400; @include breakpoint("max-sm") {
                font-size: 14px;
            }
        }

        p:last-child {
            margin: 0;
        }
    }
}