@import "../../../styles/mixins/global.scss";


.confidence-banner {
  margin: 50px 0;
  background: #00aeef;
  padding: 30px 0;
  .items-center {
    display: flex;
    align-items: center;
    justify-content: space-between;


    @include breakpoint("max-sm") {
      display: grid;
      grid-template-columns: repeat(1 , 1fr);
      gap: 40px;
    }

    div {
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        color: #ffffff;
        margin: 0 0 12px 0;

        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #ffffff;

        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 24px;

        }
      }

      button {
        border: none;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        background: #ffffff;
        border-radius: 4px;
        padding: 6px 24px;
        color: #00aeef;
      }
    }
  }
}
