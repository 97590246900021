@import '../../styles/mixins/global.scss';

.integrity-section {
    background-color: #f8f8f8;

    .integrity-alignment {
        padding: 58px 0;

        @include breakpoint("max-sm") {
            padding: 0;
        }

        .integrity-baaner-details-alignment {
            display: flex;
            align-items: center;

            @include breakpoint("max-sm") {
                display: block;
                padding: 20px 0 0 0;
            }

            .integrity-first-box {
                border-radius: 90px 2px;
                background: #B3E7FA;
                width: 680px;
                height: 389px;
                display: flex;
                align-items: center;
                padding: 16px;
                position: relative;
                z-index: 99;

                @include breakpoint("max-sm") {
                    height: 278px;
                    width: 100%;
                }

                h6 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;

                    @include breakpoint("max-sm") {
                        font-size: 18px;

                    }
                }

                p {
                    padding: 36px 0 0 0;
                    color: rgba(0, 0, 0, 0.80);
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        padding: 20px 0 0 0;

                    }
                }
            }

            .integrity-second-box {
                border-radius: 2px 90px;
                background: #FBC394;
                width: 656px;
                height: 389px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 104px 0 0;
                margin: 143px 0 0 -194px;

                @include breakpoint("max-sm") {
                    height: 278px;
                    width: 100%;
                    margin: 20px 0 0 0;
                    padding: 0;
                }

                .integrity-img-alignment {
                    @include breakpoint("max-sm") {
                        max-width: 150px;
                        margin: 0 auto;
                    }
                }

                .aaziko-logo-alignment {
                    padding: 21px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .integrity-all-body-details-alignment {
            @include breakpoint("max-sm") {
                padding: 0 0 40px 0;
            }

            .integrity-all-body-grid-alignment {
                display: grid;
                grid-template-columns: 1fr 408px;
                gap: 80px;
                padding: 112px 0 0 0;
                align-items: center;

                .integrity-img-alignment {
                    @include breakpoint("max-sm") {
                        order: 1;
                    }
                }

                &:nth-of-type(even) {
                    grid-template-columns: 408px 1fr;

                    @include breakpoint("max-sm") {
                        grid-template-columns: 1fr;
                    }
                }

                @include breakpoint("max-sm") {
                    grid-template-columns: 1fr;
                    padding: 40px 0 0 0;
                    gap: 10px;
                }


                .integrity-all-details-alignment {
                    @include breakpoint("max-sm") {
                        order: 2;
                    }

                    h6 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;

                        @include breakpoint("max-sm") {
                            font-size: 20px;
                            text-align: center;
                        }
                    }

                    p {
                        color: rgba(0, 0, 0, 0.80);
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        padding: 36px 0 0 0;

                        @include breakpoint("max-sm") {
                            padding: 8px 0 0 0;
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}