@import "../../../../styles/mixins/global.scss";

.store-front-details-sidebar {
  background: #ffffff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 140.5%;
    margin: 0 0 10px 0;
    color: #343434;
    @include breakpoint("max-sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  ul {
    margin: 0;
    padding-left: 1.25rem;
    padding-bottom: 20px;

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 140.5%;
      color: #606060;
    }
  }
}
