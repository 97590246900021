@import "../../../styles/mixins/global.scss";

.supplier-memberships-banner-section {
    .supplier-memberships-banner-alignment {
        .supplier-memberships-banner-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpoint("max-sm") {
                display: grid;
                grid-template-columns: repeat(1 , 1fr);
                gap: 40px;
            }

            .supplier-memberships-left-side-alignment {
                h6 {
                    color: #343434;
                   font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 140.5%;
                    padding: 0 0 10px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                h4 {
                    color: #00AEEF;
                   font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 140.5%;
                    max-width: 458px;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                .supplier-button-alignment {
                    padding: 50px 0 0 0;
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    @include breakpoint("max-sm") {
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 16px;
                    }
                    .supplier-start-selling {
                        button {
                            height: 50px;
                            padding: 0 40px;
                            border-radius: 8px;
                            background: #00AEEF;
                            color: #FFF;
                           font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 24px;
                            cursor: pointer;
                            border: none;

                            @include breakpoint("max-sm") {
                                padding: 10px;
                                font-size: 14px;
                                width: 100%;
                                height: auto;

                            }
                        }
                    }

                    .supplier-chat-consultant-button {
                        button {
                            height: 50px;
                            padding: 0 24px;
                            border-radius: 8px;
                            background: #BABABA;
                            color: #FFF;
                           font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 24px;
                            cursor: pointer;
                            border: none;
                            @include breakpoint("max-sm") {
                                padding: 10px;
                                font-size: 14px;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }

            .supplier-memberships-right-side-alignment {
                .supplier-memberships-box-alignment {
                    width: 243px;
                    padding: 12px 0;
                    border-radius: 8px;
                    border: 1px solid #00AEEF;
                    background: #FFF;
                    margin: 0 0 8px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 9px;

                    @include breakpoint("max-sm") {
                        width: 100%;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    h6 {
                        color: #00AEEF;
                       font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 140.5%;
                    }

                    p {
                        color: #343434;
                       font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 140.5%;
                        text-align: center;
                    }
                }
            }
        }

        .supplier-earth-img-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: -50px 0 0 0;

            @include breakpoint("max-sm") {
                display: block;
            }
        }

        .supplier-aaziko-details-box-alignment {
            background: #FFF;
            box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.09);
            padding: 50px 50px 16px 50px;

            @include breakpoint("max-sm") {
                padding: 16px;
            }

            h5 {
                color: #343434;
                text-align: center;
               font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 140.5%;
                max-width: 789px;
                margin: 0 auto;
            }

            .supplier-aaziko-details-flex-alignment {
                display: flex;
                align-items: flex-start;
                gap: 40px;
                padding: 40px 0 0 0;

                @include breakpoint("max-sm") {
                    display: grid;
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 40px;
                }

                .suplier-aaziko-img-alignment {
                    width: 432px;
                    max-width: 432px;
                    min-width: 432px;
                    height: 256px;
                    background: #BABABA;
                    @include breakpoint("max-sm") {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }

                .supplier-aaziko-description-details {
                    p {
                        color: #606060;
                       font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        padding: 0 0 10px 0;
                        font-weight: 400;
                        line-height: 140.5%;

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            line-height: 22px;
                        }
                        &:last-child {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}