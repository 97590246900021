@import "../../styles/mixins/index.scss";

.customs-easy-section {
    background: #FFF;
    padding: 82px 0;

    .customs-easy-banner-alignment {
        .customs-easy-banner-details {
            h2 {
                span {
                    color: #F48120;
                    font-size: 50px;
                    font-weight: 600;
                    line-height: 54px;
                    text-transform: capitalize;
                }

                color: rgba(0, 0, 0, 0.80);
                font-size: 40px;
                font-weight: 500;
                line-height: 44px;
                text-transform: capitalize;
            }

            .second-details-alignment {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 250px 0 0 0;

                h2 {
                    max-width: 624px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 48px;
                    text-transform: capitalize;

                    span {
                        color: #EC1C24;
                        font-size: 50px;
                        font-weight: 600;
                        line-height: 58px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}