@import "../../../styles/mixins/global.scss";


.resources-details-all-content-alignment {
  padding: 22px 0 50px 0;
  background-color: #fff;
  .white-box {
    background-color: #fff;
    box-shadow: 2px 2px 42px rgba(0, 0, 0, 0.1);
    padding: 70px 128px;
    border-radius: 8px;


    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    .grid {
      display: grid;
      grid-template-columns: 510px 1fr;
      gap: 130px;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      .grid-items {
        .new-text-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;

          .new-text-grid-items {
            h5 {
              font-weight: 600;
              font-size: 24px;
              line-height: 38px;
              text-align: center;
              color: #00aeef;
              margin: 0 0 8px 0;
              @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
              }
            }

            span {
              display: block;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #606060;
            }
          }
        }

        .text {
          p {
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            margin: 0 0 12px 0;
            color: var(--theme-heading-text-color);
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.05em;
            color: #606060;
            max-width: 510px;
            display: block;
          }
        }
      }
    }
  }

  .text-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    background: #00aeef;
    height: 80px;
    border-radius: 8px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 0;
      height: auto;
    }

    .text-grid-items:nth-child(1) {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 8px 0px 0px 8px;

      span {
        color: #00aeef;
      }
    }
    .text-grid-items {
      height: 80px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;

      span {
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        line-height: 22px;
        color: #ffffff;
      }
    }
  }
}
