@import "../../../styles/mixins/global.scss";

.lsq-grow-your-bussiness-section {
    width: 100%;
    height: 480px;
    background: url("../../../assets/Image/grow-bussiness-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .lsq-grow-bussiness-alignment {
        height: 480px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h4 {
            color: #FFF;
            font-size: 36px;
            font-weight: 700;
            line-height: 40px;
        }

        .lsq-grow-bussiness-button {
            padding: 30px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .chat-with-consultant-button {
                button {
                    width: 211px;
                    height: 52px;
                    background-color: transparent;
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    cursor: pointer;
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                }
            }

            .start-selling-button {
                button {
                    border-radius: 10px;
                    background: #FFF;
                    width: 211px;
                    height: 52px;
                    color: #1750C1;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    border: none;
                }
            }
        }
    }
}