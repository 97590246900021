@import '../../../styles/mixins/global.scss';

.new-footer-alignment {
    padding: 100px 0;
    background: #000;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    .footer-alignment {
        display: flex;
        justify-content: center;
        padding: 0 0 50px 0;
    }

    .footer-menu-alignment {
        display: flex;
        justify-content: space-between;

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            display: grid;
        }

        p {
            color: #FFF;
            font-size: 24px;
            margin: 0 0 37px 0;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
                margin: 0 0 20px 0;
            }
        }

        a {
            display: block;
            color: #FFF !important;
            font-size: 16px;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            padding: 0 0 21px 0;
            opacity: .5;
            line-height: normal;
text-decoration: none;
            @include breakpoint("max-sm") {
                font-size: 14px;
            }
        }
    }
}