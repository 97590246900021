:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 1px solid #e4e4e4;
  --left-msg-bg: #00aeef4f;
  --right-msg-bg: #00aeef;
}
.new-chat-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  background-color: #00efef3d;
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.chat-new-modal {
  position: fixed;
  width: 325px;
  border: 1px solid #10d9e0;
  height: 480px;
  background-color: #10d9e0;
  z-index: 9999;
  bottom: 102px;
  right: 46px;
  border-radius: 5px;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0);
  overflow: hidden;
  transform-origin: right bottom;
  &.open-chat {
    transform: scale(1);
  }
}

.new-icon-relative-div {
  position: relative;

  .first-icon {
    position: absolute;
    padding: 0;
    font-size: 10px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    i {
      padding: 0;
      font-size: 10px;
      color: #00aeef;
      cursor: pointer;
    }
  }
  .sec-icon {
    position: absolute;
    right: 30px;
    padding: 0;
    font-size: 10px;
    top: 50%;
    transform: translateY(-50%);

    i {
      padding: 0;
      font-size: 10px;
      color: #00aeef;
      cursor: pointer;
    }
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 10px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #10d9e0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.modaldetails-alignment {
  // display: flex;
  // align-items: center;
  // position: fixed;
  // width: 100%;
  // height: 100vh;
  // top: 0;
  // right: 0;
  // z-index: 999;
  // background: rgba(0, 0, 0, 0.6);
  .modal-heading-alignment {
    background: #00aeef;
    border-radius: 8px 8px 0px 0px;
    padding: 10px 0;
    text-align: center;

    h3 {
      color: #fff;
    }
  }

  .details-modal-alignment {
    // padding: 20px 27px 30px 27px;

    .companyName-alignment {
      margin: 0 0 25px 0;

      h4 {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #343434;
        border-bottom: 1px solid #bababa;
        // padding: 0 0 10px 0;
      }

      span {
        display: block;
        padding: 15px 0 0 0;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #343434;
      }
    }

    .other-details-alignment {
      margin: 0 0 25px 0;

      h4 {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #343434;
        border-bottom: 1px solid #bababa;
        padding: 0 0 10px 0;
      }

      .child-details-grid-alignment {
        // display: grid;

        // grid-template-columns: repeat(2, 1fr);
        padding: 15px 0 0 0;

        .checkbox-input-alignment {
          display: flex;
          align-items: center;
          align-items: flex-start;
          gap: 10px;
          padding: 0 0 14px 0;

          input[type="checkbox"] {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
          }

          label {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #343434;
          }
        }

        .time-details-alignment {
          p {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #343434;
            padding: 0 0 4px 0;

            &:last-child {
              padding: 0;
            }
          }
        }

        .date-time-input-alignment {
          background: #ffffff;
          border: 1px solid #bababa;
          border-radius: 5px;
          padding: 0 8px;
          display: flex;
          align-items: center;
          height: 30px;
          gap: 7px;
          margin: 0 0 12px 0;

          &:last-child {
            margin: 0;
          }

          input {
            background: transparent;
            border: none;
            height: 100%;
            width: 100%;
            color: #606060;
          }
        }
      }
      .child-details-grid-alignment-call {
        display: grid;

        grid-template-columns: repeat(2, 1fr);
        padding: 15px 0 0 0;

        .checkbox-input-alignment {
          display: flex;
          align-items: center;
          align-items: flex-start;
          gap: 10px;
          padding: 0 0 14px 0;

          input[type="checkbox"] {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
          }

          label {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #343434;
          }
        }

        .time-details-alignment {
          p {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #343434;
            padding: 0 0 4px 0;

            &:last-child {
              padding: 0;
            }
          }
        }

        .date-time-input-alignment {
          background: #ffffff;
          border: 1px solid #bababa;
          border-radius: 5px;
          padding: 0 8px;
          display: flex;
          align-items: center;
          height: 30px;
          gap: 7px;
          margin: 0 0 12px 0;

          &:last-child {
            margin: 0;
          }

          input {
            background: transparent;
            border: none;
            height: 100%;
            width: 100%;
            color: #606060;
          }
        }
      }
    }

    .contact-button-alignment {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      button {
        cursor: pointer;
        background: #bababa;
        border-radius: 8px;
        max-width: 98px;
        width: 98px;
        height: 34px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #606060;
        &:last-child {
          background: #00aeef;
          color: #ffffff;
        }
      }
    }
  }
}

.close-button-alignment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 10px 0;
  button {
    background: #00aeef;
    border: none;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.modalWrapperAlignment-model {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  // height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(0.4);

  .modalBoxAlignment-model {
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 800px;
    max-width: 800px;
    padding: 24px;
    height: auto;
    overflow-y: auto;
    max-height: 900px;
    overflow-x: hidden;
  }
}

.chat-design-all-content-alignment {
  width: 100%;

  @media screen and (max-width: 992px) {
    width: 100%;
    // padding: 20px;
    overflow-x: hidden;
  }
  .msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 50px);
    border-radius: 5px;
    background: #aee4f9;
    // background: var(--msger-bg);
  }

  .msger-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: var(--border);
    background: #aee4f9;
    color: var(--theme-heading-text-color);

    @media screen and (max-width: 992px) {
      padding: 15px;
    }
  }

  .msger-chat {
    height: 373px;

    overflow-y: auto;
    padding: 20px;

    @media screen and (max-width: 992px) {
      padding: 15px;
    }
  }
  .msger-chat::-webkit-scrollbar {
    width: 6px;
  }
  .msger-chat::-webkit-scrollbar-track {
    background: #ddd;
  }
  .msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }
  .msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .msg:last-of-type {
    margin: 0;
  }
  .msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;

    @media screen and (max-width: 992px) {
      width: 25px;
      min-width: 25px;
      height: 25px;
      max-height: 25px;
    }
  }

  .msg-text {
    font-size: 12px;
    line-height: 16px;
  }

  .msg-bubble {
    max-width: 450px;
    padding: 16px;
    border-radius: 15px;
    background: var(--left-msg-bg);

    @media screen and (max-width: 992px) {
      padding: 10px;
    }
  }
  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-text {
    @media screen and (max-width: 992px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;

    @media screen and (max-width: 992px) {
      font-size: 13px;
      line-height: 16px;
    }
  }
  .msg-info-time {
    font-size: 0.85em;

    @media screen and (max-width: 992px) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
  }

  .right-msg {
    flex-direction: row-reverse;
  }
  .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
  }
  .right-msg .msg-img {
    margin: 0 0 0 10px;
  }

  .msger-inputarea {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 10px;
    border-top: 1px solid #d0d0d0;
    padding: 8px;
  }
  .msger-inputarea * {
    border: none;
    border-radius: 3px;
    font-size: 1em;
  }
  .msger-input {
    background: #f5f5f5;
    border: 1px solid #d0d0d0;
    width: 100%;
    border-radius: 41px;
    font-weight: 500;
    height: 28px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 12px;
    color: var(--theme-heading-text-color);

    &:focus {
      outline: none;
    }
  }
  ::placeholder {
    font-size: 10px;
    line-height: 14px;

    color: #a2a2a2;
  }
  .msger-send-btn {
    font-weight: 700;
    padding: 6px;
    background: #00aeef;
    border-radius: 41px;
    font-size: 10px;
    line-height: 14px;

    /* white */

    color: #ffffff;
    transition: background 0.23s;
  }
  .msger-send-btn:hover {
    background: rgb(0, 180, 50);
  }

  .msger-chat {
    background-color: #fcfcfe; //aliceblue
    border-radius: 10px 10px 0 0;
    // background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
  }
}
