@import "../../styles/mixins/global.scss";

.aaziko-request-quote-section {
    padding: 65px 0 80px 0;

    .inspectionServiceAlignment {
        background: #fff;
        padding: 35px 30px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.50);
        background: #FFF;
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.06);

        @include breakpoint("max-sm") {
            padding: 16px;
        }

        .headingAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;

            .nameCardAlignment {
                display: flex;
                align-items: center;
                gap: 24px;

                @include breakpoint(max-md) {
                    width: 100%;
                }

                h1 {
                    padding: 0;
                    margin: 0;
                    color: #343434;
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                img {
                    width: 42px;
                    height: 35px;
                    @include pointer;
                    object-fit: cover;
                }
            }

            .internationalAlignment {
                display: flex;
                align-items: center;
                gap: 12px;

                @include breakpoint("max-sm") {
                    gap: 10px;
                }

                span {
                    color: #343434;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 18px;
                    }
                }

                img {
                    width: 10px;
                    height: 6px;
                    @include pointer;
                    object-fit: cover;
                }
            }
        }

        .requestNoteAlignment {
            margin-top: 16px;

            @include breakpoint("max-sm") {
                margin-top: 10px;
            }

            span {
                color: #606060;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;

                @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 18px;
                }

            }
        }

        .signinDetailsNoteAlignment {
            margin-top: 30px;
            background: rgba(0, 174, 239, 0.04);
            padding: 14px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 5px;

            @include breakpoint("max-sm") {
                margin-top: 20px;
                padding: 12px;
            }

            span {
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #606060;


                @include breakpoint("max-sm") {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .signInAlignment {
                display: flex;
                align-items: center;
                gap: 11px;

                span {
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #00AEEF;
                    white-space: nowrap;

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                img {
                    width: 10%;
                    height: 9px;
                    object-fit: cover;
                    @include pointer;
                    // display: flex;
                }
            }
        }

        .formAlignment {
            padding-right: 187px;

            @include breakpoint(max-md) {
                padding-right: 0;
            }

            .cargodetailsAlignment {
                margin-top: 40px;

                @include breakpoint("max-sm") {
                    margin-top: 20px;
                }

                &.contactDetails {
                    margin-top: 30px;
                }

                .headingAlignment {
                    h1 {
                        margin: 0;
                        padding: 0;
                        color: #343434;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: normal;
                        color: #343434;

                        @include breakpoint("max-sm") {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }

                .cargodetailsFormAlignment {
                    margin-top: 30px;

                    @include breakpoint("max-sm") {
                        margin-top: 16px;
                    }

                    .formLabelAlignment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .productLabel {
                            span {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;

                                @include breakpoint("max-sm") {
                                    font-size: 14px;
                                    line-height: 18px;
                                }

                                &.required {
                                    color: #ED1C24;
                                }
                            }
                        }

                        .hsCodeLabel {
                            span {
                                color: #606060;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                text-decoration-line: underline;

                                @include breakpoint("max-sm") {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }

                    .inputAlignment {
                        margin-top: 10px;
                        position: relative;
                        display: flex;
                        align-items: center;

                        input {
                            width: 100%;
                            height: 50px;
                            background: rgba(0, 174, 239, 0.04);
                            border: none;
                            padding: 14px 14px 14px 42px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 32px;

                            @include breakpoint("max-sm") {
                                padding: 14px;
                                font-size: 14px;
                                line-height: 18px;
                            }

                            &::placeholder {
                                color: #BABABA;

                            }
                        }

                        .serchImgAlignment {
                            position: absolute;
                            left: 16px;
                            @include pointer;
                        }
                    }
                }

                .cargoDetailsBoxAlignment {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 24px;

                    .boxAlignment {
                        width: 200px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        border: 1px solid #BABABA;
                        background: rgba(186, 186, 186, 0.10);

                        &.active {
                            background: #00AEEF;
                            border: none;

                            span {
                                color: #fff
                            }
                        }

                        span {
                            color: #606060;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                        }

                        img {
                            width: 19px;
                            height: 21px;
                            margin-right: 10px;
                            @include pointer;

                            &.oversizedImg {
                                width: 14px;
                            }

                            &.liquidImg {
                                width: 14px;
                            }
                        }
                    }
                }
            }

            .imoClassUNnumberAlignment {
                margin-top: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px;

                @include breakpoint(max-md) {
                    grid-template-columns: 1fr;
                }

                .labelInputAlignment {
                    display: grid;
                    gap: 10px;

                    label {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        color: #343434;

                        &.unLabel {
                            color: #606060;
                            margin-left: 30px;
                        }

                        span {
                            color: #ED1C24;
                        }
                    }

                    .inputAlignmentDetails {
                        position: relative;
                        display: flex;
                        align-items: center;

                        input {
                            width: 330px;
                            height: 40px;
                            padding: 10px 16px;
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            border: 1px solid #BABABA;
                            border-radius: 8px;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                color: #BABABA;
                            }

                            @include breakpoint(max-md) {
                                width: 100%;
                            }
                        }

                        .imgAlignment {
                            position: absolute;
                            right: 17px;

                            img {
                                display: flex;
                                @include pointer;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .deliveryFormAlignment {
                margin-top: 40px;

                .hedingAlignment {
                    h1 {
                        margin: 0;
                        padding: 0;
                        color: #343434;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 28px;
                        color: #343434;
                    }
                }

                .deliveryOptionAlignment {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    flex-wrap: wrap;

                    .differentOptionAlignment {
                        background: #fff;
                        padding: 8px;
                        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.09);
                        display: flex;

                        .optionBoxAlignment {
                            padding: 9px 20px;
                            @include pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 12px;
                            @include pointer;

                            &:nth-child(2) {
                                border-right: 0.5px solid #BABABA;
                            }

                            span {
                                color: #606060;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                color: #606060;


                            }

                            img {
                                width: 12px;
                                height: 16px;
                                object-fit: cover;

                                &.land {
                                    width: 15px;
                                    height: 16px;
                                }

                                &.air {
                                    width: 19px;
                                    height: 20px;
                                }

                                &.auto {
                                    width: 17px;
                                    height: 17px;
                                }
                            }

                            &.active {
                                background: rgba(0, 174, 239, 0.1);

                                span {
                                    color: #00AEEF;
                                }
                            }

                        }
                    }
                }

                .deliveryDetailsAlignment {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .detailsFlex {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 24px;

                        @include breakpoint(max-md) {
                            grid-template-columns: 1fr;
                        }

                        &.teatAreaGrid {
                            grid-template-columns: 1fr;
                        }


                        .labelInputAlignment {
                            width: 100%;

                            &.textArea {
                                width: 100%;
                            }

                            label {
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                color: #606060;

                                span {
                                    color: #ED1C24;
                                }
                            }

                            .deliveryDetailsFlex {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                border: 1px solid #BABABA;
                                width: 100%;
                                padding: 0 16px;
                                margin-top: 10px;
                                border-radius: 8px;

                                .inputAlignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 12px;
                                    width: 100%;
                                    height: 50px;

                                    &.textarea-alignment {
                                        height: 70px;
                                    }

                                    input {
                                        border: none;
                                        width: 90%;
                                        height: 100%;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 24px;

                                        &::placeholder {
                                            color: #BABABA;
                                        }

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    textarea {
                                        border: none;
                                        width: 100%;
                                        height: 70px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 24px;

                                        &::placeholder {
                                            color: #BABABA;
                                        }

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                }

                                .dropDownAlignment {
                                    img {
                                        width: 10px;
                                        height: 6px;
                                        display: block;
                                    }
                                }
                            }

                        }
                    }
                }

                .buttonAlignment {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    flex-wrap: wrap;

                    button {
                        background: #00AEEF;
                        padding: 11px 72px;
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        border: none;
                        color: #fff;
                        border-radius: 4px;
                    }

                    span {
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        color: #BABABA;

                        &.linkAlignment {
                            color: #606060;
                            @include pointer;
                        }
                    }
                }
            }


            .associatedservicesAlignment {
                margin-top: 30px;

                .headingAlignment {
                    h1 {
                        padding: 0;
                        margin: 0;
                        color: #343434;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .associatedservicesDetails {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .associatedservicesBackground {

                        border-radius: 4px;
                        // opacity: 0.1;    
                        background: #FFF;
                        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
                        padding: 9px;
                        display: flex;
                        align-items: center;
                        gap: 14px;

                        .checkboxaAlignment {
                            input[type="checkbox"] {
                                width: 12px;
                                height: 12px;
                                @include pointer;
                            }
                        }

                        .servicesDetailsFlex {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            img {
                                width: 13px;
                                height: 12px;
                                @include pointer;
                                object-fit: cover;
                            }

                            span {
                                color: #606060;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }

        .cargoesFinanceAlignment {
            margin-top: 30px;

            .headingAlignmet {
                h1 {
                    margin: 0;
                    padding: 0;
                    color: #343434;
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;
                }
            }

            .cargoesFinanceNotesAlignment {
                margin-top: 12px;
                background: var(--blue3-blackground);
                padding: 20px;
                display: flex;
                gap: 12px;

                .checkboxAlignment {
                    input[type="checkbox"] {
                        width: 12px;
                        height: 12px;
                        @include pointer;
                    }
                }

                .checkDetailsAlignment {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;

                    span {
                        color: #343434;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;

                        &.notesDetails {
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            color: #606060;
                        }
                    }
                }
            }
        }
    }
}