@import '../../../styles/mixins/global.scss';

.types-insurance-policy-main {
    padding: 76px 0 0 0;

    .types-insurance-policy-alignment {
        .types-insurance-heading {
            h6 {
                text-align: center;
                color: #000;
                font-family: 'Montserrat';
                font-size: 45px;
                font-weight: 600;
                line-height: 54px;
            }
        }

        .types-insurance-body-alignments {
            padding: 95px 0 0 0;

            .types-insurance-box-alignment {
                background: linear-gradient(101deg, rgba(157, 228, 255, 0.80) -3.05%, rgba(0, 174, 239, 0.00) 83.13%);
                width: 100%;
                height: 245px;
                border-radius: 150px 0 0 150px;
                padding: 34px 0 48px 90px;
                margin: 0 0 42px 0;

                &:last-child {
                    margin: 0;
                }

                &.second-color {
                    background: linear-gradient(101deg, rgba(255, 200, 135, 0.80) 2.84%, rgba(255, 72, 0, 0.00) 83.13%);
                }

                &.third-color {
                    background: linear-gradient(101deg, rgba(153, 255, 203, 0.80) 6.39%, rgba(0, 165, 81, 0.00) 83.13%);
                }

                .types-insurance-top-heading {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    h6 {
                        color: rgba(0, 0, 0, 0.8);
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 28px;
                    }
                }

                .types-insurance-list {
                    p {
                        color: rgba(0, 0, 0, 0.8);
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        max-width: 886px;
                    }
                }
            }
        }
    }
}