@import '../../../../styles/mixins/global.scss';

.what-we-supply-section {
    padding: 50px 0 0 0;

    .what-we-supply-details {
        h4 {
            color: var(--theme-heading-text-color);
           font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
              }
        }

        .what-we-supply-child-details {
            padding: 20px 0 0 0;

            .what-we-supply-child-grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 24px;


                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                }

                .what-we-supply-child-gridItem {
                    .what-we-supply-child-box {
                        border-radius: 4px;
                        background: #FFF;
                        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.12);
                        height: 200px;

                        .what-we-box-heading {
                            padding: 16px 16px 0 16px;

                            p {
                                color: #606060;
                               font-family: 'Poppins', sans-serif;
                                font-size: 24px;
                                font-weight: 600;
                                line-height: 28px;
                                @include breakpoint("max-sm") {
                                    font-size: 20px;
                                    line-height: 30px;
                                  }
                            }
                        }

                        .what-we-box-other-details {
                            display: flex;
                            align-items: flex-end;
                            justify-content: space-between;
                            padding: 0 0 16px 16px;

                            a {
                                color: #00AEEF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                text-decoration-line: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}