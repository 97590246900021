@import '../../../styles/mixins/global.scss';

.every-think-by-aaziko-section {
    padding: 142px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 40px 0 0 0;
    }

    .every-think-by-aaziko-heading {
        h5 {
            color: rgba(0, 0, 0, 0.80);
            font-family: 'Poppins', sans-serif;
            font-size: 60px;
            font-weight: 600;
            line-height: 74px;
            text-align: center;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }

            span {
                color: #00AEEF;
            }
        }
    }

    .every-think-by-aaziko-details-alignment {
        padding: 126px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }

        .every-think-by-aaziko-details-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 92px 100px;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .every-think-by-aaziko-details-grid-item {
                &:last-child {
                    grid-column: span 2;

                    @include breakpoint("max-sm") {
                        grid-column: auto;
                    }
                }

                .step-details-alignment {
                    text-align: center;

                    .step-number {
                        width: 81px;
                        height: 81px;
                        border: 3px solid rgba(0, 174, 239, 0.50);
                        border-radius: 50%;
                        margin: 0 auto;
                    }

                    .step-main-img {
                        padding: 28px 0 0 0;
                    }

                    .step-all-description {
                        padding: 26px 0 0 0;

                        h6 {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 31px;

                            @include breakpoint("max-sm") {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }

                        p {
                            padding: 12px 0 0 0;
                            color: var(--theme-heading-text-color);
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 21px;
                            max-width: 340px;
                            margin: 0 auto;

                            @include breakpoint("max-sm") {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}