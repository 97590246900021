@import "../../../styles/mixins/global.scss";

.auditingand-compliance-banner {
  background: #042953;
  padding: 100px 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  .logo-icon-center-alignment {
    display: flex;
    align-items: center;
    padding: 0 0 40px 0;
    justify-content: center;
    @include breakpoint("max-sm") {
      padding: 0 0 30px 0;
    }
  }

  h1 {
    color: #e9d25d;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    max-width: 800px;
    font-weight: 600;
    line-height: 115.9%;
    text-align: center;
    margin: 0 auto;

    @include breakpoint("max-sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
