@import "../../../../styles/mixins/global.scss";
$breakpoint-xs: 576px; //New Scss
$breakpoint-sm: 768px; //New Scss

.our-trainding-video-section {
  padding: 170px 0 80px 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  .our-trainding-video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 56px 56px; // here change the second gap 175px

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .our-trainding-video-grid-item {
      .our-trainding-video-grid-box {
        width: 100%;
        max-height: 100%; // Add New Css
        min-height: 100%; // Add New Css
        border-radius: 10px;
        border: 2px solid #00aeef;
        padding: 12px 16px 18px 16px;

        @include breakpoint("max-sm") {
          padding: 14px;
        }

        .our-trainding-video-img {
          width: 100%;
          height: 207px;

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 10px;
          }

          iframe {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        .our-trainding-video-details-alignment {
          padding: 16px 4px 0 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          h6 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            max-width: 284px;

            @include breakpoint("max-sm") {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .our-trainding-video-right-side-alignment {
            .our-traimding-youtube-icon {
              width: 76px;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            .subscribe-button-alignment {
              padding: 12px 0 0 0;

              button {
                padding: 6px 17px;
                color: #f00;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                border-radius: 10px;
                border: 1px solid #f00;
                background: transparent;
                cursor: pointer;

                @include breakpoint("max-sm") {
                  font-size: 12px;
                  padding: 6px 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  // New scss  add here...
  // Media Queries
  $breakpoint-xs-minus-1: calc(#{$breakpoint-xs} - 1px);

  @media (max-width: $breakpoint-xs-minus-1) {

    .our-trainding-video-grid-item {
      .our-trainding-video-grid-box {
        display: block;
        grid-template-columns: auto;
      }
    }

    .our-trainding-video-grid {
      display: block;
      grid-template-columns: repeat(1, 1fr);
    }

    .our-trainding-video-grid-item {
      .our-trainding-video-grid-box {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {

    .our-trainding-video-grid {
      display: block;
      grid-template-columns: repeat(1, 1fr);
      margin-top: -86px;
    }

    .our-trainding-video-grid-item {
      .our-trainding-video-grid-box {
        margin-bottom: 16px;
      }
    }
  }
}