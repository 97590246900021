@import "../../../styles/mixins/global.scss";

.fast-access-section {
    background-color: #fff;
    padding: 0 0 70px 0;
    @include breakpoint("max-sm") {
        padding: 0 0 40px 0;
    }
    .fast-access-alignment {
        position: relative;

        .fast-access-advantage-img {
            position: absolute;
            top: 80px;
            left: 0;

            @include breakpoint("max-sm") {
                display: none;
            }
        }

        .fast-access-details-alignment {
            .fast-access-heading-alignment {
                padding: 0 0 110px 0;

                @include breakpoint("max-sm") {
                    padding: 0 0 40px 0;
                }

                h4 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    line-height: 42px;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    text-align: center;

                    @include breakpoint("max-sm"){
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }

            .fast-access-all-details-alignment {
                .fast-access-all-details-grid {
                    display: grid;
                    grid-template-columns: 1fr 480px;
                    gap: 104px;
                    @include breakpoint("max-sm") {
                        grid-template-columns: repeat(1 , 1fr);
                        gap: 40px;
                    }
                    .fast-access-all-details-gridItem {
                        .fast-access-child-details {
                            padding: 0 0 30px 0;

                            h6 {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 24px;
                                line-height: 38px;
                                font-weight: 500;
                       
                                letter-spacing: 0.2px;
                                padding: 0 0 6px 0;
                                text-align: left;
                                @include breakpoint("max-sm") {
                                    font-size: 20px;
                                    line-height: 30px;
                                  }
                            }

                            p {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 30px;
                                letter-spacing: 0.2px;

                                @include breakpoint("max-sm") {
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}