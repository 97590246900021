@import "../../styles/mixins/global.scss";

.customs-section {
    background-color: #f8f8f8;

    .customs-main-alignment {
        display: flex;
        align-items: center;
        gap: 32px;
        padding: 62px 0;

        @include breakpoint("max-sm") {
            padding: 40px 0;
            display: grid;
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px;
        }
       

        .custom-left-side-alignment {
            h4 {
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-weight: 600;
                line-height: 48px;
                text-transform: capitalize;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 140.5%;
                padding: 24px 0 0 0;
                max-width: 512px;
            
                @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .custom-right-side-alignment {
            width: 585px;
            max-width: 585px;
            // min-width: 585px;
            height: 320px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            padding: 25px 22px;

            @include breakpoint("max-sm") {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 10px;
            }
        }
    }
}