@import '../../../styles/mixins/global.scss';

.payment-mode-section {
    padding: 30px 0 0 0;

    .payment-mode-step-details {
        &.second-step-mode {
            padding: 126px 0 0 0;

            @include breakpoint("max-sm") {
                padding: 40px 0 0 0;
            }
        }
        .payment-mode-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: center;

            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 48px;
                opacity: 0.8;
                position: relative;
                left: -15px;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            img {
                @include breakpoint("max-sm") {
                    display: none;
                }
            }
        }

        .payment-steps-all-details-alignment {
            padding: 30px 0 0 0;
            position: relative;

            @include breakpoint("max-sm") {
                padding: 40px 0 0 0;
            }

            &.second-step {
                &::before {
                    display: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                background: url("../../../assets/Image/p-angle-2.svg");
                width: 77px;
                height: 100px;
                top: 50%;
                right: 50%;
                transform: translate(-50%);

                @include breakpoint("max-sm") {
                    display: none;
                }
            }

            .payment-step-details-flex-alignment {
                display: flex;
                align-items: center;
                gap: 98px;
                margin: 0 0 92px 0;

                @include breakpoint("max-sm") {
                    display: grid;
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 40px;
                }

                &:last-child {
                    margin: 0;
                }

                &.right-side {
                    gap: 20px;

                    p {
                        max-width: 651px;
                    }

                }

                .payment-step-box-alignment {
                    width: 387px;
                    height: 322px;
                    min-width: 387px;
                    max-width: 387px;
                    border-radius: 10px;
                    background: #F9F9F9;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include breakpoint("max-sm") {
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                    }
                }

                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                    max-width: 558px;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                .payment-read-more-button {
                    padding: 12px 0 0 0;

                    button {
                        border-radius: 10px;
                        background: #00AEEF;
                        width: 101px;
                        height: 30px;
                        color: #FFF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                        cursor: pointer;
                        border: none;
                    }
                }
            }
        }
    }
}