@import "../../../styles/mixins/global.scss";

.home-page-hero-banner {
  padding: 80px 0;
  background: linear-gradient(
    180deg,
    rgba(223, 255, 239, 0.85) 0%,
    rgba(223, 255, 239, 0) 89.07%
  );

  .grid {
    @include grid;
    grid-template-columns: 1fr 500px;
    gap: 80px;
    align-items: center;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .grid-items {
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: #00aeef;
        display: block;
        padding: 0 0 24px 0;
      }
      .earth-image-style {
        @include breakpoint("max-md") {
          @include flex-center;
          justify-content: center;
        }

        img {
          width: 100%;

          @include breakpoint("max-md") {
            width: 70%;
          }

          @include breakpoint("max-sm") {
            width: 100%;
          }
        }
      }

      h1 {
        font-weight: 600;
        font-size: 50px;
        line-height: 56px;
        color: var(--theme-heading-text-color);
        max-width: 400px;
        margin: 0 0 24px 0;
        a {
          color: #00aeef;
        }
        @include breakpoint("max-sm") {
          @include heading-lg;
        }
      }

      .all-button-center-alignment {
        @include flex-center;
        gap: 24px;

        @include breakpoint("max-sm") {
          display: grid;
          grid-template-columns: repeat(1 , 1fr);
          gap: 20px;
        }

        button {
          @include breakpoint("max-sm") {
            width: 100%;
          }
        }

        .how-demo-button {
          padding: 14px 38px;
          border-radius: 8px;
          font-weight: 600;
font-size: 16px;
font-family: var(--primary-font);
border: 1px solid #00AEEF;
background-color: transparent;
cursor: pointer;
color: #00AEEF;
line-height: 21px;

@include breakpoint("max-sm") {
  font-size: 14px;
}

        }
        .new-first-button {
          padding: 14px 38px;
          background: #00aeef;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;
  font-family: var(--primary-font);

          line-height: 21px;
          color: #ffffff;
          border: none;
          cursor: pointer;

          @include breakpoint("max-sm") {
            font-size: 14px;
          }
        }
      }

      p {
        @include normal18;
        color: var(--theme-text-gray-500);
      }
    }
  }
}

.product-inquiry-modal {
  .modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: 300ms ease 0s 1 normal none running OpenFade;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    .product-inquiry-modal-box {
      background-color: #fff;
      max-width: 800px;
      width: 100%;
      position: relative;
      box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
        0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
      line-height: normal;

      .product-inquiry-details {
        padding: 20px;

        .product-heading {
          margin-bottom: 15px;

          h2 {
            @include heading-4;
            color: var(--theme-heading-text-color);
          }
        }

        .close-alignment {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 35px;
          height: 35px;
          font-size: 0;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
          line-height: 29px;
          fill: #fff;
          border-radius: 50px;
          background-color: rgb(0, 0, 0);
          border: 3px solid #fff;
          outline: none !important;

          svg {
            display: inline-block;
            vertical-align: middle;
            height: 19px;
            width: 19px;
          }
        }

        .product-details-form-section {
          .input {
            position: relative;
            margin-bottom: 20px;

            &.contry-alignment {
              width: 100%;
            }

            input {
              width: 100%;
              transition: #e6e6e6 0.2s ease-in-out;
              color: #797979;
              @include normal18;
              background-color: transparent;
              height: 50px;
              padding: 0;
              max-width: 100%;
              border: none;
              border-bottom: 1px solid #e6e6e6;

              &::placeholder {
                @include normal14;
                color: lightgray;
              }

              &:focus {
                border-color: #73c139;
              }

              &:focus-visible {
                outline: none;
              }
            }

            textarea {
              width: 100%;
              transition: #e6e6e6 0.2s ease-in-out;
              color: #797979;
              @include normal18;
              background-color: transparent;
              height: 32px;
              padding: 0;
              max-width: 100%;
              border: none;
              border-bottom: 1px solid #e6e6e6;
              margin-top: 10px;

              &::placeholder {
                @include normal14;
                color: lightgray;
              }

              &:focus {
                border-color: #73c139;
                height: 200px;
              }

              &:focus-visible {
                outline: none;
              }
            }

            .dropdown-alignment {
            }
          }

          .product-img-section {
            margin-bottom: 20px;

            p {
              @include normal18;
              color: #444444;
              margin-bottom: 20px;
            }

            .photo-upload-img-section {
              input[type="file"] {
                color: inherit;
                font: inherit;
                margin: 0;
                cursor: pointer;
              }
            }
          }

          .email-number-alignment {
            display: flex;
            align-items: center;

            .PhoneInput {
              width: 100%;

              .PhoneInputCountry {
                border-bottom: 1px solid #e6e6e6;
                width: 20%;
                margin-right: 0;
                gap: 10px;
              }

              input {
                &.PhoneInputInput {
                  width: 100%;
                  transition: #e6e6e6 0.2s ease-in-out;
                  color: #797979;
                  @include normal18;
                  background-color: transparent;
                  height: 50px;
                  padding: 0;
                  max-width: 100%;
                  border: none;
                  border-bottom: 1px solid #e6e6e6;

                  &::placeholder {
                    @include normal14;
                    color: lightgray;
                  }

                  &:focus {
                    border-color: #73c139;
                  }

                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }
          }
        }

        .submit-button-section {
          margin: 15px 0;

          button {
            background: #4f74f9;
            color: var(--theme-white-color);
            display: inline-block;
            padding: 6px 48px;
            position: relative;
            white-space: nowrap;
            text-align: center;
            border: 1px solid #4f74f9;
            -webkit-border-radius: 50px;
            border-radius: 50px;
            cursor: pointer;
            @include normal14;
            line-height: 40px;

            &:hover {
              border: 2px solid #4f74f9;
              background: var(--theme-white-color);
              color: #4f74f9;
            }
          }
        }
      }
    }
  }
}
