@import '../../../styles/mixins/global.scss';

.add-container-modal-section {
    .add-container-modal-alignment {
        position: fixed;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.50);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;

        .add-container-box-alignment {
            border-radius: 8px;
            background: #FFF;
            max-width: 840px;
            padding: 30px;
            overflow: auto;
            max-height: 100%;

            .add-container-type-name-alignment {
                padding: 30px 0 0 0;

                &:first-child {
                    padding: 0;
                }

                h6 {
                    color: #343434;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                }

                .add-container-type-box-details-alignment {
                    border: 1px solid #BABABA;
                    background: #FFF;
                    margin: 14px 0 0 0;
                    width: 100%;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .add-container-type-child-box {
                        width: 112px;
                        height: 100%;
                        border-right: 1px solid #BABABA;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        align-items: center;
                        justify-content: center;

                        &:last-child {
                            border-right: none;
                        }

                        @include breakpoint("max-sm") {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        p {
                            color: #606060;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21px;
                            text-align: center;

                            @include breakpoint("max-sm") {
                                font-size: 12px;
                            }
                        }


                    }
                }

                .add-container-type-second-all-details {
                    padding: 14px 0 0 0;

                    .add-container-type-second-all-details-grid {
                        display: grid;
                        grid-template-columns: 246px 1fr;
                        gap: 26px;

                        @include breakpoint("max-md") {
                            grid-template-columns: 1fr;
                        }

                        .add-container-type-second-form-alignment {
                            .add-container-first-input-grid {
                                display: grid;
                                grid-template-columns: 1fr 162px;

                                .add-container-input-alignment {
                                    label {
                                        color: #606060;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }

                                    .add-container-input {
                                        width: 100%;
                                        height: 30px;
                                        border-radius: 4px;
                                        border: 1px solid #BABABA;
                                        background: #FFF;

                                        input {
                                            width: 100%;
                                            height: 100%;
                                            border: none;
                                            background: transparent;
                                            color: #606060;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 21px;
                                            padding: 0 10px;
                                        }
                                    }

                                    .color-box-alignment {
                                        width: 100%;
                                        border-radius: 4px;
                                        background: #00AEEF;
                                        height: 30px;
                                    }
                                }
                            }


                            .add-container-three-grid {
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                gap: 10px;
                            }
                        }

                        .add-container-type-second-form-alignment {
                            max-width: 100%;

                            .add-container-first-input-grids {
                                max-width: 100%;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                gap: 10px;
                                margin-top: 10px;

                                .add-container-input-alignments {
                                    label {
                                        color: #606060;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }

                                    .add-container-input {
                                        width: 100%;
                                        height: 30px;
                                        border-radius: 4px;
                                        border: 1px solid #BABABA;
                                        background: #FFF;

                                        input {
                                            width: calc(100% - 2em);
                                            border: none;
                                            background: transparent;
                                            color: #606060;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 21px;
                                            padding: 5px 10px;
                                            margin-right: 2em;
                                        }

                                        .placeholder-wrapper {
                                            position: relative;
                                        }

                                        .placeholder-prefix,
                                        .placeholder-suffix {
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            color: #999;
                                            font-size: 14px;
                                            padding: 0 5px;
                                        }

                                        .placeholder-prefix {
                                            left: 5px;
                                        }

                                        .placeholder-suffix {
                                            right: 5px;
                                        }
                                    }

                                    .color-box-alignment {
                                        width: 100%;
                                        border-radius: 4px;
                                        background: #00AEEF;
                                        height: 30px;
                                        text-align: center;
                                        color: #FFF;
                                        padding: 3px;
                                    }
                                }
                            }


                            .add-container-three-grid {
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                gap: 10px;
                            }
                        }


                    }

                    .add-container-type-second-all-details-grids {
                        display: grid;
                        grid-template-columns: 246px 1fr;
                        gap: 26px;

                        @include breakpoint("max-md") {
                            grid-template-columns: 1fr;
                        }

                        .add-container-type-second-all-details-border {
                            border: 1px solid #BABABA;
                            border-radius: 4px;
                            padding: 10px;

                            .add-container-type-second-all-details-label {
                                display: flex;
                                padding: 10px;

                                input {
                                    background: transparent;
                                    color: #606060;
                                }

                                label {
                                    color: #606060;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 21px;
                                    margin-left: 10px;
                                }
                            }

                            .add-container-type-second-all-details-border-grid {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 10px;

                                @include breakpoint("max-sm") {
                                    grid-template-columns: 1fr;
                                }

                                .add-container-type-second-all-details-border-flex {
                                    display: flex;

                                    .add-container-type-second-all-details-labels {
                                        padding-left: 25px;

                                        label {
                                            color: #606060;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 21px;
                                            margin-left: 10px;
                                        }

                                        .add-container-input {
                                            width: 100%;
                                            height: 30px;
                                            border-radius: 4px;
                                            border: 1px solid #BABABA;
                                            background: #FFF;

                                            input {
                                                width: 100%;
                                                height: 100%;
                                                border: none;
                                                background: transparent;
                                                color: #606060;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 21px;
                                                padding: 0 10px;
                                            }

                                            .placeholder-wrapper {
                                                position: relative;
                                            }

                                            .placeholder-prefix,
                                            .placeholder-suffix {
                                                position: absolute;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                color: #999;
                                                font-size: 14px;
                                                padding: 0 5px;
                                            }

                                            .placeholder-prefix {
                                                left: 5px;
                                            }

                                            .placeholder-suffix {
                                                right: 5px;
                                            }
                                        }
                                    }
                                }

                                img {
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .add-modal-buttons {
            button {
                padding: 14px 38px;
                border-radius: 10px;
                color: #FFF;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                cursor: pointer;
                border: none;

                @include breakpoint("max-sm") {
                    padding: 8px 18px;
                    font-size: 14px;
                }
            }
        }
    }
}