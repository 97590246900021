@import "../../styles/mixins/global.scss";

.vessel-types-section {
    .vessel-types-alignment {
        padding: 60px 0;

        .vessel-types-heading-alignment {
            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                opacity: 0.8;
                text-align: center;
            }
        }


        .vessel-types-body-alignment {
            padding: 42px 0 0 0;

            .vessel-types-body-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 21px;
                padding: 42px 0 0 0;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr) !important;
                }
                @include breakpoint("max-md") {
                    grid-template-columns: repeat(2, 1fr);
                }

                .vessel-types-body-grid-item {
                    .vessel-types-body-box {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.20);
                        background: #FFF;
                        box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.30);
                        padding: 18px 20px 22px 20px;

                        h6 {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-align: center;
                        }

                        .vessel-types-img-alignment {
                            padding: 32px 0 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;


                        }

                        .vessel-types-learn-more-details {
                            padding: 38px 0 0 0;

                            a {
                                color: #00AEEF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                cursor: pointer;
                            }

                        }
                    }

                }
            }
        }
    }
}