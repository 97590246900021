@import "../../styles/mixins/global.scss";

.world-sea-port-section {
    padding: 0 0 88px 0;

    .world-sea-port-banner {
        width: 100%;
        height: 640px;

        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .world-sea-port-body-alignment {
        .world-sea-port-contry-name-details {
            .world-sea-port-contry-grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;
            }
        }

        .world-sea-port-other-details-alignment {
            padding: 30px 0 0 0;

            .world-sea-port-box-alignment {
                width: 100%;
                height: 180px;
                border-radius: 10px;
                background: #ECFAFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                margin: 32px 0 0 0;

                &:first-child {
                    margin: 0;
                }

                .world-sea-port-box-all-details-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 30px;

                    .world-sea-port-left-side-flex {
                        display: flex;
                        align-items: flex-start;
                        gap: 28px;

                        .shipping-icon-box-alignment {}

                        .shipping-other-details-alignment {
                            h6 {
                                color: #000;
                                font-family: 'Poppins', sans-serif;
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                padding: 0 0 14px 0;
                            }

                            p {
                                color: #000;
                                font-family: 'Poppins', sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                max-width: 753px;
                            }
                        }
                    }

                    .get-started-button {
                        button {
                            border-radius: 10px;
                            background: #00AEEF;
                            border: none;
                            color: #FFF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            padding: 14px 34px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .world-sea-port-all-select-counrty-name {
            padding: 50px 28px;

            h6 {
                color: #000;
                font-size: 32px;
                font-weight: 600;
                line-height: 36px;
            }

            .world-sea-port-selected-country-name {
                padding: 16px 0 0 0;

                ul {
                    display: flex;
                    align-items: center;
                    gap: 82px;
                    list-style-position: inside;

                    li {
                        color: #000;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}