@import "../../styles/mixins/index.scss";

.services-fees-section {
    padding: 60px 0;

    .services-fees-alignment {
        .services-fees-heading {
            h4 {
                color: #000;
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
            }
        }

        .services-fees-body-alignment {
            padding: 48px 0 0 0;

            .services-fees-body-box {
                border: 1px solid #d0d0d0;
                background: #F6FDFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 0 0 45px 0;

                .services-fees-body-top-alignment {
                    padding: 55px 26px 50px 26px;
                    display: grid;
                    grid-template-columns: repeat(26,1fr);
                    gap: 10px;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    @include breakpoint("max-sm"){
                        grid-template-columns: repeat(5,1fr)
                    };
                    @media (min-width:"541px") and (max-width:"991px") {
                        grid-template-columns: repeat(10, 1fr);
                        gap: 20px;
                    }
                    justify-content: space-between;

                    p {
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 11.44px;
                        transition: 0.3s ease-in-out;
                        cursor: pointer;

                        &:first-child {
                            color: #00AEEF;
                        }

                        &:hover {
                            transition: 0.3s ease-in-out;
                            color: #00AEEF;
                        }
                    }
                }

                .services-fees-details-table-alignment {
                    table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
                                    padding: 0 0 22px 0;
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:last-child {
                                    td {
                                        border-bottom: none;
                                    }
                                }

                                td {
                                    padding: 24px 40px;
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.40);

                                    &.all-notes {
                                        color: rgba(0, 0, 0, 0.80);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                    }

                                    p {
                                        color: #00AEEF;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                    }

                                    &:nth-child(1) {
                                        width: 30%;
                                    }

                                    &:nth-child(2) {
                                        width: 70%;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}