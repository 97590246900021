@import "../../../styles/mixins/global.scss";


.ship-schedule-option-by-vessel-section {
    margin: 14px 0 0 0;

    .ship-schedule-option-search-box-alignment {
        border-radius: 8px;
        background: #F1F1F1;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        height: 70px;
        width: 100%;
        padding: 10px 10px 10px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ship-schedule-option-input-alignment {
            width: 100%;
            height: 100%;
            border-right: 1px solid #BABABA;

            &.second-input {
                border-right: none;
                padding: 0 0 0 24px;
            }

            input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;

                &::placeholder {

                    color: #BABABA;
                }
            }
        }

        .search-box-alignment {
            width: 50px;
            height: 50px;
            min-width: 50px;
            max-width: 50px;

            button {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #00AEEF;
                border: none;
                border-radius: 8px;
            }

        }
    }

    .ship-schedule-option-by-vessel-body-alignment {
        margin: 75px 0 0 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        h4 {
            padding: 20px 0 0 0;
            color: #343434;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
        }

        p {
            padding: 12px 0 0 0;
            max-width: 670px;
            color: #BABABA;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            text-transform: capitalize;
            margin: 0 auto;
        }

        .read-about-app-section {
            padding: 30px 0 0 0;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                color: #343434;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }
}