@import '../../../styles/mixins/global.scss';

.why-by-insurance-main {
    padding: 100px 0 0 0;

    .why-by-insurance-alignment {
        h4 {
            color: rgba(0, 0, 0, 0.8);
            font-size: 45px;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
        }

        .why-by-insurance-all-details-alignment {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin: 98px 114px 172px 114px;

            .why-by-insurance-box {
                width: 100%;
                height: 236px;
                position: relative;

                .why-by-insurance-top-icon {
                    position: absolute;
                    top: -32px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .why-by-insurance-details-alignment {
                    position: absolute;
                    top: 70px;
                    left: 48px;
                    right: 48px;
                    max-width: 180px;
                    margin: 0 auto;
                    text-align: center;

                    h6 {
                        color: rgba(0, 0, 0, 0.8);
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    p {
                        padding: 10px 0 0 0;
                        color: #000;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                    }
                }

                &:nth-child(even) {
                    .why-by-insurance-top-icon {
                        bottom: -45px;
                        top: auto;
                    }

                    .why-by-insurance-details-alignment {
                        bottom: 70px;
                        top: auto;
                        p {
                            padding: 0 0 10px 0;
                        }
                    }
                }

            }
        }
    }
}