.blogdetail-section {
  .home-img {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
      border-bottom: 1px solid rgb(0 0 0 / 14%);
      box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
    }
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    .home-content {
      .content-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .descrition {
          h2 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #343434;
            margin-top: 0;
          }
          .icon-section-main {
            display: flex;
            gap: 30px;
            .icon-section {
              display: flex;
              align-items: center;
              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #606060;
                padding-left: 12px;
              }
            }
          }
        }
        .profile-img {
          display: flex;
          align-content: center;
          .img-text {
            margin-right: 10px;
            h2 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #343434;
              margin-bottom: 5px;
              margin-top: 0;
            }
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #606060;
              margin: 0;
            }
          }
        }
      }
    }
    .relatedpost-section {
      margin-bottom: 100px;
      margin-top: 50px;
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #343434;
        margin-bottom: 10px;
      }
      .post-section-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .grid-item {
          border-radius: 8px 8px 0px 0px;
          border: 1px solid #bababa;

          box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
          transition: 0.3s;
       

          p {
            padding: 10px;
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #343434;
          }
        }
      }
    }
    .comment-section {
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: var(--theme-heading-text-color);
      }
      .chat-main-section {
        display: flex;
        margin-bottom: 24px;
        &.margin-alignment {
          margin-left: 100px;
        }

        .chat-massege {
          // padding: 0 30px;

          .profile-name {
            position: relative;
            padding: 0 20px;
            margin-bottom: 15px;
            &::before {
              content: "";
              position: absolute;
              background: #bababa;
              height: 100%;
              width: 1px;
              top: 0;
              bottom: 0;
            }
            .name-main {
              display: flex;
              justify-content: space-between;
              padding-left: 20px;
              h3 {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 6px;
                margin-top: 0;
              }
              .calender-img {
                display: flex;
                align-items: center;
                span {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  color: #606060;
                  padding-left: 8px;
                }
              }
              .reply-section {
                span {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  color: #bababa;
                }
              }
            }
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #606060;
              padding-left: 20px;
            }
          }
          .send-messge {
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
            .send-input {
              width: 100%;
              input {
                background: #f2f2f2;
                border-radius: 4px;
                width: 100%;
                height: 32px;
                border: none;
              }
            }
            .send-button {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              button {
                background: #00aeef;
                border-radius: 4px;
                width: 32px;
                height: 32px;
                border: none;
                svg {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .leave-comment-section {
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 24px;
      }
      .messge-section{
        margin-bottom: 50px;
          .grid-main {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 36px;
            margin-bottom: 24px;
            .grid-item {
                width: 100%;
              input {
                border: 1px solid #C4C4C4;
                border-radius: 8px;
                background: rgba(196, 196, 196, 0.1);
                color:  #343434;
                width: 100%;
                height: 50px;
                padding: 0 6px;
                
              
              }
            }
          }
          .grid-section{
            textarea {
                border: 1px solid #C4C4C4;
                border-radius: 8px;
                background: rgba(196, 196, 196, 0.1);
                color:  #343434;
                width: 100%;
                height: 137px;
                padding:6px;
               
                     
              }
          }

      }
      .leave-button{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        button{
            background: #00AEEF;
            border-radius: 8px;
            padding: 8px 36px;
            color:#fff;
            border: none;
        }
      }
    }
  }
}
