@import "../../styles/mixins/global.scss";

.types-railway-wagons-section {
    .types-railway-wagons-alignment {
        padding: 60px 0;

        .types-railway-wagons-heading {
            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                opacity: 0.8;
                text-align: center;
            }
        }

        .types-railway-wagons-body {
            padding: 42px 0 0 0;

            .types-railway-wagons-body-alignment {
                .types-railway-wagons-body-grid {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 38px 20px;

                    @include breakpoint("max-sm") {
                        grid-template-columns: repeat(1, 1fr) !important;
                    }
                    @include breakpoint("max-md") {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    .types-railway-wagons-body-grid-item {
                        .types-railway-wagons-body-box {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            border: 1px solid rgba(0, 0, 0, 0.20);
                            background: #FFF;
                            box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.30);
                            padding: 32px 28px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            h6 {
                                max-width: 300px;
                                color: var(--theme-heading-text-color);
                                text-align: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                margin: 0 auto;
                                text-align: center;
                            }

                            .types-raiway-wagon-img {
                                padding: 44px 0;

                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .types-raiway-wagon-list-name {
                                ul {
                                    list-style-position: inside;

                                    li {
                                        color: var(--theme-heading-text-color);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}