@import "../../styles/mixins/global.scss";

.hs-code-section {
  padding: 60px 0;

  .hs-code-alignment {
    .hs-code-heading {
      h4 {
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.8;
        text-align: center;
      }
    }

    .hs-code-search-alignment {
      padding: 32px 0 0 0;

      @include breakpoint("max-sm") {
        padding: 30px 0 0 0;
      }

      .hs-code-search-box-alignment {
        max-width: 560px;
        margin: 0 auto;
        height: 50px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        gap: 10px;

        input {
          width: 100%;
          height: 100%;
          border: none;
          color: rgba(0, 0, 0, 0.8);
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;

          &:focus {
            outline: none;
          }
        }

        svg {
          cursor: pointer;
        }
      }
    }
  }
}
