@import "../../../styles/mixins/global.scss";

.ts-support-section {
    .ts-support-background {
        background: url("../../../assets/imges/blue-bg.png");
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 52px 0;
        text-align: center;

        h4 {
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #343434;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
              }
        }

        .ts-support-details-alignment {
            padding: 80px 0 0 0;

            .ts-support-details-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 24px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 60px;
                }

                .ts-support-details-gridItem {
                    .ts-support-details-box {
                        background: #FFFFFF;
                        border-radius: 8px;

                        .ts-support-heading-box {
                            background: #00AEEF;
                            box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.08);
                            border-radius: 8px 8px 0px 0px;
                            height: 84px;
                            width: 100%;

                            .ts-support-top-box-alignment {
                                width: 106px;
                                height: 106px;
                                background: #FFFFFF;
                                box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.14);
                                border-radius: 50%;
                                position: relative;
                                top: -50px;
                                margin: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .ts-support-body-alignment {
                            padding: 20px 32px 24px 30px;

                            h4 {
                                font-family: 'Lato', sans-serif;
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 29px;
                                color: #343434;
                                padding: 0 0 8px 0;
                            }

                            p {
                                max-width: 234px;
                                font-family: 'Lato', sans-serif;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 26px;
                                color: #606060;
                                text-align: center;
                                margin: auto;
                            }

                            .ts-support-payment-option-details {
                                padding: 18px 0 0 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 24px;
                            }

                            .ts-support-view-all-alignment {
                                padding: 30px 0 0 0;

                                a {
                                    font-family: 'Lato', sans-serif;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #00AEEF;
                                    cursor: pointer;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}