@import "../../styles/mixins/global.scss";


.post-your-request-section {
    padding: 35px 0;
    background: #FFF;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    .post-your-request-heading {
        h4 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            text-align: center;


            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .post-your-request-banner-details {
        padding: 45px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 30px 0 0 0;
        }

        .post-your-request-grid {
            display: grid;
            grid-template-columns: 1fr 342px;
            gap: 226px;

            @include breakpoint("max-sm") {
                gap: 40px;
                grid-template-columns: repeat(1 , 1fr);
                display: block;
            }

            .post-your-request-grid-item {
                .post-your-video-box {
                    width: 100%;
                    height: 245px;
                    border: 1px solid #000;
                    background: #D9D9D9;

                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }

                .post-your-left-box {
                    width: 100%;
                    height: auto;
                    border: 1px solid #000;
                    padding: 14px 8px 18px 8px;

                    .post-your-top-details {
                        display: flex;
                        align-items: center;
                        gap: 26px;

                        .post-your-profile-img {
                            width: 51px;
                            max-width: 51px;
                            min-width: 51px;
                            height: 51px;
                            border: 1px solid #000;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                border-radius: 50%;
                            }
                        }

                        .post-your-profile-details {
                            h6 {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 14px;
                                padding: 0 0 5px 0;

                                &:last-child {
                                    padding: 0;
                                }
                            }
                        }

                    }
                    .post-your-description {
                        padding: 12px 0 0 0;

                        p {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 21px;
                        }
                    }
                }
            }
        }
    }
}