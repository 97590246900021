@import '../../styles/mixins/global.scss';

.supplier-payment-section {
    background-color: #f8f8f8;

    .supplier-payment-bg-alignment {
        background: url('../../assets/Image/payment-bg.png');
        width: 100%;
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include breakpoint("max-sm") {
            height: auto;
        }
        .supplier-payment-banner-details-alignment {
            display: flex;
            justify-content: center;
            height: 500px;
            flex-direction: column;
            gap: 21px;
            position: relative;

            @include breakpoint("max-sm") {
                height: auto;
                padding: 40px 0;
            }

            &::before {
                position: absolute;
                content: '';
                background: url('../../assets/Image/payment-banner-icon.png');
                bottom: 0;
                right: 0;
                z-index: 9;
                width: 710px;
                height: 467px;

                @include breakpoint("max-sm") {
                    display: none;
                }
            }

            h4 {
                max-width: 480px;
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-weight: 700;
                line-height: 48px;
                opacity: 0.8;
            
                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 2px;
                max-width: 644px;

                @include breakpoint("max-sm") {
                    letter-spacing: 0;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

        }
    }
}