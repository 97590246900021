@import "../../../../styles/mixins/global.scss";

.goods-transportation-all-content-alignment {
  padding: 24px 0 100px 0;


  @include breakpoint("max-sm") {
    padding: 24px 0 40px 0;
  }

  .white-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 53px 80px;


    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    .two-col-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 34px;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 20px;
      }

      .two-col-grid-items {
        background: #ffffff;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
        padding: 24px;

        .icon {
          height: calc(32px + 24px);
        }
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #343434;
          margin: 0 0 12px 0;
        }
        ul {
          padding-left: 1.25rem;
          li {
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            padding: 0 0 5px 0;
            color: #606060;
          }
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          padding: 0 0 20px 0;
          color: #606060;
          display: block;
        }
      }
    }

    .sec-grid {
      display: grid;
      grid-template-columns: 1fr 452px;
      align-items: center;
      padding: 0 0 50px 0;
      gap: 67px;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      .sec-grid-items {
        .image-style {
          img {
            width: 100%;
            object-fit: cover;
            border-radius: 38px;
            height: 294px;
          }
        }

        .icon-style {
          padding: 0 0 24px 0;
          img {
            cursor: pointer;
          }
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
          padding: 0 0 24px 0;
          max-width: 501px;
        }

        button {
          border: none;
          font-weight: 500;
            font-size: 14px;
            line-height: 22px;
          color: #ffffff;
          background: #00aeef;
          border-radius: 7px;
          padding: 6px 18px;
          cursor: pointer;
        }
      }
    }

    .box-center-alignment {
      max-width: 933px;
      margin: 0 auto;

      .main-grid {
        gap: 66px 106px;
        display: grid;
        padding: 50px 0 80px 0;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint("max-md") {
          grid-template-columns: repeat(1 , 1fr);
          gap: 40px;
        }

        .main-grid-items {
          display: grid;
          grid-template-columns: 50px 1fr;
          gap: 10px;

          div {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #606060;
              display: block;
            }
          }
        }
      }
    }

    .center-text {
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #343434;
        margin: 0 0 14px 0;
      }

      span {
        font-weight: 400;
        margin: 0 auto;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #606060;
        max-width: 682px;
        display: block;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 408px;
      gap: 72px;
      align-items: center;
      padding: 0 0 50px 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      
      .grid-items {
        .image-style {
          img {
            width: 100%;
          }
        }

        .button-text-alignment {
          display: flex;
          padding: 30px 0 0 0;
          align-items: center;
          gap: 11px;

          a {
            display: block;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            line-height: 130.5%;
            color: #00aeef;
          }

          button {
            background: #00aeef;
            border-radius: 7px;
            padding: 6px 18px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            cursor: pointer;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 14px 0;
          color: #343434;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
          max-width: 602px;
        }
      }
    }
  }
}
