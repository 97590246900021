@import "../../styles/mixins/index.scss";

.remote-inspection-section {
  background-color: #f8f8f8;
  padding: 36px 0;

  .remote-inspection-alignment {
    .remote-inspection-grid {
      display: grid;
      grid-template-columns: 350px 1fr;
      gap: 48px;
      align-items: center;

      .remote-inspection-details {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ImageBox {
          background: #F4812033;
          width: 720px;
          height: 279px !important;
          gap: 0px;
          border-radius: 130px;
          opacity: 0px;
          height: 30px;
        }

        .image1 {
          position: absolute;
          top: 30px;
          left: 190px;

          img {
            width: 205.06px;
            height: 205.06px;
            gap: 0px;
            opacity: 0px;
          }
        }

        .image2 {
          position: absolute;
          top: -100px;
          left: 310px;

          img {
            width: 466.06px;
            height: 500.23px;
            top: 100.98px;
            left: 893.17px;
            gap: 0px;
            border-radius: 125px 0px 0px 0px;
            opacity: 0.8px;
            angle: -45 deg;
          }
        }

        .image3 {
          position: absolute;
          top: 30px;
          right: 20px;

          img {
            width: 204.35px;
            height: 204.35px;
            gap: 0px;
            opacity: 0px;

          }
        }

        .line1 {
          position: absolute;
          top: 20px;
          left: 140px;
        }

        .line2 {
          position: absolute;
          top: 10px;
          left: 100px;
        }

        .spancircle1 {
          width: 7px;
          height: 7px;
          position: absolute;
          top: -10px;
          left: 78px;
          background-color: #EC1C24;
          border-radius: 50%;
        }

        .spancircle2 {
          width: 6px;
          height: 6px;
          position: absolute;
          top: 10px;
          left: 130px;
          background-color: #F48120;
          border-radius: 50%;
        }

      }

      .remote-inspection-right-side-alignemnt {
        h4 {
          color: var(--theme-heading-text-color);
          font-family: Montserrat;
          font-size: 70px;
          font-weight: 600;
          line-height: 85.33px;
          text-align: left;
          color: #042953;
        }
      }
    }

  }

  .remote-inspection-all-details-alignmnent {
    padding: 112px 0 0 0;

    @include breakpoint("max-sm") {
      padding: 40px 0 0 0;
    }
  }

  .benefit-remote-inspection-details {
    .benefit-remote-inspection-heading {
      h6 {
        color: var(--theme-heading-text-color);
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @include breakpoint("max-sm") {
          font-size: 20px;
        }
      }
    }

    .benefit-remote-inspection-all-details {
      padding: 52px 0 0 0;

      @include breakpoint("max-sm") {
        padding: 18px 0 0 0;
      }

      .benefit-remote-inspection-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 21px;

        @include breakpoint("max-sm") {
          grid-template-columns: 1fr;
          gap: 30px;
        }

        .benefit-remote-inspection-child-details {
          .remote-inspection-child-details {
            display: flex;
            justify-content: center;
          }

          h6 {
            color: var(--theme-heading-text-color);
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            opacity: 0.8;
            padding: 30px 0 0 0;
            text-align: center;

            @include breakpoint("max-sm") {
              font-size: 16px;
              font-weight: 700;
              padding: 8px 0 0 0;
            }
          }
        }
      }
    }
  }

  .fullbg {
    width: 100%;
    height: 400px;
    top: 762px;
    gap: 0px;
    opacity: 0px;
    background: #DCF5FF;
    margin-top: 64px;

    div {
      h3 {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
        line-height: 54.86px;
        text-align: left;
        color: #042953;
      }

      div {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 24.38px;
        text-align: left;
        color: #000000;
        margin-right: 200px;
        padding-top: 16px;
      }
    }

    .helpeingalign {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div {
        margin-right: 16px;

        .imageshadow {
          background: #ECFAFF;
          box-shadow: 0px 2px 4px 0px #00000040;
          padding: 2px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 30px;
            height: 30px;
            top: 1025px;
            left: 160px;
            gap: 0px;
            opacity: 0px;
          }

        }

        div {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 600;
          line-height: 21.94px;
          text-align: left;
          color: #000000;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .remote-inspection-all-child-details-alignment {
    margin: 0 0 102px 0;

    @include breakpoint("max-sm") {
      margin: 0 0 60px 0;
    }

    .remote-inspection-heading-alignment {
      h6 {
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #042953;

        @include breakpoint("max-sm") {
          font-size: 20px;
        }
      }
    }

    .romote-inspection-option-details-alignment {
      padding: 54px 0 0 0;

      @include breakpoint("max-sm") {
        padding: 18px 0 0 0;
      }

      .remote-inspection-child-grid-alignment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 38px 124px;

        @include breakpoint("max-sm") {
          grid-template-columns: 1fr;
          gap: 30px;
        }

        .remote-inspection-child-grid-item {
          .bordershadow {
            width: 440px;
            height: 210px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              width: calc(100% + 10px);
              height: calc(100% + 10px);
              border-top: 80px solid #EC1C24;
              border-radius: 300px;
              border-bottom: 75px solid #00A551;
              box-shadow: 0px 0px 4px 5px #0000001A;
              transform: skew(-25deg);
            }

            .remote-option-details-img {
              width: 450px;
              height: 220px;
              rotate: 0deg;
              border-radius: 121px;
              opacity: 0px;
              background: #FFFFFF;
              box-shadow: 0px 0px 4px 5px #0000001A;
              text-align: center;
              padding: 16px 0px;

              img {
                width: 200px;
                height: 185.46px;
                gap: 0px;
                opacity: 0px;
              }
            }
          }

          .remote-option-details-alignment {
            padding: 24px 0 0 0;
            text-align: center;

            @include breakpoint("max-sm") {
              padding: 8px 0 0 0;
            }

            h6 {
              color: var(--theme-heading-text-color);
              font-family: "Poppins", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              opacity: 0.8;
              margin-left: -99px;

              @include breakpoint("max-sm") {
                font-size: 16px;
                font-weight: 700;
              }
            }

            p {
              color: var(--theme-heading-text-color);
              text-align: center;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 16px 99px;
              margin-left: -99px;


              @include breakpoint("max-sm") {
                font-size: 14px;
                padding: 8px 0 0 0;
              }
            }
          }
        }
      }
    }
  }
}


$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
@media (max-width: $breakpoint-xs) {
  .remote-inspection-section {
    .remote-inspection-alignment {
      .remote-inspection-grid {
        display: block !important;

        .remote-inspection-details {
          margin-top: 32px;

          .ImageBox {
            width: 100% !important;
            height: 200px !important;
          }

          .image1 {
            top: 30px;
            left: 0px;

            img {
              width: 150px;
              height: 150px;
            }
          }

          .image2 {
            top: 0px;
            left: 110px;

            img {
              width: 200px;
              height: 250px;
            }
          }

          .image3 {
            top: 30px;
            right: 0px;

            img {
              width: 150px;
              height: 150px;
            }
          }

          .line1 {
            top: 0px;
            left: 10px;
          }

          .line2 {
            top: -10px;
            left: 70px;
          }

          .spancircle1 {
            top: -30px;
            left: 60px;
          }

          .spancircle2 {
            top: -10px;
            left: 6px;
          }

        }

        .remote-inspection-right-side-alignemnt {
          margin-top: -32px;

          h4 {
            font-size: 36px !important;
            line-height: 40px;
          }
        }
      }

    }

    .benefit-remote-inspection-details {
      .benefit-remote-inspection-all-details {
        .benefit-remote-inspection-grid {
          display: block !important;
        }
      }
    }

    .fullbg {
      height: 100%;

      div {
        h3 {
          font-size: 36px;
        }

        div {
          margin-right: 0px;
        }
      }

      .helpeingalign {
        display: block;
      }
    }

    .remote-inspection-all-child-details-alignment {
      .romote-inspection-option-details-alignment {
        .remote-inspection-child-grid-alignment {
          display: block;

          .remote-inspection-child-grid-item {
            .bordershadow {
              width: 250px;
              margin: 0 auto !important;

              .remote-option-details-img {
                width: 260px;
              }
            }

            .remote-option-details-alignment {
              h6 {
                margin-left: 0px !important;
                padding-top: 16px;
              }

              p {
                padding: 16px 0px;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
  .remote-inspection-section {
    .remote-inspection-alignment {
      .remote-inspection-grid {
        display: block !important;

        .remote-inspection-details {
          .ImageBox {
            width: 100%;
          }

          .image1 {
            position: absolute;
            top: 30px;
            left: 10px;
          }

          .image2 {
            position: absolute;
            top: -100px;
            left: 122px;
          }

          .image3 {
            position: absolute;
            top: 30px;
            right: 20px;
          }

          .line1 {
            position: absolute;
            top: -20px;
            left: 60px;
            rotate: 30deg;
          }

          .line2 {
            position: absolute;
            top: -20px;
            left: 20px;
            rotate: 30deg;
          }

          .spancircle1 {
            top: -60px;
            left: 53px;
          }

          .spancircle2 {
            top: 10px;
            left: 130px;
          }

        }

        .remote-inspection-right-side-alignemnt {
          margin-top: -32px;
          margin-bottom: 100px;

          h4 {
            font-size: 56px !important;
            line-height: 60px;
          }
        }
      }

    }

    .benefit-remote-inspection-details {
      .benefit-remote-inspection-all-details {
        .benefit-remote-inspection-grid {
          display: block !important;
        }
      }
    }

    .fullbg {
      height: 100%;
      width: 100%;

      div {
        h3 {
          font-size: 32px;
        }

        div {
          margin-right: 0px;
        }
      }

      .helpeingalign {
        gap: 32px !important;

        div {
          width: 100%;
        }
      }
    }

    .remote-inspection-all-child-details-alignment {
      .romote-inspection-option-details-alignment {
        .remote-inspection-child-grid-alignment {
          display: block;

          .remote-inspection-child-grid-item {
            .bordershadow {
              width: 340px;
              margin: 0 auto !important;

              .remote-option-details-img {
                width: 350px;
              }
            }

            .remote-option-details-alignment {
              h6 {
                margin-left: 0px !important;
                padding-top: 16px;
              }

              p {
                padding: 16px 36px;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .remote-inspection-section {
    .remote-inspection-alignment {
      .remote-inspection-grid {
        display: block !important;
        margin-bottom: 32px;

        .remote-inspection-details {
          .ImageBox {
            width: 100%;
          }

          .image1 {
            position: absolute;
            top: 30px;
            left: 10px;
          }

          .image2 {
            position: absolute;
            top: -100px;
            left: 122px;
          }

          .image3 {
            position: absolute;
            top: 30px;
            right: 20px;
          }

          .line1 {
            position: absolute;
            top: -20px;
            left: 60px;
            rotate: 30deg;
          }

          .line2 {
            position: absolute;
            top: -20px;
            left: 20px;
            rotate: 30deg;
          }

          .spancircle1 {
            top: -60px;
            left: 53px;
          }

          .spancircle2 {
            top: 10px;
            left: 130px;
          }

        }

        .remote-inspection-right-side-alignemnt {
          margin-top: -32px;
          margin-bottom: 100px;

          h4 {
            font-size: 56px !important;
            line-height: 60px;
          }
        }
      }

    }

    .benefit-remote-inspection-details {
      .benefit-remote-inspection-all-details {
        .benefit-remote-inspection-grid {
          display: block !important;
        }
      }
    }

    .fullbg {
      height: 100%;
      width: 100%;
      padding-bottom: 32px;

      div {
        h3 {
          font-size: 32px;
        }

        div {
          margin-right: 0px;
        }
      }

      .helpeingalign {
        gap: 32px !important;

        div {
          width: 100%;
        }
      }
    }

    .remote-inspection-all-child-details-alignment {
      .romote-inspection-option-details-alignment {
        .remote-inspection-child-grid-alignment {
          grid-template-columns: repeat(2, 1fr);

          .remote-inspection-child-grid-item {
            .bordershadow {
              width: 270px;
              margin: 0 auto !important;

              .remote-option-details-img {
                width: 280px;
              }
            }

            .remote-option-details-alignment {
              h6 {
                margin-left: 0px !important;
                padding-top: 16px;
              }

              p {
                padding: 16px 0px;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  .remote-inspection-section {
    .remote-inspection-alignment {
      .remote-inspection-grid {
        grid-template-columns: 300px 1fr;
        gap: 26px;

        .remote-inspection-details {
          width: 100% !important;

          .ImageBox {
            width: 600px;
          }

          .image1 {
            top: 30px;
            left: 0px;
          }

          .image2 {
            position: absolute;
            top: -100px;
            left: 100px;

            img {
              width: 430.06px;
            }
          }

          .image3 {
            top: 30px;
            right: 0px;
          }

          .line1 {
            position: absolute;
            top: -20px;
            left: 50px;
          }

          .line2 {
            position: absolute;
            top: 10px;
            left: 20px;
          }

          .spancircle1 {
            top: -10px;
            left: 10px;
          }

          .spancircle2 {

            top: -40px;
            left: 40px;
          }

        }

        .remote-inspection-right-side-alignemnt {
          h4 {
            font-size: 56px;
            line-height: 65.33px;

          }
        }
      }

    }

    .benefit-remote-inspection-details {
      .benefit-remote-inspection-all-details {
        .benefit-remote-inspection-grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .fullbg {
      div {
        div {
          margin-right: 90px;
        }
      }

      .helpeingalign {
        div {
          div {
            text-overflow: ellipsis;
          }
        }
      }
    }

    .remote-inspection-all-child-details-alignment {
      .romote-inspection-option-details-alignment {
        .remote-inspection-child-grid-alignment {
          gap: 38px 36px;

          .remote-inspection-child-grid-item {
            .bordershadow {
              width: 400px;

              .remote-option-details-img {
                width: 410px;
              }
            }
          }
        }
      }
    }
  }
}