@import "../../../styles/mixins/global.scss";
.grow-your-business-banner {
  background-image: url("../../../assets/imges/business-banner.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  padding: 50px 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  h6 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 30px 0;
    color: #ffffff;
    text-align: center;

    @include breakpoint("max-sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .btn-center-alignment {
    display: flex;
    justify-content: center;
    padding: 30px 0 0 0;

    @include breakpoint("max-sm") {
      padding: 10px 0 0 0;
    }

    button {
      background-color: transparent;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 130.5%;
      color: #ffffff;
      background: #00aeef;
      border: 1px solid #ffffff;
      border-radius: 7px;
      padding: 9px 13px;
      @include breakpoint("max-sm") {
        font-size: 14px;
      }
    }
  }
}
