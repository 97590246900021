@import "../../../styles/mixins/global.scss";

.all-shipping-lead-section {
    padding: 30px 0;

    .all-shipping-grid-alignment {
        display: grid;
        grid-template-columns: 384px 1fr;
        gap: 24px;

        @include breakpoint("max-sm") {
            grid-template-columns: 1fr;
        }

        .all-shipping-gridItem-alignment {
            .all-shipping-left-side-alignment {
                .new-request-box-alignment {

                    width: 100%;
                    height: 100px;
                    border-radius: 8px;
                    background: #B6E4F5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    cursor: pointer;

                    @include breakpoint("max-sm") {
                        height: 70px;
                        gap: 12px;
                    }

                    .plus-icon-alignment {
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        border-radius: 46px;
                        background: rgba(0, 174, 239, 0.20);
                        color: var(--blue, #00AEEF);
                        font-size: 30px;
                       font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include breakpoint("max-sm") {
                            width: 36px;
                            height: 36px;
                        }

                    }

                    p {
                        color: #00AEEF;
                        font-size: 24px;
                       font-family: 'Poppins', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;

                        @include breakpoint("max-sm") {
                            font-size: 16px;
                        }
                    }
                }

                .request-id-box-alignment {
                    margin: 20px 0 0 0;

                    border-radius: 8px;
                    background: #D9D9D9;
                    padding: 20px;

                    @include breakpoint("max-sm") {
                        padding: 16px;
                    }

                    .request-id-heading {
                        padding: 0 0 10px 0;
                        border-bottom: 1px solid #BABABA;

                        h5 {
                            color: #606060;
                            font-size: 20px;
                           font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                    }

                    .shipping-rount-alignment {
                        padding: 20px 0;

                        @include breakpoint("max-sm") {
                            padding: 16px 0;
                        }

                        h6 {
                            color: #606060;
                            font-size: 14px;
                           font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .shipping-rount-address-box {
                            margin: 10px 0 0 0;
                            border-radius: 4px;
                            border: 0.5px solid #BABABA;
                            background: rgba(186, 186, 186, 0.10);

                            .shipping-rount-aadres-name {
                                padding: 20px 16px;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                border-bottom: 1px solid #BABABA;

                                @include breakpoint("max-sm") {
                                    padding: 12px;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }

                                span {
                                    color: #00AEEF;
                                    font-size: 16px;
                                   font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    line-height: normal;
                                    text-transform: uppercase;
                                }

                                p {
                                    color: #606060;
                                    font-size: 14px;
                                   font-family: 'Poppins', sans-serif;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    text-transform: capitalize;
                                }

                            }
                        }
                    }

                    .type-of-shipping-alignment {


                        h6 {
                            color: #606060;
                            font-size: 14px;
                           font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .shipping-details-option-alignment {
                            padding: 20px 0 0 0;

                            .option-heading-alignment {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                padding: 0 0 8px 0;

                                @include breakpoint("max-sm") {
                                    padding: 8px 0;
                                }

                                input[type="checkbox"] {
                                    width: 20px;
                                    height: 20px;
                                    padding: 0;
                                    margin: 0;
                                }

                                label {
                                    color: #606060;
                                    font-size: 14px;
                                   font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    line-height: normal;
                                    text-transform: capitalize;
                                }
                            }

                            .oprion-option-box-alignment {
                                margin: 8px 0 0 0;
                                border-radius: 4px;
                                border: 0.5px solid var(--light-grey, #BABABA);
                                background: rgba(186, 186, 186, 0.10);

                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 8px 14px;

                                .option-name-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 4px;

                                    span {
                                        color: #00AEEF;
                                        font-size: 12px;
                                       font-family: 'Poppins', sans-serif;
                                        font-weight: 500;
                                        line-height: normal;
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }

                    .shipping-button-alignment {
                        padding: 51px 0 0 0;

                        @include breakpoint("max-sm") {
                            padding: 25px 0 0 0;
                        }

                        button {
                            border-radius: 4px;
                            background: #00AEEF;
                            height: 48px;
                            width: 100%;
                            border: none;
                            color: #FFF;
                            font-size: 20px;
                           font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: capitalize;
                            cursor: pointer;


                            @include breakpoint("max-sm") {
                                height: 36px;
                                font-size: 16px;
                                font-weight: 500;
                            }

                        }
                    }
                }

            }

            .all-shipping-right-side-alignment {
                border-radius: 8px;
                background: #FFF;
                padding: 30px;

                @include breakpoint("max-sm") {
                    padding: 16px;
                }

                .all-shipping-details-grid-alignment {
                    display: grid;
                    grid-template-columns: 160px 1fr;
                    gap: 40px;
                    border-bottom: 1px solid #BABABA;
                    padding: 20px 0 30px 0;

                    @include breakpoint("max-sm") {
                        grid-template-columns: 1fr;
                        gap: 20px;
                    }

                    &:first-child {
                        padding: 0 0 30px 0;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    .all-shipping-details-gridItem {
                        .all-shipping-first-part {
                            h6 {
                                color: #00AEEF;
                                font-size: 16px;
                               font-family: 'Poppins', sans-serif;
                                font-weight: 700;
                                line-height: normal;
                                text-transform: capitalize;
                            }

                            p {
                                padding: 6px 0 0 0;
                                color: #BABABA;
                                font-size: 12px;
                               font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;

                            }

                            .tranform-img-alignment {
                                padding: 24px 0 0 0;


                                @include breakpoint("max-sm") {
                                    padding: 16px 0 0 0;
                                }

                            }
                        }

                        .all-shipping-second-part {
                            .all-shipping-first-row-alignment {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .shiping-address-name-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    p {
                                        color: #343434;
                                        font-size: 16px;
                                       font-family: 'Poppins', sans-serif;
                                        font-weight: 700;
                                        line-height: normal;
                                        text-transform: capitalize;

                                        @include breakpoint("max-sm") {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                            .all-shipping-rount-box-alignment {
                                margin: 20px 0 0 0;
                                max-width: 358px;
                                border-radius: 4px;
                                border: 0.5px solid var(--light-grey, #BABABA);
                                background: rgba(186, 186, 186, 0.10);
                                padding: 14px;

                                display: flex;
                                align-items: flex-start;
                                gap: 12px;


                                .all-shipping-rout-box-img-alignment {
                                    width: 12px;
                                    height: 60px;
                                    min-width: 12px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                    }
                                }

                                .all-shipping-other-details-alignment {
                                    width: 100%;
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-between;

                                    .all-shipping-name-alignment {
                                        padding: 0 0 11px 0;

                                        &:last-child {
                                            padding: 0;
                                        }

                                        p {
                                            color: #606060;
                                            font-size: 12px;
                                           font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            line-height: normal;
                                            text-transform: capitalize;
                                        }

                                        .all-shipping-contry-alignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;
                                            padding: 8px 0 0 0;

                                            .counrty-img {
                                                width: 23px;
                                                height: 14px;
                                            }

                                            p {
                                                color: #343434;
                                                font-size: 14px;
                                               font-family: 'Poppins', sans-serif;
                                                font-weight: 400;
                                                line-height: normal;
                                                text-transform: capitalize;
                                            }
                                        }
                                    }
                                }

                            }

                            .all-shipping-wight-other-details-alignment {
                                padding: 10px 0 0 0;

                                .all-shipping-other-chilg-details {
                                    display: flex;
                                    align-items: center;
                                    gap: 34px;
                                    padding: 0 0 10px 0;

                                    &:last-child {
                                        padding: 0;
                                    }

                                    p {
                                        width: 115px;
                                        color: #606060;
                                        font-size: 14px;
                                       font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        line-height: normal;
                                        text-transform: capitalize;
                                    }

                                    span {
                                        color: #606060;
                                        font-size: 14px;
                                       font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        line-height: normal;
                                        text-transform: capitalize;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}