@import "../../../styles/mixins/global.scss";

.key-documentation-section {
    padding: 172px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 0;
    }

    .key-documentation-heading {

        h4 {
            text-align: center;
            opacity: 0.8;
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .key-deocument-details-alignment {
        padding: 82px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }

        .key-deocument-details-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 55px 20px;
            flex-wrap: wrap;

            .key-document-box-alignment {
                width: 367px;
                min-width: 367px;
                max-width: 367px;
                height: 290px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            p {
                padding: 30px 0 0 0;
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 28px;
                font-weight: 600;
                line-height: 32px;
                text-align: center;
                max-width: 263px;
                margin: 0 auto;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}