@import '../../../styles/mixins/global.scss';

.not-accepted-payment-section {
    padding: 132px 0 82px 0;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    .not-accepted-payment-alignment {
        max-width: 853px;
        margin: 0 auto;
        text-align: center;

        h6 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;
            opacity: 0.8;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }

        p {
            color: var(--theme-heading-text-color);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            padding: 28px 0 0 0;


            @include breakpoint("max-sm") {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .not-accepted-box-alignment {
            margin: 42px auto 0 auto;
            width: 443px;
            height: 409px;
            border-radius: 10px;
            background: #F9F9F9;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint("max-sm") {
                width: 100%;
                height: 320px;
            }
        }
    }
}