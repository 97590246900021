@import "../../../styles/mixins/index.scss";

.customs-problems-section {
    padding: 106px 0 0 0;

    .customs-problems-heading {
        h4 {
            color: rgba(0, 0, 0, 0.8);
            font-size: 40px;
            font-weight: 700;
            line-height: 46px;
            text-align: center;
        }
    }

    .customs-problem-body-alignment {
        padding: 64px 0 0 0;

        .customs-problem-flex-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 100px 0;

            @include breakpoint("max-sm") {
               flex-wrap: wrap;
            }
            .customs-problem-details-img {
                width: 407px;
                height: 316px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .customs-problem-child-details {
                max-width: 579px;

                h4 {
                    color: rgba(0, 0, 0, 0.8);
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 36px;
                }

                p {
                    padding: 30px 0 0 0;
                    color: #000;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                    text-transform: capitalize;
                }
            }
        }
    }
}