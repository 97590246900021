@import '../../../../styles/mixins/global.scss';

.what-we-are-section {
    padding: 50px 0 0 0;

    .what-we-are-heading-alignment {
        h4 {
            color: var(--theme-heading-text-color);
           font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
              }
        }
    }

    .what-we-are-details {
        padding: 20px 0 50px 0;

        .what-we-are-details-box-alignment {
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.12);
            padding: 24px;
            display: grid;
            grid-template-columns: 1fr 462px;
            gap: 24px;
            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 40px;
            }
            .what-we-are-details-heading {
                h5 {
                    color: #606060;
                   font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 134.5%;
                    max-width: 364px;


                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    padding: 10px 0 0 0;
                    color: #BABABA;
                   font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .what-we-are-details-box-alignment {
        padding: 20px 0 48px 0;

        .what-we-are-details-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;


            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
            }

            .what-we-are-details-gridItem {
                .what-we-are-details-child-box {
                    border-radius: 4px;
                    background: #FFF;
                    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.08);
                    padding: 24px;

                    h5 {
                        color: #606060;
                       font-family: 'Poppins', sans-serif;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 28px;
                        padding: 20px 0 0 0;
                    }

                    p {
                        padding: 10px 0 0 0;
                        color: #BABABA;
                       font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}