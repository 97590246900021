@import '../../../styles/mixins/global.scss';

.marine-insurance-act1963-section {
    padding: 154px 0 82px 0;

    .marine-insurance-act1963-alignment {
        .marine-insurance-act1963-heading {
            text-align: center;

            h4 {
                color: rgba(0, 0, 0, 0.8);
                font-size: 45px;
                font-weight: 600;
                line-height: 54px;
            }
        }

        .marine-insurance-act1963-list {
            padding: 52px 0 0 0;
            max-width: 826px;
            margin: 0 auto;

            p {
                color: rgba(0, 0, 0, 0.8);
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                padding: 0 0 32px 0;
                text-align: center;
            }
        }
    }
}