@import "../../../styles/mixins/global.scss";

.largest-degital-all-content-alignment {
  background-color: #fff;

  .sec-section {
    padding: 50px 0;

    .text {
      padding: 0 0 20px 0;
      h4 {
        font-weight: 700;
        text-align: center;
        font-size: 24px;
        line-height: 36px;
        color: #343434;
        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .button-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 26px 0 0 0;

      button {
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 130.5%;
        background: #00aeef;
        border-radius: 7px;
        padding: 9px 36px;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .text-grid {
      max-width: 1092px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      position: relative;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 30px;
      }

      &::before {
        position: absolute;
        width: 65%;
        left: 18%;
        background-color: #00aeef;
        content: "";
        top: 30px;
        height: 1px;
      }

      p {
        margin: 20px 0 10px 0;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #606060;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 132.5%;
        text-align: center;
        color: #606060;
        display: block;
      }

      .icon-center-alignment {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
        justify-content: center;

        .icon {
          width: 50px;
          height: 50px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #fff;
          background: rgba(0, 174, 239);
          border: 1px solid #00aeef;
          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }

    .center-box {
      display: flex;
      justify-content: center;
      padding: 0 0 50px 0;

      .box {
        width: 589px;
        background: #606060;
        border-radius: 5px;
        height: 305px;
      }
    }
  }

  .first-section {
    padding: 50px 0;
    border-bottom: 1px solid #bababa;

    .text {
      padding: 0 0 50px 0;
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #343434;
        text-align: center;
        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 20px;
      }

      .grid-items {
        background: #ffffff;
        box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        padding: 20px;

        .icon {
          height: calc(70px + 30px);
        }

        p {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #606060;
          margin: 0 0 10px 0;
        }

        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 132.5%;
          color: #606060;
          display: block;
        }
      }
    }
  }
}
