@import '../../../styles/mixins/global.scss';

.what-coverded-section {
    padding: 146px 0 0 0;

    .what-coverded-heading {
        h4 {
            color: rgba(0, 0, 0, 0.8);
            font-size: 45px;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
        }
    }

    .what-coverded-box-alignment {
        max-width: 946px;
        border-radius: 10px;
        border: 1px solid rgba(0, 174, 239, 0.5);
        padding: 54px;
        background: rgba(0, 174, 239, 0.10);
        margin: 52px auto 0 auto;

        .what-covered-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 60px 20px;

            .what-covered-grid-item {
                text-align: center;

                p {
                    color: #606060;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 130.5%;
                }

            }
        }
    }
}