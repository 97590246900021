@import "../../../../styles/mixins/global.scss";

.warehouing-details-all-content-alignment {
  padding: 24px 0 100px 0;

  .box {
    padding: 50px 80px;
    background: #ffffff;
    border-radius: 8px;


    @include breakpoint("max-sm") {
      padding: 24px;
    }

    .common-box {
      background: #ffffff;
      border-radius: 4px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      .item:nth-child(1) {
        border-right: 1px solid #bababa;

        @include breakpoint("max-md") {
          border: none;
        }
      }

      .item:nth-child(2) {
        padding-left: 30px;

        @include breakpoint("max-md") {
          padding-left: 0;
        }
      }

      .icon-center-alignment {
        height: calc(78px + 20px);
      }

      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #343434;
        margin: 0 0 26px 0;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        color: #606060;
      }

      ul {
        padding-left: 1.25rem;
        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 34px;
          color: #606060;
        }
      }
    }

    .hading-grid {
      display: grid;
      grid-template-columns: 1fr 521px;
      gap: 50px;
      padding: 0 0 55px 0;


      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }
      .hading-grid-items:nth-child(1) {
        @include breakpoint("max-md") {
          order: 2;
        }
      }
      .hading-grid-items {
        .image-style {
          img {
            width: 100%;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          margin: 0 0 14px 0;
          line-height: 26px;
          color: #343434;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
        }
      }
    }

    .ware-grid {
      display: grid;
      grid-template-columns: 1fr 442px;
      gap: 77px;
      padding: 0 0 50px 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }
      .ware-grid-items {
        p {
          font-weight: 600;
          margin: 0 0 14px 0;
          font-size: 20px;
          line-height: 26px;
          color: #343434;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          padding: 0 0 20px 0;
          color: #606060;
        }

        .image-style {
          img {
            width: 100%;
            border-radius: 12px;
          }
        }
      }
    }

    .rule-text {
      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #343434;
        text-align: center;
        margin: 0 0 56px 0;
      }

      .new-box {
        max-width: 854px;
        margin: 0 auto;


        @include breakpoint("max-sm") {
          width: 100%;
        }

        .sub-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 35px 70px;
          padding: 0 0 50px 0;

          @include breakpoint("max-md") {
            grid-template-columns: repeat(2 , 1fr);
            gap: 20px;
          }
          @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 20px;
          }
          .sub-grid-items {
            .icon-center {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 0 6px 0;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              text-align: center;
              color: #606060;

              @include breakpoint("max-sm") {
                word-break: break-all;
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .text-center-style {
      padding: 0 0 50px 0;
      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        max-width: 907px;
        margin: 0 auto;
        text-align: center;
        color: #606060;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 472px;
      gap: 127px;
      align-items: center;
      padding: 0 0 50px 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      .grid-items {
        .image {
          img {
            width: 100%;
          }
        }

        .button-text-alignment {
          display: flex;
          align-items: center;
          gap: 11px;
          padding: 30px 0 0 0;
          a {
            display: block;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            line-height: 130.5%;
            color: #00aeef;
          }

          button {
            background: #00aeef;
            border-radius: 7px;
            padding: 6px 18px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            cursor: pointer;
          }
        }
        p {
          font-weight: 600;
          margin: 0 0 14px 0;
          font-size: 20px;
          line-height: 26px;
          color: #343434;
          
        }

        span {
          max-width: 521px;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;

          @include breakpoint("max-sm") {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
