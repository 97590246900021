@import "../../../styles/mixins/global.scss";

.store-front-banner-design {
  background-image: url("../../../assets/imges/store-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0 94px 0;


  @include breakpoint("max-sm") {
    padding: 60px 0;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 593px;
    gap: 60px;
    align-items: center;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 40px;
    }

    .grid-items {
      .white-box {
        width: 100%;
        height: 315px;
        background-color: #fff;
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
border-radius: 8px;

@include breakpoint("max-sm") {
  height: 220px;
}
      }

      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        max-width: 527px;

        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 22px;
        }
      }

      h1 {
        font-weight: 600;
        max-width: 438px;
        margin: 0 0 11px 0;
        font-size: 30px;
        line-height: 140.5%;
        color: #ffffff;
        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}
