@import "../../../styles/mixins/global.scss";

.cargo-insurance-all-content-alignment {
  padding: 24px 0 100px 0;


  @include breakpoint("max-sm") {
    padding: 24px 0 40px 0;
  }

  .box {
    background: #ffffff;
    border-radius: 8px;
    padding: 43px 80px;

    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

    .text-style {
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 16px 0;
        text-align: center;
        color: #343434;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        padding: 0 0 20px 0;
        max-width: 683px;
        margin: 0 auto;
        color: #606060;
      }
    }

    .type-content-alignment {
      h4 {
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 56px 0;
        text-align: center;
        line-height: 26px;
        color: #343434;
      }

      .box-center {
        width: 868px;
        margin: 0 auto;

        @include breakpoint("max-sm") {
          width: 100%;
        }

        .three-col-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 60px 95px;
          padding: 0 0 96px 0;


          @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
          }

          .icon-center {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 4px 0;
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            color: #606060;
          }
        }
      }
    }

    .sub-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 34px;
      padding: 0 0 70px 0;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      .sub-grid-items {
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.10);
        padding: 24px;

        .icon {
          height: calc(30px + 24px);
        }

        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 12px 0;
          color: #343434;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
          display: block;
        }
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 364px;
      gap: 75px;
      padding: 0 0 50px 0;


      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
      }

      .button-text-alignment {
        display: flex;
        padding: 30px 0 0 0;
        align-items: center;
        gap: 11px;

        a {
          display: block;
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 130.5%;
          color: #00aeef;
        }

        button {
          background: #00aeef;
          border-radius: 7px;
          padding: 6px 18px;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
          cursor: pointer;
        }
      }

      .grid-items {
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 14px 0;
          color: #343434;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
          max-width: 602px;
          display: block;
        }

        .blur-container {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}