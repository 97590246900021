@import '../../styles/mixins/global.scss';

.uld-container-types-section {
    background: #FFF;

    .uld-container-types-alignment {
        padding: 60px 0;

        @include breakpoint("max-sm") {
            padding: 30px 0;
        }

        .container-heading-alignment {
            text-align: center;

            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                opacity: 0.8;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }

        .uld-container-types-body-alignment {
            padding: 42px 0 0 0;

            @include breakpoint("max-sm") {
                padding: 30px 0 0 0;
            }

            .uld-container-types-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 42px 21px;


                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 20px;
                }

                .uld-container-types-grid-item {
                    .uld-container-types-box {
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.20);
                        background: #FFF;
                        box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.30);
                        padding: 18px 20px;

                        .uld-container-types-box-heading {
                            text-align: center;

                            h4 {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }

                        .uld-container-types-type-img {
                            margin: 40px 0;
                            width: 100%;
                            height: 156px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .uld-container-types-learn-more-alignment {
                            a {
                                color: #00AEEF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}