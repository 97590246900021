@import "../../../styles/mixins/global.scss";

.advertising-section-all-contnet-alignment {
  padding: 50px 0 100px 0;
  background-color: #fff;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
    }

    .grid-items {
      background: #ffffff;
      box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 28px 15px;

      .icon-grid {
        display: grid;
        grid-template-columns: 127px 1fr;
        gap: 20px;
        align-items: center;
        .icon-grid-items {
          p {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #343434;
            margin: 0 0 10px 0;
            @include breakpoint("max-sm") {
              font-size: 16px;
              line-height: 24px;
            }
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #606060;
            display: block;
          }
        }
      }
    }
  }

  .text {
    padding: 0 0 40px 0;
    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin: 0 0 10px 0;
      color: #343434;
      @include breakpoint("max-sm") {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #606060;
      max-width: 918px;
      margin: 0 auto;
    }
  }
}
