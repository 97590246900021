@import "../../../styles/mixins/global.scss";

.lsq-form-section {
    padding: 56px 0 0 0;
    @include breakpoint("max-sm") {
        padding: 40px 0 0 0;
    }
    .lsq-form-alignment {
        .lsq-form-row {
            padding: 0 0 25px 0;
            @include breakpoint("max-sm") {
                padding: 0 0 20px 0;
            }
            label {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                display: block;
                padding: 0 0 9px 0;
            }

            p {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                opacity: 0.6;
                padding: 0 0 6px 0;
            }

            .input-three-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 74px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 20px;
                }
            }

            .input-two-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 46px 100px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1 , 1fr);
                    gap: 20px;
                }
            }


            .lsq-input-alignemnt {
                width: 100%;
                height: 49px;
                border: 1px solid #000;
                position: relative;

                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background: transparent;
                    color: #838383;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 0 20px;
                }


                .drop-down-arrow-alignment {
                    position: absolute;
                    top: 12px;
                    right: 20px;

                    svg {
                        cursor: pointer;
                    }
                }
            }

            .lsq-textarea-alignemnt {
                width: 100%;
                height: 151px;
                border: 1px solid #000;
                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background: transparent;
                    color: #838383;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 12px;
                }
            }


        }

        .lsq-upload-img-details {
            .lsq-upload-box {
                border: 1px solid #FFF;
                background: #FFF;
                box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
                width: 78px;
                height: 78px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;

                input[type="file"] {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                    text-indent: 999px;
                }
            }

            .lsq-file-upload-notes {
                padding: 26px 0;

                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    padding: 0 0 8px 0;
                    &:last-child {
                        padding: 0;
                    }
                }
            }
        }
    }
}