@import "../../styles/mixins/global.scss";

.individual-section {
    background: #D3EAFD;

    .individual-details-bg-alignment {
        background: url("../../assets/Image/individual-bg.png");
        width: 100%;
        height: 530px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        top: -36px;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint("max-sm") {
            height: 320px;
        }

        h4 {
            color: #434242;
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 800;
            line-height: 54px;
            letter-spacing: 4.05px;
        
            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .individual-details-form-alignment {
        padding: 70px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 0;
        }

        .individual-first-grid {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 51px;
            padding: 0 0 53px 0;
        
            @include breakpoint("max-sm"){
                grid-template-columns: repeat(1 , 1fr);
                padding: 0 0 40px 0;
            }
        }

        .individual-second-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 51px;
            padding: 0 0 53px 0;


            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 40px;
                padding: 0 0 40px 0;
            }
        }

        .input-alignment {
            input {
                width: 100%;
                height: 50px;
                border-radius: 15px;
                border: 1.5px solid rgba(0, 0, 0, 0.80);
                background: transparent;
                padding: 0 20px;
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;

                &::placeholder {
                    color: var(--theme-heading-text-color);

                }
            }
        }

        .individual-address-alignment {


            textarea {
                width: 100%;
                height: 152px;
                border-radius: 15px;
                border: 1.5px solid rgba(0, 0, 0, 0.80);
                background: transparent;
                padding: 20px;
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
            }
        }

        .individual-submit-button {
            padding: 113px 0 77px 0;
            text-align: center;

            @include breakpoint("max-sm") {
                padding: 40px 0;
            }

            button {
                padding: 18px 78px;
                border-radius: 15px;
                background: #2478F4;
                color: #D3EAFD;
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 34px;
                cursor: pointer;
                border: none;

                @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 22px;
                    padding: 12px 24px;
                }
            }
        }
    }
}