@import "../../../styles/mixins/global.scss";


.logistic-service-banner {
  background-image: url("../../../assets/imges/logistic-banner-min.png");
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  background-size: cover;

  .w-full {
    width: 100%;
  }

  .text-style {
    padding: 80px 0;

    h1 {
      max-width: 580px;
      font-weight: 600;
      margin: 0 0 10px 0;
      font-size: 30px;
      line-height: 70px;
      color: #ffffff;

      @include breakpoint("max-sm") {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin: 0 0 50px 0;
    }

    button {
      border: none;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 130.5%;
      background: #ffffff;
      border-radius: 7px;
      color: #343434;
      padding: 10px 50px;
    }
  }
}
