@import "../../../styles/mixins/global.scss";

.analysis-section-content-alignment {
  background: linear-gradient(100.84deg, #018dc1 9.55%, #76d4f7 89.68%);
  padding: 50px 0;

  h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 140.5%;
    text-align: center;
    color: #ffffff;
    margin: 0 0 30px 0;
    @include breakpoint("max-sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    .card-grid-items {
      background: #ffffff;
      border-radius: 8px;
      padding: 20px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 140.5%;
        color: #606060;

        display: block;
      }

      p {
        position: relative;
        font-weight: 600;
        font-size: 20px;
        line-height: 140.5%;
        color: #606060;
        margin: 0 0 35px 0;

        &::before {
          position: absolute;
          bottom: -15px;
          background-color: #00aeef;
          width: 96px;
          height: 3px;
          left: 0;
          content: "";
        }
      }
    }
  }
}
