.orderTicketMain-section {
    background: #FFFFFF;
    padding: 24px;
    height: calc(100vh - 255px);

    .orderDelivery-section {

        .header-alignment {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #343434;
            }

            button {
                padding: 7px 25px;
                background: #00AEEF;
                border-radius: 57px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                border: none;
                color: #FFFFFF;
                cursor: pointer;
            }
        }

        .orderDelivery-main-box {
            margin: 30px 0;
            display: flex;
            gap: 17px;
            flex-wrap: wrap;

            .box-alignment {
                background: rgba(0, 174, 239, 0.13);
                border-radius: 5px;
                width: 192px;
                max-width: 192px;
                height: 91px;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #606060;
                    
                }
            }
        }
    }

    .issue-section {
        .heading-alignment {
            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #343434;
            }
        }

        .issue-details-section {
            margin: 30px 0 0 0;

            .issue-details-alignment {
                display: flex;
                flex-direction: column;
                gap: 24px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #606060;
                    cursor: pointer;

                    &.active {
                        color: #00AEEF;
                    }
                    :hover{
                        color: #00AEEF;
                    }
                }
              
            }
        }
    }

    .issue-related-section {
        .heading-alignment {
            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #343434;
            }
        }

        .notes-alignment {
            margin: 16px 0 0 0;

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #606060;
            }
        }

        .button-alignment {
            margin: 40px 0 0 0;
            display: flex;
            gap: 15px;

            button {
                width: 188px;
                max-width: 188px;
                height: 30px;
                background: #00AEEF;
                border-radius: 57px;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
                border: none;
                cursor: pointer;
            }
        }
    }

    .issue-related-form-section {
        .heading-alignment {
            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #343434;
            }
        }

        .notes-alignment {
            margin: 16px 0 0 0;

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #606060;
            }
        }

        .issue-form-section {
            margin: 26px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .form-grid {
                display: grid;
                grid-template-columns: 107px 506px;
                gap: 7px;

                .label-alignment {
                    text-align: end;
                    margin: 5px 0 0 0;

                    label {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #606060;

                        span {
                            color: #FF2828;
                        }
                    }
                }

                .input-alignment {
                    input {
                        width: 100%;
                        height: 28px;
                        padding: 0 8px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #343434;
                        background: #FFFFFF;
                        border: 1px solid #BABABA;

                        &::placeholder {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #BABABA;
                        }

                        &:focus-visible {
                            outline: none;
                        }
                    }

                    textarea {
                        height: 136px;
                        background: #FFFFFF;
                        border: 1px solid #BABABA;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #343434;
                        width: 100%;
                        padding: 11px 8px;

                        &::placeholder {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #BABABA;
                        }

                        &:focus-visible {
                            outline: none;
                        }
                    }
                }

                .attechment-img-section {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .upload-img-box {
                        background: rgba(255, 255, 255, 0.8);
                        border: 1px dashed #BABABA;
                        width: 67px;
                        height: 65px;
                        padding: 0 10px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                           font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 12px;
                            text-align: center;
                            text-transform: capitalize;
                            color: #BABABA;
                        }

                        input {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }

                    .uploaded-img-alignment {
                        position: relative;

                        img {
                            width: 87px;
                            height: 65px;
                        }

                        .close-icon-alignment {
                            background: rgba(0, 0, 0, 0.54);
                            padding: 2px;
                            border-radius: 50%;
                            width: 12px;
                            height: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            position: absolute;
                            top: -6px;
                            right: -8px;

                            img {
                                width: 8px;
                                height: 8px;
                                display: block;
                            }
                        }
                    }
                }
            }

        }

        .button-alignment {
            display: flex;
            align-items: center;
            gap: 19px;
            margin: 40px 0 0 0;
            button {
                width: 134px;
                height: 30px;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                border-radius: 57px;
                border: none;
                cursor: pointer;
                &:first-child {
                    background: #00AEEF;
                    color: #FFFFFF;
                }

                &:last-child {
                    background: #FFFFFF;
                    border: 1px solid #00AEEF;
                    color: #00AEEF;
                }
            }
        }
    }
}