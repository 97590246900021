@import "../../styles/mixins/global.scss";

.header-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
  position: relative;

  a {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
    padding: 19px 0;
  }

  .logo {
    img {
      @include breakpoint("max-sm") {
        max-width: 120px;
      }
    }
  }

  .menu-hide {
    width: 0%;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .menu-show-design {
    width: 70%;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .meni-data-show-align {
    overflow: unset !important;
  }

  .menu-show {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    display: flex;
    align-items: center;
    // gap: 40px;
    gap: 28px;

    @include breakpoint("max-lg") {
      display: none !important;
    }
  }

  .logo {
    img {
      cursor: pointer;
    }
  }

  .menu {
    @include breakpoint("max-lg") {
      display: none;
    }

    img {
      cursor: pointer;
      max-width: 32px;
    }
  }

  .mobile-view-menu {
    display: none;

    @include breakpoint("max-lg") {
      display: block;
      position: relative;
    }

    img {
      @include breakpoint("max-lg") {
        max-width: 30px;
      }
    }


    img {
      cursor: pointer;
      max-width: 32px;
    }

  }

}

.hero-section-all-contnet-alignment {
  padding: 60px 0;
  overflow: hidden;

  .grid {
    display: grid;
    grid-template-columns: 674px 1fr;
    gap: 40px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px;
    }

    .grid-items:nth-child(2) {
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .text {
      .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 25px;
        padding: 25px 0 0 0;

        @include breakpoint("max-sm") {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
        }

        button {
          background-color: transparent;
          min-width: 190px;
          cursor: pointer;
          font-family: var(--primary-font);
          color: #fff;
          font-size: 16px;
          border-radius: 10px;
          padding: 12px;
          border: 2px solid #00aeef;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          @include breakpoint("max-sm") {
            min-width: auto;
            font-size: 16px;
            width: 100%;
          }
        }
      }

      span {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin: 0 0 25px 0;
        line-height: normal;
        display: block;
      }

      h2 {
        color: #fff;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include breakpoint("max-sm") {
          font-size: 30px;
          line-height: 41px;
          margin: 0 0 40px 0;
        }

        a {
          color: #00aeef;
          font-weight: 800;
        }
      }
    }
  }
}

.min-heihgt-home-page {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;

  @include breakpoint("max-sm") {
    height: 100%;
    position: static;
  }
}

.main-video {
  position: relative;
  min-height: 100vh;

  @include breakpoint("max-sm") {
    background: url("../../assets/Image/dot.png"), #000;
    background-repeat: no-repeat;
    background-size: cover;
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @include breakpoint("max-sm") {
      display: none;
    }
  }
}

.video-after-close {
  background: url("../../assets/Image/star-bg.png"), #000;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  position: relative;
}

.new-home-background-black-color {
  background-color: var(--theme-heading-text-color);
  background-color: var(--theme-heading-text-color);
  min-height: 100vh;
  margin-top: -10px;

  .dot-background {
    background: url("../../assets/Image/star-bg.png"), #000;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
}

.black-menu-content-bg-layer {
  position: fixed;
  top: 113px;
  height: calc(100vh - 68px);
  left: 0;
  width: 100%;
  // background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  backdrop-filter: blur(2px);

  .menu-container-dropdown {
    // position: fixed;
    // top: 68px;
    // left: 0;
    width: 100%;
    height: auto;
    // z-index: 9999;

    .menu-container-content {
      border-radius: 8px;
      background: #000;
      height: 100%;
      min-height: 350px;
      transition: 0.4s linear;

      .home-menu-content-alignment {
        height: 100%;
        min-height: 350px;
        padding: 0 120px;

        &.transpot-content {
          padding: 0 200px;
        }

        .home-menu-left-part-alignment {
          height: 100%;
          width: 100%;
          background: #abe3f9;
          border-radius: 8px 0 0 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          padding: 20px;

          .home-menu-heading {
            text-align: center;

            h5 {
              @include bold20;
            }
          }

          .dome-menu-description {
            p {
              @include medium15;
              color: #4b4b4b;
              text-align: justify;
            }
          }
        }

        .home-menu-grid-alignment {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;

          &.transpot-grid {
            display: grid;
            grid-template-columns: 1fr 500px 1fr 1fr;
          }

          &.inspection-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }

          &.four-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
          }

          .home-menu-details-alignment {
            padding: 40px 20px;

            &.transpot-details {
              padding: 40px 0;
            }

            h6 {
              @include medium18;
              color: #fff;
              padding: 0 0 28px 0;
              text-transform: uppercase;
              font-family: 'Poppins', sans-serif;
            }

            .home-child-name-alignment {
              a {
                text-decoration: none;
              }

              p {
                @include normal17;
                color: #fff;
                padding: 0 0 18px 0;
                text-transform: uppercase;
                cursor: pointer;
                transition: 0.4s ease-in-out;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                line-height: 18px;

                &:hover {
                  color: #00aeef;
                  transition: 0.4s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}

.child-grid-alignment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 0 0 0;
}

.video-hide-close {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.animation-time {
  transition: 0.3s ease-in-out;
}

.gif-close {
  opacity: 0;
  transition: 0.3s ease-in-out;

  @include breakpoint("max-sm") {
    opacity: 1;
  }
}

.gif-open {
  opacity: 1;
  transition: 0.3s ease-in-out;
}



.mobile-view-sidebar-alignment {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  border-radius: 0;
  background: #1c1a1a;
  z-index: 99999999;
  display: none;
  overflow-y: auto;
  transition: 0.4s ease-in-out;

  @include breakpoint(max-lg) {
    display: block;
  }


  &.show-sidebar {
    max-width: 100%;
    transition: 0.4s ease-in-out;
    transform: translateX(0%);
  }

  &.hidden-sidebar {
    transform: translateX(100%);
    transition: 0.4s ease-in-out;
  }

  .mobile-view-header-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
    border-bottom: 1.5px solid #9e9393ab;

    .mobile-view-logo-alignment {
      max-width: 120px;

      img {
        width: 100%;
      }
    }

    .mobile-view-close-icon {
      i {
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .mobile-view-sidebar-details-alignment {
    padding: 20px;

    ul {
      list-style-type: none;
      padding: 16px 0;

      li {
        padding: 4px;
        margin: 0 0 16px 0;

        .mobile-view-menu-name-alignment {

          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          font-weight: 400;

          .mobile-view-icon-alignment {
            i {
              font-size: 14px;
            }
          }
        }

        .mobile-menu-child-option-alignment {
          padding: 8px 0 0 0;

          &.hidden-option {
            max-height: 0px;
            transition: 0.12s ease-in-out;
            overflow-y: hidden;
          }

          &.show-option {
            max-height: 800vh;
            overflow-y: auto;
            transition: 2s ease-in-out;
          }

          .mobile-menu-option-details-alignment {
            padding: 10px 20px 0 20px;

            h6 {
              @include medium18;
              color: #fff;
              text-transform: uppercase;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              line-height: 20px;
            }

            .mobile-view-home-child-name-alignment {
              padding: 12px;

              a {
                
                text-decoration: none !important;
                &:last-child {
                  p {
                    padding: 0;
                  }
                }

                p {
                  color: #fff;
                  padding: 0 0 10px 0;
                  text-transform: uppercase;
                  cursor: pointer;
                  transition: 0.4s ease-in-out;
                  font-weight: 400;
                  font-family: 'Poppins', sans-serif;
                  font-size: 12px;
                  line-height: 14px;
                  white-space: break-spaces;

                  &:hover {
                    color: #00aeef;
                    transition: 0.4s ease-in-out;
                  }
                }
              }

            }
          }
        }
      }
    }

  }
}