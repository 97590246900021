@import "../../styles/mixins/global.scss";

.suppler-collaborate-section {
    background: #FFF;
    padding: 32px 0;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        height: 506px;
        width: 1063px;
        border-radius: 253px;
        opacity: 0.5;
        background: linear-gradient(95deg, rgba(253, 193, 197, 0) 8.33%, #FFDDDF 74.97%);
        top: -110px;
        right: 0;
        z-index: 99;
        transform: rotate(-60deg);
    }

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    .suppler-collaborate-main-alignment {


        .suppler-collaborate-grid {
            display: grid;
            grid-template-columns: 1fr 456px;
            gap: 112px;
            align-items: center;

            @include breakpoint("max-md") {
                grid-template-columns:  1fr;
                gap: 40px;
            }
            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .suppler-collaborate-grid-item {
                h4 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 48px;
                    font-weight: 700;
                    line-height: 58px;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    p {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 48px;
                        font-weight: 700;
                        line-height: 58px;

                        @include breakpoint("max-sm") {
                            font-size: 20px;
                            line-height: 30px;
                        }

                        span {
                            &.a-text {
                                color: #00A551;
                            }

                            &.a-2-text {
                                color: #F48120;
                            }

                            &.z-text {
                                color: #EC1C24;
                            }

                            &.i-text {
                                color: #0082C9;
                            }

                            &.ko-text {
                                color: #00ADEE;
                            }

                        }
                    }
                }

                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    padding: 16px 0 0 0;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                .suppler-collaborate-img {
                    width: 100%;
                    height: 456px;

                    @include breakpoint("max-sm") {
                        height: 320px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}