@import '../../../styles/mixins/global.scss';

  .fill-button {
        @include flex-center;
        justify-content: center;
        padding: 0 0 0 0;
    }

.work-seller-all-content-alignment {

  
    padding: 80px 0;

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    .grid:last-child {
        padding: 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
        align-items: center;
        padding: 0 0 80px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 60px 0;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .grid-items {
            p {
                @include heading-3;
                font-weight: 600;
                @include text-center;
                color: var(--theme-text-black-100);
            }

            .video-text-style {
                iframe {
                    width: 100%;
                    height: 350px;

                    @include breakpoint("max-sm") {
                        height: 260px;
                    }
                }
            }
        }
    }
}

.sub-text-style-all-content-alignment {
    padding: 0 0 80px 0;

    // .fill-button {
    //     @include flex-center;
    //     justify-content: center;
    //     padding: 20px 0 0 0;
    // }

    .text-style {
        padding: 0 0 20px 0;

        ul {
            padding: 20px 0 0 20px;
            margin: 0;

            li {
                @include medium16;
                color: var(--theme-text-gray-500);
                padding: 0 0 13px 0;
            }

            li:last-child {
                padding: 0;
            }
        }


        p {
            @include bold20;
            color: var(--theme-text-black-300);
            margin: 0 0 10px 0;
        }

        span {
            display: block;
            @include medium16;
            color: var(--theme-text-gray-500);
        }
    }
}