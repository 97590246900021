@import "../../styles/mixins/global.scss";

.inspection-section {
    background: #FFF;
    padding: 60px 0 0 0;

    .inspection-alignment {
        .inspection-banner-alignment {
            display: grid;
            grid-template-columns: 1fr 526px;
            gap: 62px;
            align-items: center;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .inspection-left-side-details-alignment {
                h6 {
                    color: #042953;
                    font-size: 70px;
                    font-weight: 600;
                    line-height: 85px;
                    max-width: 387px;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    padding: 28px 0 0 0;

                    opacity: 0.8;
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    line-height: normal;
                    color: var(--theme-heading-text-color);
                    font-weight: 500;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    a {
                        font-weight: 700;
                        color: #00aeef;
                    }
                }
            }
        }
    }
    
            .inspection-virtual-section {
                padding: 152px 0 0 0;
                text-align: center;
    
                @include breakpoint("max-sm") {
                    padding: 40px 0 0 0;
                }
    
                h4 {
                    color: #0D1D2D;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 36px;
    
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
    
                p {
                    padding: 32px 0 0 0;
                    max-width: 692px;
                    margin: 0 auto;
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
    
                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
    
                .inspection-virtual-option-alignment {
                    padding: 48px 0;
                    background: #EBFAFF;
                    margin: 16px 0 0 0;
    
                    .option-grid-alignment {
                        display: grid;
                        grid-template-columns: 289px repeat(2, 1fr);
                        gap: 50px;
    
                        @include breakpoint("max-sm") {
                            grid-template-columns: repeat(1, 1fr);
                            gap: 40px;
                        }
    
                        p {
                            padding: 22px 0 0 0;
                            color: var(--theme-heading-text-color);
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
    
                            @include breakpoint("max-sm") {
                                font-size: 18px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
    
            .inspection-path-section {
                padding: 122px 0 0 0;
    
                @include breakpoint("max-sm") {
                    padding: 40px 0 0 0;
                }
    
                h4 {
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
    
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
    
                .inspection-path-steps-alignment {
                    padding: 50px 0 0 0;
    
                    .inspection-path-steps-details-alignment {
                        padding: 0 0 86px 0;
    
                        @include breakpoint("max-sm") {
                            padding: 0 0 40px 0;
                        }
    
                        &.second-step {
                            .inspection-path-number {
                                flex-direction: row-reverse;
    
                                @include breakpoint("max-sm") {
                                    flex-direction: row;
                                }
                            }
    
    
    
                            .inspection-all-details-alignment {
                                .inspection-all-details-grid {
                                    grid-template-columns: 1fr 500px;
    
                                    @include breakpoint("max-sm") {
                                        grid-template-columns: repeat(1, 1fr);
                                    }
    
                                    .inspection-all-details-grid-item {
                                        &:first-child {
                                            @include breakpoint("max-sm") {
                                                order: 2;
                                            }
                                        }
    
                                        &:last-child {
                                            @include breakpoint("max-sm") {
                                                order: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        &:last-child {
                            padding: 0;
                        }
    
                        .inspection-path-number {
                            display: flex;
                            align-items: center;
    
                            @include breakpoint("max-sm") {
    
                                gap: 24px;
    
                                img {
                                    width: 80px;
                                }
                            }
    
                            h6 {
                                color: var(--theme-heading-text-color);
                                text-align: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 38px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                opacity: 0.8;
    
                                @include breakpoint("max-sm") {
                                    font-size: 18px;
                                    text-align: left;
                                }
    
                            }
    
                            .inspection-left-side-details-alignment {
                                h6 {
                                    color: var(--theme-heading-text-color);
                                    text-align: center;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 38px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    opacity: 0.8;
    
                                    @include breakpoint("max-sm") {
                                        font-size: 24px;
                                        text-align: left;
                                    }
    
                                }
    
                                p {
                                    padding: 28px 0 0 0;
    
                                    opacity: 0.8;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 30px;
                                    font-style: normal;
                                    line-height: normal;
                                    color: var(--theme-heading-text-color);
                                    font-weight: 500;
    
                                    @include breakpoint("max-sm") {
                                        font-size: 16px;
                                        padding: 12px 0 0 0;
                                    }
    
                                    a {
                                        font-weight: 700;
                                        color: #00AEEF;
    
                                    }
                                }
                            }
                        }
    
                        .inspection-virtual-section {
                            padding: 122px 0 0 0;
                            text-align: center;
    
                            @include breakpoint("max-sm") {
                                padding: 40px 0 0 0;
                            }
    
                            h4 {
                                color: #0D1D2D;
                                font-family: 'Poppins', sans-serif;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
    
                                @include breakpoint("max-sm") {
                                    font-size: 20px;
                                    line-height: 30px;
                                }
    
                            }
    
                            p {
                                padding: 32px 0 0 0;
                                max-width: 692px;
                                margin: 0 auto;
                                color: var(--theme-heading-text-color);
                                text-align: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
    
                                @include breakpoint("max-sm") {
                                    font-size: 16px;
                                    padding: 8px 0 0 0;
                                }
                            }
    
                            .inspection-virtual-option-alignment {
                                padding: 78px 0 0 0;
    
                                @include breakpoint("max-sm") {
                                    padding: 32px 0 0 0;
                                }
    
                                .option-grid-alignment {
                                    display: grid;
                                    grid-template-columns: 289px repeat(2, 1fr);
                                    gap: 50px;
    
                                    @include breakpoint("max-sm") {
                                        grid-template-columns: 1fr;
                                        gap: 24px;
                                    }
    
                                    p {
                                        padding: 22px 0 0 0;
                                        color: var(--theme-heading-text-color);
                                        text-align: center;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
    
                                        @include breakpoint("max-sm") {
                                            font-size: 18px;
                                            padding: 12px 0 0 0;
                                        }
    
                                    }
                                }
                            }
                        }
    
    
                        .inspection-path-section {
                            padding: 122px 0 0 0;
    
                            @include breakpoint("max-sm") {
                                padding: 60px 0 0 0;
                            }
    
                            h4 {
                                color: var(--theme-heading-text-color);
                                text-align: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
    
                                @include breakpoint("max-sm") {
                                    font-size: 24px;
                                }
                            }
    
                            .inspection-path-steps-alignment {
                                padding: 50px 0 0 0;
    
                                @include breakpoint("max-sm") {
                                    padding: 30px 0 0 0;
                                }
    
                                .inspection-path-steps-details-alignment {
                                    padding: 0 0 86px 0;
    
                                    &.second-step {
                                        .inspection-path-number {
                                            flex-direction: row-reverse;
                                        }
    
                                        .inspection-all-details-alignment {
                                            .inspection-all-details-grid {
                                                grid-template-columns: 1fr 500px;
                                            }
                                        }
                                    }
    
                                    &:last-child {
                                        padding: 0;
                                    }
    
                                    .inspection-path-number {
                                        display: flex;
                                        align-items: center;
    
                                        @include breakpoint("max-sm") {
                                            gap: 4px;
                                        }
    
                                        h6 {
                                            color: var(--theme-heading-text-color);
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 28px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: normal;
                                            opacity: 0.8;
                                            margin: 0 0 0 -15px;
    
                                            @include breakpoint("max-sm") {
                                                font-size: 20px;
                                                margin: 0;
                                            }
    
                                        }
                                    }
    
                                    .inspection-all-details-alignment {
                                        padding: 44px 0 0 0;
    
                                        .inspection-all-details-grid {
                                            display: grid;
                                            grid-template-columns: 500px 1fr;
                                            gap: 28px;
                                            align-items: center;
    
                                            @include breakpoint("max-sm") {
                                                grid-template-columns: 1fr;
                                            }
    
                                            .inspection-all-details-grid-item {
    
    
                                                .inspection-all-child-details-alignment {
                                                    padding: 0 0 32px 0;
    
                                                    &:last-child {
                                                        padding: 9px;
    
                                                        @include breakpoint("max-sm") {
                                                            padding: 0;
                                                        }
                                                    }
    
    
                                                    h6 {
                                                        color: var(--theme-heading-text-color);
                                                        text-align: left;
                                                        font-family: 'Poppins', sans-serif;
                                                        font-size: 30px;
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        line-height: normal;
                                                    }
    
                                                    p {
                                                        padding: 22px 0 0 0;
                                                        color: var(--theme-heading-text-color);
                                                        font-family: 'Poppins', sans-serif;
                                                        font-size: 16px;
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        line-height: normal;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        .trust-aaziko-section {
                            padding: 160px 0 66px 0;
    
                            h6 {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 28px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                opacity: 0.8;
                                margin: 0 0 0 -15px;
    
                                @include breakpoint("max-sm") {
                                    font-size: 20px;
                                    line-height: 30px;
                                }
                            }
                        }
    
                        .inspection-all-details-alignment {
                            padding: 44px 0 0 0;
    
                            @include breakpoint("max-sm") {
                                padding: 30px 0 0 0;
                            }
    
                            .inspection-all-details-grid {
                                display: grid;
                                grid-template-columns: 500px 1fr;
                                gap: 28px;
                                align-items: center;
    
                                @include breakpoint("max-sm") {
                                    grid-template-columns: repeat(1, 1fr);
                                }
    
                                .inspection-all-details-grid-item {
    
                                    @include breakpoint("max-sm") {
                                        img {
                                            max-width: 250px;
                                            margin: 0 auto;
                                            display: flex;
                                        }
                                    }
    
                                    .inspection-all-child-details-alignment {
                                        padding: 0 0 32px 0;
    
                                        &:last-child {
                                            padding: 9px;
    
                                            @include breakpoint("max-sm") {
                                                padding: 0;
                                            }
                                        }
    
                                        h6 {
                                            color: var(--theme-heading-text-color);
                                            text-align: left;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 30px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;
    
                                            @include breakpoint("max-sm") {
                                                font-size: 20px;
                                            }
                                        }
    
                                        p {
                                            padding: 22px 0 0 0;
                                            color: var(--theme-heading-text-color);
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;
    
                                            @include breakpoint("max-sm") {
                                                padding: 8px 0 0 0;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            .trust-aaziko-section {
                padding: 160px 0 66px 0;
    
                @include breakpoint("max-sm") {
                    padding: 70px 0 60px 0;
                }
    
                h6 {
                    text-align: center;
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
    
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                    }
                }
    
                p {
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    max-width: 1140px;
                    margin: 0 auto;
                    padding: 55px 0 0 0;
    
                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        padding: 14px 0 0 0;
                        max-width: 100%;
                    }
                }
    
                .trust-img-alignment {
                    padding: 32px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
}