@import "../../styles/mixins/global.scss";

.certification-all-contnet-alignment {
  padding: 50px 0;

  @include breakpoint("max-sm") {
    padding: 30px 0;
  }

  .white-box-design {
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;

    @include breakpoint("max-sm") {
      padding: 24px;
    }

    .three-col-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      .three-col-grid-items {
        .icon-center-alignment {
          display: flex;
          justify-content: center;
          padding: 0 0 22px 0;
          align-items: center;
        }

        span {
          font-size: 16px;
          line-height: 24px;
          display: block;
          color: #606060;
          font-weight: 400;
          text-align: center;



          @include breakpoint("max-sm") {
            font-size: 14px;
          }
        }
      }
    }

    .center-text-all-content-alignment {
      padding: 0 0 40px 0;

      @include breakpoint("max-sm") {
        padding: 0 0 20px 0;
      }

      p {
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        color: #606060;

        @include breakpoint("max-sm") {
          font-size: 14px;
        }
      }
    }

    .first-grid {
      display: grid;
      grid-template-columns: 1fr 522px;
      gap: 40px;



      @include breakpoint("max-md") {
        grid-template-columns: repeat(1, 1fr);

      }

      .first-grid-items {
        h1 {
          font-size: 30px;
          line-height: 42px;
          color: var(--theme-heading-text-color);
          font-weight: 600;
          margin: 0 0 20px 0;


          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130.5%;
          color: #606060;
          margin: 0 0 16px 0;


          @include breakpoint("max-sm") {
            font-size: 14px;
          }
        }

        .btn-alignment {
          display: flex;
          align-items: center;
          gap: 10px;

          a {
            display: block;
            color: #00AEEF;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
          }

          button {
            font-size: 14px;
            line-height: 16px;
            padding: 6px 18px;
            border-radius: 7px;
            background: #00aeef;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        }
      }
    }

    .certification-details-box-alignment {
      margin: 110px 0 66px 0;

      .certification-details-box-border-alignment {
        border-radius: 4px;
        border: 1px solid #BABABA;
        background: #FFF;
        width: 100%;

        .cerrification-details-grid-alignment {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          @include breakpoint("max-md") {
            grid-template-columns: 1fr;
          }

          .certification-all-details {
            &:first-child {
              padding: 45px 52px 106px 74px;
              border-right: 1px solid #BABABA;
            }

            &:last-child {
              padding: 45px 12px 40px 30px;
            }

            p {
              color: #606060;
              font-family: 'Lato';
              font-size: 18px;
              font-weight: 400;
              line-height: 182.5%;
              padding: 38px 0 0 0;
            }
          }
        }
      }
    }
  }
}