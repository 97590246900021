@import "../../../styles/mixins/global.scss";

.get-started-all-content-alignment {
  padding: 0 0 100px 0;

  @include breakpoint("max-sm") {
    padding: 0 0 30px 0;
  }

  .box {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 8px 3px rgba(28, 28, 28, 0.25);
    width: 794px;
    margin: 0 auto;
    padding: 50px;
    @include breakpoint("max-md") {
      width: 100%;
      padding: 24px;
    }
    h4 {
      color: #042953;
      font-size: 30px;
      text-align: center;
      font-style: normal;
      margin: 0 0 40px 0;
      font-weight: 600;
      line-height: normal;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .btn-center {
      display: flex;
      justify-content: center;

      button {
        border: none;
        cursor: pointer;
        font-family: var(--primary-font);
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        border-radius: 10px;
        background: #f48120;
        padding: 10px 24px;
        font-weight: 600;
        line-height: normal;

        @include breakpoint("max-sm") {
          font-size: 14px;
        }
      }
    }

    p {
      color: var(--theme-heading-text-color);
      text-align: center;
      font-size: 16px;
      margin: 0 0 40px 0;
      line-height: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include breakpoint("max-sm") {
        font-size: 14px;
      }
    }
  }
}
