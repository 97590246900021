.container {

    h4 {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 600;
        line-height: 54.86px;
        text-align: center;
        color: #042953;
        padding-top: 64px;
    }

    .TowGridAlign {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;

        .InfoBox {
            margin: 0 auto !important;


            h2 {
                font-family: Montserrat;
                font-size: 70px;
                font-weight: 600;
                line-height: 85.33px;
                text-align: left;
                color: #042953;
            }
        }

        .ImageDiv {
            img {
                width: 600px;
                height: 335px;
                top: 210px;
                left: 707px;
                gap: 0px;
                opacity: 0px;
            }
        }
    }

    .BoxTowGri {
        display: grid;
        grid-template-columns: 400px 1fr;
        background: #E1E1E14D;
        border: 2px solid #F48120;
        border-radius: 150px;
        padding: 16px 99px;
        margin-top: 64px;

        div {
            img {
                width: 250px;
                height: 239.62px;
                top: 840px;
                left: 250px;
                gap: 0px;
                opacity: 0px;
            }
        }

        .MessageInfo {
            width: 600px;
            margin: auto !important;

            h3 {
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                line-height: 39.01px;
                text-align: left;
                color: #042953;
                padding-bottom: 6px;
            }

            div {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                line-height: 21.94px;
                text-align: left;
                color: #000000;
            }
        }
    }
}




// Define breakpoint variables
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@media (max-width: $breakpoint-xs) {
    .container {

        h4 {
            font-size: 32px !important;
        }

        .TowGridAlign {
            display: block !important;

            .InfoBox {
                h2 {
                    font-size: 46px !important;
                    line-height: 50.33px !important;
                }
            }

            .ImageDiv {
                img {
                    width: 100% !important;
                }
            }
        }

        .BoxTowGri {
            display: block !important;
            padding: 36px 26px !important;

            div {
                img {
                    width: 100% !important;
                }
            }

            .MessageInfo {
                width: 100% !important;
                margin: auto !important;
                padding: 16px !important;
            }
        }
    }

}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .container {
        .TowGridAlign {
            .InfoBox {    
                h2 {
                    font-size: 46px !important;
                    line-height: 50.33px !important;
                }
            }
        }
    
        .BoxTowGri {
            display: block !important;
            padding: 26px 56px !important;
            justify-content: center !important;
            align-items: center !important;
            text-align: center !important;
    
            div {
                img {
                    width: 250px;
                    height: 239.62px;
                    top: 840px;
                    left: 250px;
                    gap: 0px;
                    opacity: 0px;
                }
            }
    
            .MessageInfo {
                width: 100% !important;
                margin: auto !important;
                h3 {
                    padding-top: 16px;
                    text-align: center !important;
                }
    
                div {
                    text-align: center !important;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .container {
        .TowGridAlign {
            .InfoBox {    
                h2 {
                    font-size: 52px !important;
                    line-height: 50.33px !important;
                }
            }
        }
    
        .BoxTowGri {
            display: block !important;
            padding: 26px 56px !important;
            justify-content: center !important;
            align-items: center !important;
            text-align: center !important;
    
            div {
                img {
                    width: 250px;
                    height: 239.62px;
                    top: 840px;
                    left: 250px;
                    gap: 0px;
                    opacity: 0px;
                }
            }
    
            .MessageInfo {
                width: 100% !important;
                margin: auto !important;
                h3 {
                    padding-top: 16px;
                    text-align: center !important;
                }
    
                div {
                    text-align: center !important;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .container {     
        .BoxTowGri {
            grid-template-columns: 300px 1fr !important;
            .MessageInfo {
                width: 100% !important;
            }
        }
    }
}