@import "../../../styles/mixins/index.scss";

.effortess-customs-section {
  padding: 100px 0 0 0;

  .effortess-customs-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    h4 {
      color: rgba(0, 0, 0, 0.8);
      font-size: 42px;
      font-weight: 600;
      line-height: 51px;
    }
  }

  .effortess-customs-body-alignment {
    margin: 50px 0 0 0;

    .effortess-customs-body-box {
      width: 100%;
      // height: 281px;
      border-radius: 30px;
      background: #fff;
      box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
      padding: 40px 16px;
      display: inline-table;

      .all-details-all-flex-aligment {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        justify-content: center;
        flex-wrap: wrap;

        .effortess-customs-input-alignment {
          label {
            // color: #505050;
            // font-size: 16px;
            // font-weight: 600;
            // line-height: 24px;
            font-size: 0.875em;
            margin: 0;
            color: #6a8099;
            letter-spacing: -0.3px;
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: 500;
            display: block;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .effortess-customs-inout-dropdown {
            padding: 10px 0 0 0;

            .effortess-customs-input-box {
              border-radius: 15px;
              background: #ededed;
              display: flex;
              align-items: center;
              justify-content: space-between;
              min-width: 173px;
              height: 57px;
              padding: 0 10px 0 21px;
              cursor: pointer;
              position: relative;
              min-width: 173px;
              width: auto;
              width: 200px;

              &.product-input {
                width: 367px;
                padding: 0 24px 0 21px;
              }

              .custom-span {
                color: #505050;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
              }

              .custom-span.disabled {
                color: #b1a4a4;
              }

              .dropdown-icon {
                cursor: pointer;
                width: 24px;
                height: 24px;
              }

              .effortess-customs-dropdown-box-alignment {
                border-radius: 0 0 15px 15px;
                background: #ededed;
                width: 160px;
                top: 56px;
                left: 0;
                right: 0;
                position: absolute;
                margin: 0 auto;
                width: 200px;

                .effortess-dropdoewn-name {
                  // padding: 12px 20px;
                  // padding: 12px 20px;
                  text-align: center;
                  margin-top: 8px;

                  p {
                    color: #505050;
                    font-size: 14px;
                    font-weight: 500;
                    // margin-bottom: 8px !important;
                    line-height: 21px;
                    white-space: wrap !important;

                    &:hover {
                      background: #d9d9d9;
                      white-space: center;
                    }
                  }
                }

                &.open-effortess-dropdown {
                  max-height: 155px;
                  overflow-y: auto;
                  transition: all 0.5s ease-in-out;
                  overflow-x: hidden;
                  // height: auto;
                  // height: 100px;;
                  z-index: 1111;
                }

                &.close-effortess-dropdown {
                  max-height: 0px;
                  overflow-y: hidden;
                  transition: all 0.5s ease-in-out;
                }
              }

              &.active {
                border: 2px solid #042953;

                .dropdown-icon {
                  border-left: 2px solid #042953;
                }

                input.search-input {
                  border-bottom: 2px solid #042953;
                  width: 200px;
                }
              }

              input.search-input {
                flex-grow: 1;
                border: none;
                outline: none;
                padding: 10px;
                font-size: 14px;
                color: #505050;
                background: transparent;

                &::placeholder {
                  color: #505050;
                }
              }
            }
          }
        }

        .search-button-alignment {
          margin-top: 30px;

          button {
            height: 57px;
            padding: 0 48px;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            border-radius: 15px;
            background: #042953;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .custome-button-alignment {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 30px;

    button {
      height: 57px;
      padding: 0 48px;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      border-radius: 15px;
      border: none;
      cursor: pointer;

      &.custom1 {
        background: #ededed;
        color: #000;
      }

      &.custom2 {
        background: #ededed;
        color: #000;
      }

      &.active {
        background: #042953;
        color: #fff;
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
  }

  .results {
    box-sizing: border-box;
  }

  .summary-content {
    // margin-left: 30px;
    margin: 50px 0 30px;
    font-size: 5px;
    // font-size: 15px;
    color: #080808;
    font-weight: 400;
    letter-spacing: -0.19px;

    .heading {
      font-size: 1.4rem;
      color: #080808;
      letter-spacing: -0.19px;
      margin: 50px 0 10px;
      font-weight: 600;
      // margin: 50px 0 30px;
    }

    .sub-heading {
      display: block;

      span {
        font-size: 15px;
        color: #080808;
        font-weight: 400;
      }
    }

    .custome-data-alignment {
      margin-top: 30px;
    }
  }

  .custome-headingdata {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  .ntm-summary {
    display: block;

    .ntm-summary-results {
      box-sizing: border-box;

      .tab-title {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        padding: 10px 15px;
        // color: #fff;
        // background: #7e96a9;
        background: #002060 !important;
        color: #fff !important;
        margin: 5px;
        // margin: 0 0 5px;
        font-size: 16px;

        .span {
          box-sizing: border-box;
        }
      }
    }

    .tab-measurestitle {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      margin: 0 0 5px;

      // color: #042953;
      .span {
        box-sizing: border-box;
      }
    }
  }
}

.container .results-panel .results table tbody tr td {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.19px;
  margin: 50px 0 30px;
  color: rgba(36, 36, 36, 0.8);
}

#plus .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e") !important;
  transform: scale(0.7) !important;
}

#plus .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e") !important;
}

.accordion-item:first-of-type .accordion-button {
  // border-top-left-radius: var(--bs-accordion-inner-border-radius);
  // border-top-right-radius: var(--bs-accordion-inner-border-radius);
  // background: #7E96A9;
  // color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  color: #fff;
  background: #7e96a9;
  margin: 0 0 5px;
  font-size: 16px;
}

.table-toggle-list .toggle-trigger.expanded .toggle {
  border-top-color: #fff;
}

// .accordion-item:last-of-type .accordion-button.collapsed {
//   // border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
//  // border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
//   background: #002060!important;
//   color: #FFF!important;
// }
.container .results-panel .ntm-summary-detail-result .inner-table tbody td {
  padding: 10px;
  background: #f9f9f9;
  border: 2px solid #fff;
  font-size: 13px;
}

.container .results-panel .results table tbody tr td {
  line-height: 22px;
  vertical-align: top;
  position: relative;
  border: 0;
  font-size: 14px;
}

.ntm-summary-results {
  .tab-title w-full {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    color: #fff;
    background: #7e96a9;
    margin: 0 0 5px;
    font-size: 16px;
  }
}

.inner-table {
  .inner-table-data {
    display: flex;
    gap: 5px;

    i {
      color: #6a8099;
    }

    .inner-table-title {
      margin-top: -5px;

      .inner-table-titleData {
        color: #002060;
        font-weight: bold;
      }

      span {
        color: #6a8099;
        font-weight: 500;
        // display: block;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      a {
        color: #6a8099;
        font-weight: 500;
        text-align: left;
        text-overflow: ellipsis;
        margin-left: 5px;
        text-decoration: none;
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }

  .inner-table-title a:hover {
    text-decoration: underline;
    color: #002060;
  }
}



.contact-with-the-expert {
  text-align: center;
  padding: 60px 0 30px 0;

  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }

  @include breakpoint("max-sm") {
    padding: 60px 0;
  }

  h6 {
    color: var(--theme-heading-text-color);
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;

    @include breakpoint("max-sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .connect-with-section-form-alignment {
    padding: 40px 0 0 0;

    max-width: 550px;
    margin: 0 auto;

    .connect-with-section-form-input {
      margin: 0 0 16px 0;

      input {
        width: 100%;
        height: 50px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 15px;
        border: 1.5px solid rgba(0, 0, 0, 0.8);
        background: transparent;
        padding: 0 20px;
        color: var(--theme-heading-text-color);
      }

      select {
        width: 100%;
        height: 50px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 15px;
        border: 1.5px solid rgba(0, 0, 0, 0.8);
        background: transparent;
        padding: 0 20px;
        color: var(--theme-heading-text-color);
      }

      textarea {
        width: 100%;
        height: 150px;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 15px;
        border: 1.5px solid rgba(0, 0, 0, 0.8);
        background: transparent;
        padding: 16px 20px;
        color: var(--theme-heading-text-color);
        resize: none;
      }
    }

    .upload-images-box-alignment {
      width: 80px;
      height: 65px;
      border: 1.5px solid rgba(0, 0, 0, 0.8);
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      p {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-radius: 15px;
        color: var(--theme-heading-text-color);
      }

      input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        text-indent: 999px;
        cursor: pointer;
      }
    }

    .two-input-grid-alignment {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .request-quote-button-alignment {
    padding: 40px 0 0 0;

    button {
      padding: 14px 24px;
      border-radius: 10px;
      border: 2px solid #00aeef;
      color: #00aeef;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      cursor: pointer;
      background: transparent;
    }
  }
}

.spanvalidationError {
  color: red;
  font-size: 13px;
  margin-right: 380px;
}

.validationspanError {
  color: red;
  font-size: 13px;
  margin-right: 100px;
}

.selectedDataSection {
  margin-top: 20px;
}

.selectedDataContainer {
  padding: 10px;
}

.selectedDataTitle {
  font-size: 18px;
  // font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  color: #6a8099;
}

.selectedDataItem {
  margin-bottom: 5px;
  display: flex;
  padding: 0 10px;
}

.selectedDataItem label {
  font-weight: bold;
  margin-right: 10px;
  color: #505050;
}

.selectedDataItem p {
  margin: 0;
}