@import '../../../styles/mixins/global.scss';

.buyers-partner-banner-all-contnet-alignment {
  background: linear-gradient(
    100.84deg,
    rgba(1, 141, 193, 0.1) 9.55%,
    rgba(118, 212, 247, 0.1) 89.68%
  );
  padding: 50px 0 0 0;

  .grid {
    display: grid;
    grid-template-columns: 1fr 560px;
    gap: 125px;
    align-items: center;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 40px;
    }
    .grid-items {
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
      button {
        border: none;
        font-weight: 400;
        font-size: 16px;
        padding: 9px 26px;
        margin: 0 0 30px 0;
        background: #00aeef;
        border-radius: 7px;
        line-height: 130.5%;
        color: #ffffff;
        cursor: pointer;
      }

      h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
        margin: 0 0 10px 0;
        color: #343434;
      }

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #606060;
        max-width: 589px;
      }
    }
  }
}
