@import "../../../../styles/mixins/global.scss";
$breakpoint-xs: 576px; //New Scss
$breakpoint-sm: 768px; //New Scss

.our-trainding-song-content {
  .our-trainding-song-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 378px;

    @include breakpoint("max-sm") {
      height: auto;
      padding: 40px 0 0 0;
    }

    h4 {
      color: var(--theme-heading-text-color);
      font-family: 'Poppins', sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 97%;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: url("../../../../assets/Image/trainding-left-img.png");
      height: 100%;
      width: 662px;

      @include breakpoint("max-sm") {
        width: 100%;
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: url("../../../../assets/Image/trainding-right-img.png");
      height: 257px;
      width: 662px;
      background-repeat: no-repeat;
      background-size: cover;

      @include breakpoint("max-sm") {
        width: 100%;
        display: none;

      }
    }
  }

  .out-trainding-song-details-alignment {
    padding: 8px 0 0 0;

    .out-trainding-song-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 68px 68px; // here change the second gap 167px
      background-color: none;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      .out-trainding-song-grid-item {
        width: 100%;
        height: 272px;

        iframe {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }


  // New scss  add here...
  // Media Queries
   $breakpoint-xs-minus-1: calc(#{$breakpoint-xs} - 1px);

  @media (max-width: $breakpoint-xs-minus-1) {
    .our-trainding-song-heading {
      height: 100px;  
      &:before {
        display: none;
      }
  
      &::after {
        display: none;
      }
    }
    .out-trainding-song-details-alignment {
      .out-trainding-song-grid {
        display: block;
        grid-template-columns: auto auto;

        .out-trainding-song-grid-item {
          margin-top: 16px;
        }
      }
    }
  }

  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .our-trainding-song-heading {
      height: 150px;  
      &:before {
        display: none;
      }
  
      &::after {
        display: none;
      }
    }
    .out-trainding-song-details-alignment {
      .out-trainding-song-grid {
        display: block;
        grid-template-columns: auto auto;
      }

      .out-trainding-song-grid-item {
        margin-top: 16px;
      }
    }
  }

}