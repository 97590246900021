@import "../../../styles/mixins/global.scss";

.store-front-details-all-content-alignment-for-page {
  padding: 30px 0;

  .grid {
    display: grid;
    grid-template-columns: 282px 1fr;
    gap: 24px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 60px;
    }

    .grid-items {
      .main-white-box {
        background: #ffffff;
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 30px;

        .spacer {
          padding: 50px 0 0 0;
          border-top: 1px solid #bababa;
        }
        .chat-button-center-alignment {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            border: none;
            cursor: pointer;
            padding: 14px 33px;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
            cursor: pointer;
            background: #00aeef;
            border-radius: 8px;
          }
        }
        .add-contact {
          padding: 20px;
          background: rgba(0, 174, 239, 0.06);
          border-radius: 8px;
          margin: 0 0 50px 0;

          p {
            font-weight: 600;
            font-size: 24px;
            line-height: 140.5%;
            margin: 0 0 10px 0;
            color: #00aeef;
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 140.5%;
            color: #606060;
            display: block;
          }
        }

        .text {
          p {
            font-weight: 600;
            font-size: 24px;
            line-height: 140.5%;
            margin: 0 0 10px 0;
            color: #343434;
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 16px;
            line-height: 140.5%;
            color: #606060;
            max-width: 512px;
          }
        }

        .image-style {
          img {
            width: 100%;
          }
        }

        .sec-grid {
          display: grid;
          grid-template-columns: 1fr 244px;
          gap: 38px;
          align-items: center;

          
          @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px;
          }

          .sec-grid-items:nth-child(1) {
            @include breakpoint("max-sm") {
              order: 2;
            }
          }
        }

        .first-grid {
          display: grid;
          grid-template-columns: 244px 1fr;
          gap: 38px;
          align-items: center;
          padding: 0 0 50px 0;

          @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px;
          }

          .first-grid-items {
          }
        }

        .main-text {
          padding: 0 0 50px 0;
          h1 {
            margin: 0 0 10px 0;
            font-weight: 600;
            font-size: 30px;
            line-height: 140.5%;
            text-align: center;
            color: #00aeef;
            @include breakpoint("max-sm") {
              font-size: 24px;
              line-height: 36px;
            }
          }

          span {
            display: block;
            margin: 0 auto;
            max-width: 834px;
            font-weight: 400;
            font-size: 16px;
            line-height: 140.5%;
            text-align: center;
            color: #606060;


            @include breakpoint("max-sm") {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
