@import '../../../styles/mixins/global.scss';

.connect-with-us-section {
    padding: 142px 0 0 0;

    h6 {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 20px;
        @include breakpoint("max-sm"){
            font-size: 25px;
        }
    }

    @include breakpoint("max-sm") {
        padding: 40px 0 0 0;
    }

    .connect-with-us-details-alignment {
        padding: 52px 0 0 0;
        display: flex;
        justify-content: space-between;

        @include breakpoint("max-sm") {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .connect-with-child-details {
            text-align: center;

            h6 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 31px;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }

                span {
                    color: #00AEEF;
                }
            }

            .connect-with-us-button {
                padding: 42px 0 0 0;

                button {
                    border-radius: 10px;
                    border: 1px solid #00AEEF;
                    padding: 14px 32px;
                    color: #00AEEF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21px;
                    background: transparent;
                    cursor: pointer;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    &.lsq-button {
                        padding: 14px 38px;
                    }
                }
            }
        }

    }
}