@import "../../../styles/mixins/global.scss";

.grow-section-design {
  background: rgba(0, 174, 239, 0.12);
  padding: 50px;

  .grid {
    display: grid;
    grid-template-columns: 414px 1fr;
    gap: 80px;
    align-items: center;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 40px;
    }
    .grid-items {
      .image {
        img {
          width: 100%;
        }
      }

      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
        margin: 0 0 20px 0;
        color: #343434;
        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        max-width: 420px;
        text-transform: capitalize;
        color: #606060;
        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .icon-text {
        display: flex;
        padding: 20px 0 0 0;
        cursor: pointer;
        align-items: center;
        gap: 4px;
        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-transform: capitalize;

          color: #00aeef;
          display: block;
        }
      }
    }
  }

  .text {
    padding: 0 0 55px 0;
    h4 {
      margin: 0 0 10px 0;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      text-transform: capitalize;
      color: #343434;
      text-align: center;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: capitalize;
      color: #606060;
      @include breakpoint("max-sm") {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
