@import "../../../styles/mixins/global.scss";


.order-management {
  background: rgba(0, 174, 239, 0.12);
  padding: 60px 0;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
      gap: 60px;
    }
    .grid-items {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      .alignment {
        padding: 26px 24px 24px 24px;

        span {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #343434;
          padding: 0 0 14px 0;
          display: block;
          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 142.5%;
          text-transform: capitalize;
          color: #606060;
        }
      }

      .icon {
        width: 93px;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #00aeef;
        border-radius: 50%;
        margin-top: -42px;
        margin-left: 24px;
      }
    }
  }

  .text {
    padding: 0 0 86px 0;
    span {
      display: block;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #00aeef;
      @include breakpoint("max-sm") {
        font-size: 14px;
        line-height: 22px;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 24px;
      margin: 0 0 12px 0;
      line-height: 125.5%;
      text-align: center;
      color: #343434;
      
      @include breakpoint("max-sm") {
        font-size: 24px;
        line-height: 32px;
    }
    }
    p {
      max-width: 455px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      text-transform: capitalize;

      color: #606060;

      @include breakpoint("max-sm") {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
