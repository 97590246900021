@import "../../../styles/mixins/global.scss";

.about-aaziko-capital-section {
    background: #B3E7FA;
    padding: 41px 0 45px 0;

    .about-aaziko-capital-alignment {
        .about-aaziko-heading-alignment {
            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                line-height: 42px;
                font-weight: 600;
                letter-spacing: 0.2px;
                text-align: center;

                @include breakpoint("max-sm"){
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }

        .about-aaziko-all-child-details-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 42px 0 0 0;

            @include breakpoint("max-sm"){
                padding: 24px 0 0 0;
                display: grid;
                grid-template-columns: 1fr;
                gap: 22px;
            }

            .about-aaziko-child-details-alignment {
                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0.2px;
                    text-align: center;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                h5 {
                    color: var(--theme-heading-text-color);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0.2px;

                    @include breakpoint("max-sm") {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    br {
                        @include breakpoint("max-sm"){
                            display: none;
                        }
                    }
                }
            }
        }
    }
}