@import "../../../styles/mixins/global.scss";

$breakpoint-xs: 576px; //New Scss
$breakpoint-sm: 768px; //New Scss

.video-gallery-content-alignment {
  .video-galley-main-alignment {
    .video-galley-main-grid {
      display: grid;
      grid-template-columns: 1fr 325px;
      gap: 78px;
      align-items: center;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
      }

      .video-galley-main-grid-item {
        h4 {
          color: var(--theme-heading-text-color);
          font-family: 'Poppins', sans-serif;
          font-size: 30px;
          font-weight: 700;
          line-height: 120%;
          opacity: 0.8;

          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          color: var(--theme-heading-text-color);
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 120%;
          opacity: 0.8;
          padding: 26px 0 0 0;
          max-width: 677px;

          @include breakpoint("max-sm") {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .video-aaziko-logo {
          width: 100%;
          height: 325px;
          border: 1px solid #00aeef;
          border-radius: 30px;

          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 30px;
          }
        }
      }
    }
  }

  // New scss  add here...
  // Media Queries
  $breakpoint-xs-minus-1: calc(#{$breakpoint-xs} - 1px);

  @media (max-width: $breakpoint-xs-minus-1) {
    .video-galley-main-alignment {
      .video-galley-main-grid {
        display: block;
        grid-template-columns: auto;
      }

      .video-aaziko-logo {
        margin-top: 32px;
      }
    }
  }

  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .video-galley-main-alignment {
      .video-galley-main-grid {
        display: block;
        grid-template-columns: auto;
      }

      .video-aaziko-logo {
        margin-top: 32px;
      }
    }
  }

}