body {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font);
  -webkit-tap-highlight-color: transparent !important;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

img{
  max-width: 100%;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #D3D3D3;
  /* border-radius: 10px; */
}