@import '../../../styles/mixins/global.scss';

.supplier-not-accepted-payment-section {
    padding: 94px 0 82px 0;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    .supplier-not-accepted-payment-alignment {
         
        text-align: center;

        h6 {
            color: var(--theme-heading-text-color);
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;
            opacity: 0.8;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .supplier-not-accepted-flex-alginment {

            display: flex;
            align-items: center;
            gap: 42px;


            @include breakpoint("max-sm") {
                display: grid;
                grid-template-columns: repeat(1 , 1fr);
                gap: 40px;
            }

            p {
                color: var(--theme-heading-text-color);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 28px;
                font-weight: 500;
                line-height: 32px;
                padding: 28px 0 0 0;
                max-width: 643px;
            
                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                    padding: 0;
                }
            }

            .supplier-not-accepted-box-alignment {

                margin: 42px auto 0 auto;
                width: 443px;
                min-width: 443px;
                max-width: 443px;
                height: 409px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}