.container {
    padding: 26px 16px !important;

    .specailInspecatinGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;

        .MessageGrid {
            width: 475px;
            height: 255px;

            h2 {
                font-family: Montserrat;
                font-size: 70px;
                font-weight: 600;
                line-height: 85.33px;
                text-align: left;
                color: #042953;
            }
        }

        .ImageGrid {
            img {
                width: 590px;
                height: 459px;
                top: 176px;
                left: 706px;
                gap: 0px;
                opacity: 0px;
            }
        }
    }

    .moredetailsGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;
        margin-top: 64px;

        .SpecailInBox {
            margin-top: 64px;
            height: 100% !important;

            div {
                width: 530px;
                height: 310px;
                text-align: center;

                .shadowbox {
                    box-shadow: 0px 4px 8px 0px #0000004D;
                    background: #FFFFFF;
                    border: none;
                    padding-top: 26px;
                    transform: skew(-8deg);
                    border: 2px solid #4AC98A;
                    border-left: none;
                    border-top: none;

                    img {
                        width: 250px;
                        height: 248.2px;
                        top: 0.01px;
                        left: 0px;
                        gap: 0px;
                        opacity: 0px;
                        transform: skew(0deg);
                    }
                }
            }

            .infodetails {
                border: none;
                margin-top: 32px;
                height: 100%;
                padding: 0px 32px;
                transform: skew(0deg);

                h2 {
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 34.13px;
                    text-align: center;
                    color: #042953;
                }

                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21.94px;
                    text-align: center;
                    color: #000000;
                    border: none;
                    height: 100%;
                    padding-top: 16px;
                }
            }
        }
    }

    .specailonegrid {
        .SpecailInBox {
            margin-top: 64px;
            height: 100% !important;

            div {
                width: 100%;
                height: 310px;
                text-align: center;

                .shadowbox {
                    box-shadow: 0px 4px 8px 0px #0000004D;
                    background: #FFFFFF;
                    border: none;
                    padding-top: 26px;
                    transform: skew(-8deg);
                    border: 2px solid #4AC98A;
                    border-left: none;
                    border-top: none;

                    img {
                        width: 250px;
                        height: 248.2px;
                        top: 0.01px;
                        left: 0px;
                        gap: 0px;
                        opacity: 0px;
                        transform: skew(0deg);
                    }
                }
            }

            .infodetails {
                border: none;
                margin-top: 32px;
                height: 100%;
                padding: 0px 32px;
                transform: skew(0deg);

                h2 {
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 34.13px;
                    text-align: center;
                    color: #042953;
                }

                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21.94px;
                    text-align: center;
                    color: #000000;
                    border: none;
                    height: 100%;
                    padding-top: 16px;
                }
            }
        }
    }

}

.backgroundbox {
    width: 100%;
    opacity: 0px;
    background: #E1F6FF;
    margin-top: 36px;

    .listinfo {
        padding: 36px 0px;
        h2 {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: 600;
            line-height: 34.13px;
            text-align: left;
            color: #042953;
        }

        ul {
            margin-top: 32px;

            li {
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 500;
                line-height: 40.38px;
                text-align: left;
            }
        }
    }
}


// Define breakpoint variables
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@media (max-width: $breakpoint-xs) {
    .container {
        padding:16px !important;
        .specailInspecatinGrid {
            display: block;
            .MessageGrid {
                width: 100%;
                height: 100%;
                h2 {
                    font-size: 46px;
                    line-height: 55.33px;
                    padding-bottom: 16px;
                }
            }
    
            .ImageGrid {
                img {
                    width: 100%;
                }
            }
        }
    
        .moredetailsGrid {
            display: block !important;
            padding: 0px 16px !important;
            .SpecailInBox {
                div {
                    width: 100% !important;
                }
                .infodetails {
                    padding: 0px 0px;
                }
            }
        }
    
        .specailonegrid {
            padding: 0px 16px !important;
        }
    }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .container {
        padding:16px !important;
        .specailInspecatinGrid {
            display: block;
            .MessageGrid {
                width: 100%;
                height: 100%;
            }
    
            .ImageGrid {
                img {
                    width: 100%;
                }
            }
        }
    
        .moredetailsGrid {
            display: block !important;
            padding: 0px 16px !important;
            .SpecailInBox {
                div {
                    width: 100% !important;
                }
                .infodetails {
                    padding: 0px 0px;
                }
            }
        }
    
        .specailonegrid {
            padding: 0px 16px !important;
        }
    }
}


@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .container {
        padding:16px !important;
        .specailInspecatinGrid {
            margin-top: 16px !important;
            .MessageGrid {
                width: 80%;
                height: 100%;
            }
    
            .ImageGrid {
                img {
                    width: 100%;
                }
            }
        }
    
        .moredetailsGrid {
            padding: 0px 16px !important;
            .SpecailInBox {
                div {
                    width: 100% !important;
                }
                .infodetails {
                    padding: 0px 0px;
                }
            }
        }
    
        .specailonegrid {
            padding: 0px 16px !important;
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .container {
        .specailInspecatinGrid {
            .MessageGrid {
                width: 400px;
            }
        }
    
        .moredetailsGrid {
            .SpecailInBox {
                div {
                    width:100%;
                    height: 310px;
                    text-align: center;
    
                    .shadowbox {
                        box-shadow: 0px 4px 8px 0px #0000004D;
                        background: #FFFFFF;
                        border: none;
                        padding-top: 26px;
                        transform: skew(-8deg);
                        border: 2px solid #4AC98A;
                        border-left: none;
                        border-top: none;
    
                        img {
                            width: 250px;
                            height: 248.2px;
                            top: 0.01px;
                            left: 0px;
                            gap: 0px;
                            opacity: 0px;
                            transform: skew(0deg);
                        }
                    }
                }
    
                .infodetails {
                    border: none;
                    margin-top: 32px;
                    height: 100%;
                    padding: 0px 32px;
                    transform: skew(0deg);
    
                    h2 {
                        font-family: Montserrat;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 34.13px;
                        text-align: center;
                        color: #042953;
                    }
    
                    .message {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21.94px;
                        text-align: center;
                        color: #000000;
                        border: none;
                        height: 100%;
                        padding-top: 16px;
                    }
                }
            }
        }
    
        .specailonegrid {
            .SpecailInBox {
                margin-top: 64px;
                height: 100% !important;
    
                div {
                    width: 100%;
                    height: 310px;
                    text-align: center;
    
                    .shadowbox {
                        box-shadow: 0px 4px 8px 0px #0000004D;
                        background: #FFFFFF;
                        border: none;
                        padding-top: 26px;
                        transform: skew(-8deg);
                        border: 2px solid #4AC98A;
                        border-left: none;
                        border-top: none;
    
                        img {
                            width: 250px;
                            height: 248.2px;
                            top: 0.01px;
                            left: 0px;
                            gap: 0px;
                            opacity: 0px;
                            transform: skew(0deg);
                        }
                    }
                }
    
                .infodetails {
                    border: none;
                    margin-top: 32px;
                    height: 100%;
                    padding: 0px 32px;
                    transform: skew(0deg);
    
                    h2 {
                        font-family: Montserrat;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 34.13px;
                        text-align: center;
                        color: #042953;
                    }
    
                    .message {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21.94px;
                        text-align: center;
                        color: #000000;
                        border: none;
                        height: 100%;
                        padding-top: 16px;
                    }
                }
            }
        }
    
    }
}