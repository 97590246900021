@import '../../../styles/mixins/global.scss';

.services-provide-section {
    padding: 50px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 30px 0 0 0;
    }

    .services-provide-box-alignment {
        max-width: 792px;
        height: 400px;
        margin: 0 auto;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .services-provide-all-details-alignment {
        padding: 24px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 16px 0 0 0;
        }

        h6 {
            color: #343434;
            text-align: center;
           font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;

            @include breakpoint("max-sm") {
                font-size: 16px;
                line-height: 24px;
            }
        }

        p {
            padding: 14px 0 0 0;
            color: #343434;
            text-align: center;
           font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            max-width: 792px;
            opacity: 0.5;
            margin: 0 auto;

            @include breakpoint("max-sm") {
                font-size: 14px;
                line-height: 20px;
                max-width: 100%;

            }
        }
    }
}