@import "../../../styles/mixins/global.scss";

.aboutus-design-banner {
  background-image: url("../../../assets/imges/aboutus-min.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 495px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
    margin: 0 0 23px 0;
    color: #ffffff;
  }

  .w-full {
    width: 100%;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #d6d6d6;
    max-width: 570px;
    @include breakpoint("max-sm") {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
