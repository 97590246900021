@import "../../styles/mixins/index.scss";

.liner-terms-section {
    padding: 60px 0;

    @include breakpoint("max-sm"){
        padding: 30px  0 0 0;
    }
    .liner-terms-alignment {
        .liner-terms-heading {
            h4 {
                color: #000;
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }

        .liner-terms-body-alignment {
            padding: 32px 0 0 0;

            .liner-terms-box-alignment {
                border: 1px solid #d0d0d0;
                background: #F6FDFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 18px 26px;

                .liner-terms-img-alignment {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                    }
                }

                .liner-terms-all-details-alignment {
                    padding: 52px 0 0 0;

                    h6 {
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        opacity: 0.8;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;


                        @include breakpoint("max-sm") {
                            font-size: 18px;
                            line-height: 30px;
                        }

                        &.second-heading {
                            color: #000;
                            font-family: 'Poppins', sans-serif;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            opacity: 0.8;
                            text-align: center;
                            padding: 8px 0 0 0;
                        }
                    }

                    .liner-terms-all-details-grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;
                        padding: 42px 0 0 0;


                        @include breakpoint("max-sm") {
                            padding: 20px 0 0 0;
                            grid-template-columns: repeat(1 , 1fr);
                        }

                        .liner-terms-all-details-grid-item {
                            .liner-terms-child-details-alignment {
                                padding: 0 0 32px 0;

                                h5 {
                                    color: #00AEEF;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 26px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    opacity: 0.8;
                                    padding: 0 0 16px 0;

                                    @include breakpoint("max-sm") {
                                        font-size: 18px;
                                        line-height: 28px;
                                    }
                                }

                                p {
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    opacity: 0.8;

                                    @include breakpoint("max-sm") {
                                        font-size: 14px;
                                        line-height: 23px;
                                    }
                                }
                            }
                        }
                    }

                    .notes-details-alignment {
                        padding: 56px 0 0 0;
                        text-align: center;

                        h6 {
                            color: #00AEEF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            opacity: 0.8;
                            padding: 0 0 12px 0;
                        }

                        p {
                            color: #000;
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}