.container {
    padding: 26px 56px;

    .TowGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;

        .MessageGrid {
            width: 500px;
            height: 156px;
            margin: 0 auto !important;

            h2 {
                font-family: Montserrat;
                font-size: 64px;
                font-weight: 600;
                text-align: left;
                color: #042953;
            }

            .InfoMessageText {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
                color: #000000;
            }
        }

        .ImageGrid {
            img {
                width: 561px;
                height: 583px;
                top: 147px;
                left: 739px;
                gap: 0px;
                opacity: 0px;
            }
        }
    }

    .ThreeGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center !important;
        gap: 16px;

        .GridBox {
            width: 320px;
            height: 502px;
            padding: 0;
            gap: 0px;
            border-radius: 170.5px 170.5px 160px 0px;
            border: 3px 0px 0px 0px;
            opacity: 0px;
            box-shadow: none !important;
            background-color: #fff;
            border: 3px solid #EC1C24;
            margin-top: 16px !important;

            .ImageBoxShadow {
                width: 320px;
                height: 256px;
                top: 880px;
                left: 560px;
                gap: 0px;
                border-radius: 170.5px 170.5px 99px 0px;
                opacity: 0px;
                box-shadow: 0px 4px 4px 0px #00000040;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;

                .AllImageManage {
                    width: 250px;
                    height: 154.55px;
                }

            }

            .Message_Content {
                padding: 0px 16px;
                text-align: left;
                margin-top: 16px;

                h3 {
                    font-family: Montserrat !important;
                    font-size: 28px;
                    font-weight: 600;
                    color: #042953;
                }

                div {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.94px;
                    text-align: left;
                    color: #000000;
                }
            }
        }
    }

    .OneGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center !important;
        gap: 16px;

        .GridBoxFull {
            width: 100%;
            background-color: #fff !important;
            border-radius: 251px;
            box-shadow: none !important;
            border: 3px solid #EC1C24;
            margin-top: 64px !important;
            padding: 0 0px 32px 0px;

            .ImageBoxShadow {
                width: 100%;
                height: 220px;
                border-radius: 213px 213px 30px 30px;
                opacity: 0px;
                box-shadow: 0px 4px 4px 0px #00000040;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;

                .AllImageManage {
                    width: 280px;
                    height: 174px;
                }
            }


            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                color: #042953;
            }

            div {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.94px;
                text-align: center;
                color: #000000;
            }
        }
    }
}

.GetStart_Box {
    width: 100%;
    height: 450px;
    margin-top: 32px;
    background: #EC1C241A;
    display: flex;
    align-items: center;
    justify-content: center;

    .BoxContentCard {
        width: 693px;
        height: 252px;
        margin: 0 auto !important;

        h2 {
            font-family: Montserrat;
            font-size: 45px;
            font-weight: 600;
            line-height: 54.86px;
            text-align: center;
            color: #042953;
        }

        div {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 24.38px;
            text-align: center;
            color: #000000;
        }

        .Contact_Align {
            margin-top: 32px;
            display: flex;
            justify-content: center !important;
            align-items: center;

            button {
                width: 174px;
                height: 45px;
                border-radius: 10px;
                color: #fff;
                background: #F48120;
                border: none;
                font-family: Montserrat;
                font-size: 22px;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}


// Define breakpoint variables
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@media (max-width: $breakpoint-xs) {
    .container {
        padding: 16px;
    }

    .TowGrid {
        display: block !important;

        .MessageGrid {
            width: 100% !important;
            height: 100% !important;

            h2 {
                font-size: 36px !important;
            }
        }
        

        .ImageGrid {
            img {
                width: 100% !important;
                height: 500px !important;
                margin-top: 16px !important;
            }
        }
    }

    .ThreeGrid {
        display: block !important;
        grid-template-columns: none !important;

        .GridBox {
            width: 100% !important;
            
            .ImageBoxShadow {
                width: 100% !important;
            }
        }
    }

    .OneGrid {
        display: block !important;
        grid-template-columns: none !important;
        border: 3px solid #EC1C24;
        border-radius: 8px !important;
        margin-top: 32px !important;

        .GridBoxFull {
            width: 100% !important;
            border: none !important;
        }
    }

    .BoxContentCard {
        width: 100% !important;
        height: 252px;
    }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .container {
        padding: 16px !important;
    }

    .TowGrid {
        display: block !important;
        grid-template-columns: repeat(auto auto) !important;

        .MessageGrid {
            width: 100% !important;
            height: 100% !important;

            h2 {
                font-size: 36px !important;
            }
        }

        .ImageGrid {
            img {
                width: 100% !important;
                height: 400px !important;
                margin-top: 16px !important;
            }
        }
    }

    .ThreeGrid {
        display: block !important;
        grid-template-columns: none !important;

        .GridBox {
            width: 450px !important;

            .ImageBoxShadow {
                width: 100% !important;
            }
        }
    }

    .OneGrid {
        display: block !important;
        grid-template-columns: none !important;
        border: 3px solid #EC1C24;
        border-radius: 8px;
        margin-top: 32px !important;

        .GridBoxFull {
            width: 510px !important;
            border: none !important;
        }
    }
    .BoxContentCard {
        width: 100% !important;
    }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .container {
        padding: 16px !important;
    }

    .TowGrid {
        display: block !important;
        grid-template-columns: repeat(auto auto) !important;

        .MessageGrid {
            width: 100% !important;
            height: 100% !important;
        }

        .ImageGrid {
            img {
                width: 100% !important;
                height: 400px !important;
                margin-top: 16px !important;
            }
        }
    }

    .ThreeGrid {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;

        .GridBox {
            width: 360px !important;

            .ImageBoxShadow {
                width: 100% !important;
            }
        }
    }

    .OneGrid {
        display: block !important;
        grid-template-columns: none !important;
        border: 3px solid #EC1C24;
        border-radius: 40% 40% 40% 40% !important;
        margin-top: 32px !important;
        .GridBoxFull {
            width: 100% !important;
            border: none !important;
            
            .Message_Content{
                margin-top: 16px !important;
               div{
                width: 500px !important;
                margin: 0 auto !important;
               }
            }
        }
    }
    .BoxContentCard {
        width: 100% !important;
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .container {
        padding: 16px !important;
    }

    .TowGrid {
        display: grid !important;
        grid-template-columns: repeat(2 1fr) !important;

        .MessageGrid {
            width: 100% !important;
            height: 100% !important;
        }

        .ImageGrid {
            img {
                width: 100% !important;
                height: 400px !important;
                margin-top: 16px !important;
            }
        }
    }

    .ThreeGrid {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;

        .GridBox {
            width: 400px !important;

            .ImageBoxShadow {
                width: 100% !important;
            }
        }
    }

    .OneGrid {
        display: block !important;
        grid-template-columns: none !important;
        border: 3px solid #EC1C24;
        border-radius: 40% 40% 40% 40% !important;
        padding-bottom: 56px;
        margin-top: 32px;
        .GridBoxFull {
            width: 100% !important;
            border: none !important;
            
            .Message_Content{
                margin-top: 16px !important;
               div{
                width: 500px !important;
                margin: 0 auto !important;
               }
            }
        }
    }
}