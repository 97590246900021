@import "../../styles/mixins/global.scss";

.sourcing-section {
  background-color: #f8f8f8;

  .sourcing-banner-alignment {
    width: 100%;
    height: 533px;
    background-repeat: no-repeat;
    background-size: cover;
    background: url("../../assets/Image/gobal-sourcing-bg.png");
    background-position: center;

    @include breakpoint("max-sm") {
      height: auto;
    }

    .sourcing-banner-details {
      height: 533px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;

      @include breakpoint("max-sm") {
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        height: auto;
        padding: 40px 0;
      }

      .sourcing-banner-heading-text {
        display: flex;
        align-items: center;
        gap: 21px;

        @include breakpoint("max-sm") {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
        }
        img {
          @include breakpoint("max-sm") {
            margin: 0 auto;
          }
        }
        h4 {
          color: #fff;
          font-family: 'Poppins', sans-serif;
          font-size: 30px;
          font-weight: 600;
          line-height: 74px;

          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
      }

      p {
        max-width: 500px;
        color: #fff;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;

        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .sourcing-all-details-alignment {
    padding: 58px 0;

    .sourcing-all-flex-alignment {
      display: flex;
      align-items: center;
      margin: 0 0 148px 0;

      @include breakpoint("max-sm") {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        margin: 0 0 40px 0;
      }

      .sourcing-section-details-box {
        width: 407px;
        max-width: 407px;
        min-width: 407px;
        height: 316px;
        border-radius: 125px 10px;
        border: 1px solid #00aeef;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint("max-sm") {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }
      }

      .sourcing-section-details-box-all-details {
        background: linear-gradient(
          270deg,
          rgba(253, 193, 197, 0.5) 27.04%,
          rgba(253, 193, 197, 0) 83.13%
        );
        // opacity: 0.5;
        height: 292px;
        max-width: 894px;
        width: 894px;
        border-radius: 0 148px 148px 0;
        margin: 0 0 0 -116px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 32px;
        flex-direction: column;

        @include breakpoint("max-sm") {
          display: block;
          max-width: 100%;
          width: 100%;
          margin: 0;
          margin: 0;
          height: auto;
          background: transparent;
          border-radius: 0;
        }
        &.second-alignment {
          padding: 32px 88px;

          @include breakpoint("max-sm") {
            padding: 0;
          }
        }

        &.secondBox {
          border-radius: 148px 0 0 148px;
          background: linear-gradient(
            101deg,
            rgba(253, 193, 197, 0.5) 27.04%,
            rgba(253, 193, 197, 0) 83.13%
          );
          // opacity: 0.5;
          margin: 0 -116px 0 0;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          @include breakpoint("max-sm") {
            margin: 0;
            background: transparent;
            border-radius: 0;
          }
        }

        p {
          color: rgba(0, 0, 0, 0.8);
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          max-width: 652px;

          @include breakpoint("max-sm") {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .sourcing-read-more {
          padding: 13px 0 0 40px;

          button {
            padding: 6px 12px;
            border-radius: 10px;
            background: #00aeef;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            cursor: pointer;
            border: none;
          }
        }
      }
    }
  }
}
