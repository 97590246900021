@import "../../../styles/mixins/global.scss";

.our-latest-blog-all-content-alignment {
  padding: 80px 0;

  .text {
    padding: 0 0 24px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 40px 0;
    }
    h4 {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      text-transform: capitalize;
      color: #343434;
      text-align: center;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;


    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
    }

    .grid-items {
      .text {
        padding: 15px 0 0 0;

        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 134.5%;
          text-transform: capitalize;
          color: #343434;
          display: block;
          @include breakpoint("max-sm") {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .card-image {
        img {
          border-radius: 6px;
          height: 251px;
          width: 100%;
        }
      }
    }
  }
}
