@import '../../styles/mixins/global.scss';

.footer-designs {
    .footer-section {
        background: var(--theme-white-color);
        width: 100%;
        position: relative;

        @include breakpoint("max-sm") {
            height: auto;
        }

        .footer-alignment {
            padding: 80px 0;
            position: relative;

            @include breakpoint("max-sm") {
                padding: 180px 0 60px 0;
            }

            .logo-alignment {
                width: 212px;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }

                span {
                    color: #999999;
                    font-size: 10px;
                    line-height: 20px;
                    font-weight: 500;
                    padding-bottom: 12px;
                    cursor: pointer;

                    &:hover {
                        color: #ffffff;
                        transition: .1s;
                        -webkit-transition: .1s;
                        -moz-transition: .1s;
                    }
                }

                p {
                    color: #999999;
                    @include normal14;
                    padding-bottom: 12px;
                    cursor: pointer;

                    &:hover {
                        color: #ffffff;
                        transition: .1s;
                        -webkit-transition: .1s;
                        -moz-transition: .1s;
                    }
                }
            }

            .footer-details-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;
                padding: 70px 0 0 0;

                @include breakpoint("max-lg") {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                    padding: 40px 0 0 0;
                }

                @include breakpoint("max-sm") {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                    padding: 40px 0 0 0;
                }


                // .footer-child-details-section {
                //     display: flex;
                //     justify-content: flex-end;
                //     gap: 113px;
                //     width: 100%;

                //     @include breakpoint("max-sm") {
                //         flex-wrap: wrap;
                //         justify-content: center;
                //         gap: 40px;
                //     }

                .footer-details-section {
                    @include breakpoint("max-sm") {
                        margin: 0 60px;
                    }

                    .footer-child-title {
                        h4 {
                            color: var(--theme-heading-text-color);
                            font-size: 20px;

                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-bottom: 24px;
                            cursor: pointer;

                            &:hover {
                                color: var(--theme-heading-text-color);
                                transition: .1s;
                                -webkit-transition: .1s;
                                -moz-transition: .1s;
                            }
                        }
                    }

                    .footer-child-titles {
                        text-align: center !important;
                    }

                    .footer-list-alignment {
                        .footer-social-icon {
                            display: flex;
                            justify-content: center;
                        }

                        ul {
                            list-style-position: inside;
                            list-style: none;


                            li {
                                color: var(--theme-heading-text-color);
                                font-size: 16px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                line-height: normal;
                                padding-bottom: 10px;
                                cursor: pointer;

                                @include breakpoint("max-sm") {
                                    font-size: 14px;
                                    line-height: 22px;
                                }

                                &:hover {
                                    color: var(--theme-heading-text-color);
                                    transition: .1s;
                                    -webkit-transition: .1s;
                                    -moz-transition: .1s;
                                }
                            }

                            a {
                                text-decoration: none;
                                padding: 10px;
                            }

                            @include breakpoint("max-sm") {
                                a{
                                    padding: 5px;
                                }
                            }

                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }

                    .footer-list-alignment-socialmedia {

                        ul {
                            display: flex;
                            justify-content: space-between;
                            list-style: none;
                            margin-top: 5px;

                            li {
                                color: #999999;
                                @include normal14;
                                padding-bottom: 12px;
                                cursor: pointer;

                                &:hover {
                                    color: var(--theme-heading-text-color);
                                    transition: .1s;
                                    -webkit-transition: .1s;
                                    -moz-transition: .1s;
                                }
                            }
                        }
                    }

                    .aaziko-logo-alignment {
                        padding: 25px 0 0 0;
                    }
                }

                // }
            }

            .rain-bow-img {
                position: absolute;
                top: 0;
                right: 0;
                max-width: 230px;
            }
        }
    }
}