@import '../../styles/mixins/global.scss';

.pallet-dimensions-section {
    background: #FFF;

    .pallet-dimensions-alignment {
        padding: 60px 0 86px 0;

        .pallet-dimensions-heading-alignment {
            text-align: center;

            h4 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .pallet-dimensions-top-details-alignment {
            padding: 62px 0 0 0;

            .pallet-dimensions-top-flex-details {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                gap: 40px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .pallet-dimensions-img-alignment {
                    width: 281px;
                    max-width: 281px;
                    min-width: 281px;
                    height: 148px;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }

                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

            }
        }

        .pallet-dimensions-body-alignment {
            padding: 72px 0 0 0;

            .pallet-dimensions-box-alignment {
                border: 1px solid rgba(0, 0, 0, 0.50);
                background: #FFF;
                box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.30);
                width: 100%;

                .pallet-dimensions-box-heading-alignment {
                    border-bottom: 2px solid rgba(0, 0, 0, 0.50);
                    padding: 48px 42px 15px 42px;

                    ul {
                        display: flex;
                        align-items: center;
                        gap: 48px;
                        list-style: none;
                        flex-wrap: wrap;

                        li {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            cursor: pointer;
                            transition: 0.2s all;

                            &:hover {
                                font-weight: 600;
                                color: #00AEEF;
                                transition: 0.2s all;
                            }

                            &.list-active {
                                font-weight: 600;
                                color: #00AEEF;
                                transition: 0.2s all;
                            }
                        }
                    }
                }

                .pallet-dimensions-box-table-alignment {
                    table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
                                    padding: 26px;

                                    h6 {
                                        color: var(--theme-heading-text-color);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        padding: 0 0 12px 0;
                                        text-align: left;
                                    }

                                    p {
                                        color: rgba(0, 0, 0, 0.80);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        text-align: left;

                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:last-child {
                                    td {
                                        border-bottom: none;
                                    }
                                }

                                td {
                                    padding: 24px;
                                    color: var(--theme-heading-text-color);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.40);
                                }
                            }
                        }
                    }
                }
            }
        }

        .pallet-dimensions-euro-alignment {
            padding: 62px 0 0 0;

            h6 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .pallet-dimensions-euro-details-alignment {
                padding: 68px 0 0 0;

                .pallets-dimensions-grid-alignment {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 20px;

                    @include breakpoint("max-sm") {
                        grid-template-columns: repeat(1, 1fr) !important;
                    }

                    @include breakpoint("max-md") {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    .pallets-dimensions-grid-item {
                        .pallets-dimensions-child-heading {
                            text-align: center;

                            span {
                                color: #00AEEF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        .planner-dimensions-box-alignment {
                            border-radius: 5px;
                            border: 1px solid rgba(0, 0, 0, 0.20);
                            background: #FFF;
                            box-shadow: 0px 0px 8px 0px rgba(202, 202, 202, 0.30);
                            margin: 20px 0 0 0;
                            padding: 0 24px;

                            .planner-dimention-box-details-alignment {
                                padding: 34px 0 40px 0;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.80);

                                &:last-child {
                                    border-bottom: none;
                                }

                                h6 {
                                    color: var(--theme-heading-text-color);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}