@import "../../../../styles/mixins/global.scss";
$breakpoint-xs: 576px; //New Scss
$breakpoint-sm: 768px; //New Scss

.our-global-meeting-alignment {
  padding: 96px 0;

  @include breakpoint("max-sm") {
    padding: 0 0 40px 0;
  }

  .our-global-heading {
    h4 {
      color: var(--theme-heading-text-color);
      font-family: 'Poppins', sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .our-global-meeting-details-alignment {
    padding: 56px 0 0 0;

    @include breakpoint("max-sm") {
      padding: 30px 0 0 0;
    }

    .our-global-meeting-detail-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 56px 56px; // here change the second gap 102px

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      .our-global-meeting-detail-grid-item {
        .our-global-meeting-detail-box {
          width: 100%;
          max-height: 100%; // Add New Css
          min-height: 100%; // Add New Css
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 20px 24px 26px 24px;

          @include breakpoint("max-sm") {
            padding: 14px;
          }

          .meeting-img {
            width: 100%;
            height: 420px;

            @include breakpoint("max-sm") {
              height: 280px;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .meeting-name-details-content {
            padding: 13px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .meeting-name-description {
              h6 {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                opacity: 0.8;

                @include breakpoint("max-sm") {
                  font-size: 18px;
                  line-height: 26px;
                }
              }

              p {
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 120%;
                opacity: 0.8;
                padding: 4px 0 0 0;

                @include breakpoint("max-sm") {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  // New scss  add here...
  // Media Queries
  $breakpoint-xs-minus-1: calc(#{$breakpoint-xs} - 1px);

  @media (max-width: $breakpoint-xs-minus-1) {

    .our-global-meeting-alignment {
      .our-global-meeting-details-alignment {
        .our-global-meeting-detail-grid {
          display: block;
          grid-template-columns: auto auto;
        }

        .our-global-meeting-detail-grid-item {
          .our-global-meeting-detail-box {
            margin-top: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
  .our-global-meeting-alignment {
    .our-global-meeting-details-alignment {
      .our-global-meeting-detail-grid {
        display: block;
        grid-template-columns: auto auto;
      }

      .our-global-meeting-detail-grid-item {
        .our-global-meeting-detail-box {
          margin-top: 32px;
        }
      }

    }

  }
}