@import "../../../../styles/mixins/global.scss";

.full-container-load-all-content-alignment {
  padding: 24px 0 100px 0;

  .box {
    background: #ffffff;
    border-radius: 8px;
    padding: 60px 40px;

    @include breakpoint("max-sm") {
      padding: 40px 20px;
    }

    .container-image {
      padding: 24px 0;

      img {
        // max-width: 351px;
      }
    }

    .three-text {
      padding: 0 0 24px 0;

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #343434;
        margin: 0 0 14px 0;
      }

      ul {
        padding-left: 1.25rem;

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #606060;
        }
      }

      .advantages-over-other-type-box-alignment {
        border: 1px solid #e0e0e0;
        width: 100%;
        padding: 20px 26px 26px 26px;
        margin: 32px 0 0 0;

        .advantages-over-other-type-box-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 66px 145px;

          @include breakpoint("max-md") {
            grid-template-columns: 1fr;
          }

          .advantages-over-other-type-box-grid-item {
            .advantages-over-other-type-icon {
              width: 50px;
              height: 50px;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            p {
              color: #343434;
              text-align: center;
              padding: 22px 0 0 0;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }

    .sec-text {
      padding: 0 0 30px 0;

      &.sec-text-align {
        padding: 0;

        span {
          padding: 0 0 14px 0;
        }

        span:last-child {
          padding: 0;
        }
      }

      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #343434;
        margin: 0 0 14px 0;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #606060;
      }
    }

    .first-text {
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #343434;
        margin: 0 0 14px 0;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        padding: 0 0 8px 0;
        line-height: 24px;
        color: #606060;
      }

      a {
        display: block;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 130.5%;
        color: #00aeef;
      }
    }
  }
}