@import '../../../styles/mixins/global.scss';

.trade-services-banner-section {
    .trade-sevices-bg {
        width: 100%;
        height: 502px;
        background: url("../../../assets//Image/trade-seavices-banner.png");
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoint("max-sm") {
            height: auto;
            padding: 40px 0;
        }

        .trade-sevices-details-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 502px;

            @include breakpoint("max-sm") {
                height: auto;
                display: grid;
                grid-template-columns: repeat(1 , 1fr);
                gap: 40px;
            }

            .trade-sevices-left-side-alignment {
                h4 {
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 46px;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    padding: 12px 0 0 0;
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    max-width: 550px;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                .trade-sevices-button-alignment {
                    padding: 40px 0 0 0;

                    button {
                        padding: 12px 57px;
                        border-radius: 8px;
                        background: #FFF;
                        color: #00AEEF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 28px;
                        border: none;
                        cursor: pointer;
                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            line-height: 22px;
                            padding: 10px 24px;
                          }
                    }
                }
            }

            .trade-sevices-right-side-alignment {
                display: flex;
                gap: 21px;

                .trade-sevices-img {
                    &.small-img-alignment {
                        padding: 0 0 30px 0;
                    }
                }
            }
        }
    }
}