.container {
    padding: 26px 16px !important;

    .certificationGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;

        .MessageGrid {
            width: 400px;
            height: 255px;

            h2 {
                font-family: Montserrat;
                font-size: 65px;
                font-weight: 600;
                line-height: 79.24px;
                text-align: left;
                color: #042953;
            }
        }

        .ImageGrid {
            img {
                width: 616.32px;
                height: 428.53px;
                top: 134.92px;
                left: 708.55px;
                gap: 0px;
                opacity: 0px;
            }
        }
    }

    .certificationGridthreeGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center !important;
        gap: 16px;
        margin-top: 64px;

        .SpecailInBox {
            margin-top: 64px;
            height: 97% !important;
            width: 350px;
            background-color: #FFFFFF;
            border-radius: 120px;
            border-top: 15px solid #202020;
            box-shadow: 0px 0px 10px 3px #00000040;
            margin-bottom: 22px;

            div {
                text-align: center;

                .shadowbox {
                    padding: 26px 0px;
                    border-bottom: 2px dashed #202020;
                    border-radius: 120px;

                    img {
                        width: 203.36px;
                        height: 150px;
                        gap: 0px;
                        opacity: 0px;
                    }
                }
            }

            .infodetails {
                padding: 0px 16px;

                h2 {
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.94px;
                    text-align: center;
                    margin-top: 22px;
                    color: #042953;
                }

                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21.94px;
                    text-align: center;
                    color: #202020;
                    border: none;
                    height: 100%;
                    padding-top: 16px;
                }
            }
        }

    }

    .certificationonegrid {
        margin-top: 64px;

        .SpecailInBox {
            margin-top: 64px;
            height: 100% !important;
            width: 100%;
            background-color: #FFFFFF;
            border-radius: 120px;
            border-top: 15px solid #202020;
            box-shadow: 0px 0px 10px 3px #00000040;
            margin-bottom: 22px;

            div {
                text-align: center;

                .shadowbox {
                    padding: 32px 0px;
                    border-bottom: 2px dashed #202020;
                    border-radius: 120px;

                    img {
                        width: 203.36px;
                        height: 150px;
                        gap: 0px;
                        opacity: 0px;
                    }
                }
            }

            .infodetails {
                padding: 32px 16px;

                h2 {
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.94px;
                    text-align: center;
                    margin-top: 16px;
                    color: #042953;
                }

                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21.94px;
                    text-align: center;
                    color: #202020;
                    padding: 22px 130px;
                }
            }
        }
    }
}

.backgroundbox {
    width: 100%;
    opacity: 0px;
    background: #E1F6FF;
    margin-top: 36px;

    .listinfo {
        padding: 36px 99px;

        h2 {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: 600;
            line-height: 34.13px;
            text-align: left;
            color: #042953;
        }

        .informationaligngrid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;

            .informationdetils {
                margin-top: 32px;

                h2 {
                    font-family: Montserrat;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29.26px;
                    text-align: center;
                    color: #202020;
                }

                div {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.5px;
                    text-align: left;
                    color: #202020;
                    margin-top: 16px;
                }
            }
        }
    }
}

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
@media (max-width: $breakpoint-xs) {
    .container {
        padding: 16px !important;

        .certificationGrid {
            display: block;

            .MessageGrid {
                width: 100%;

                h2 {
                    font-size: 45px;
                    line-height: 50.24px;
                }
            }

            .ImageGrid {
                margin-top: -99px;

                img {
                    width: 100%;
                }
            }
        }

        .certificationGridthreeGrid {
            display: block;

            .SpecailInBox {
                height: 100% !important;
                width: 100%;
                padding-bottom: 32px;

                .infodetails {
                    padding: 16px 16px;
                }
            }
        }

        .certificationonegrid {
            .SpecailInBox {
                .infodetails {
                    .message {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .backgroundbox {
        .listinfo {
            padding: 36px 0px;

            .informationaligngrid {
                display: block;
            }
        }
    }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .container {
        padding: 16px !important;

        .certificationGrid {
            margin-top: 66px;

            .MessageGrid {
                width: 100%;

                h2 {
                    font-size: 45px;
                    line-height: 50.24px;
                }
            }

            .ImageGrid {
                margin-top: -99px;

                img {
                    width: 100%;
                }
            }
        }

        .certificationGridthreeGrid {
            grid-template-columns: repeat(2, 1fr);

            .SpecailInBox {
                height: 100% !important;
                width: 100%;
                padding-bottom: 32px;

                .infodetails {
                    padding: 16px 16px;
                }
            }
        }

        .certificationonegrid {
            .SpecailInBox {
                .infodetails {
                    .message {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .backgroundbox {
        .listinfo {
            padding: 36px 0px;
        }
    }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .container {
        padding: 16px !important;

        .certificationGrid {
            margin-top: 66px;

            .MessageGrid {
                width: 100%;

                h2 {
                    font-size: 45px;
                    line-height: 50.24px;
                }
            }

            .ImageGrid {
                margin-top: -99px;

                img {
                    width: 100%;
                }
            }
        }

        .certificationGridthreeGrid {
            grid-template-columns: repeat(2, 1fr);

            .SpecailInBox {
                height: 100% !important;
                width: 100%;
                padding-bottom: 32px;

                .infodetails {
                    padding: 16px 16px;
                }
            }
        }

        .certificationonegrid {
            .SpecailInBox {
                .infodetails {
                    .message {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .backgroundbox {
        .listinfo {
            padding: 36px 0px;
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .container {
        padding: 16px !important;

        .certificationGrid {
            margin-top: 66px;

            .MessageGrid {
                width: 100%;

                h2 {
                    font-size: 45px;
                    line-height: 50.24px;
                }
            }

            .ImageGrid {
                margin-top: -99px;

                img {
                    width: 100%;
                }
            }
        }

        .certificationGridthreeGrid {
            grid-template-columns: repeat(2, 1fr);

            .SpecailInBox {
                height: 100% !important;
                width: 100%;
                padding-bottom: 32px;

                .infodetails {
                    padding: 16px 16px;
                }
            }
        }

        .certificationonegrid {
            .SpecailInBox {
                .infodetails {
                    .message {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .backgroundbox {
        .listinfo {
            padding: 36px 0px;
        }
    }
}