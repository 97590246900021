@import "../../../styles/mixins/global.scss";

.trade-agreement-easy-solution-section {
    .trade-agreement-easy-solution-alignment {
        .trade-agreement-easy-solution-header {
            text-align: center;

            h4 {
                color: #000;
                font-size: 40px;
                font-weight: 700;
                line-height: 44px;
                opacity: 0.8;
            }

            p {
                padding: 26px 0 0 0;
                color: #000;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                max-width: 510px;
                margin: 0 auto;
            }
        }

        .trade-agreement-easy-solution-body {
            padding: 66px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}