@import "../../../styles/mixins/global.scss";


.kwa-banner-design {
  background: linear-gradient(100.84deg, #018dc1 9.55%, #76d4f7 89.68%);
  padding: 50px 0;

  .grid {
    display: grid;
    grid-template-columns: 1fr 548px;
    gap: 40px;
    align-items: center;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1 , 1fr);
    }
    .grid-items {
      h4 {
        font-weight: 600;
        font-size: 30px;
        line-height: 140.5%;
        margin: 0 0 10px 0;
        color: #ffffff;
        @include breakpoint("max-sm") {
          font-size: 24px;
          line-height: 36px;
        }
      }

      p {
        font-weight: 400;
        margin: 0 0 40px 0;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        max-width: 569px;
      }

      a {
        display: block;
        cursor: pointer;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }

      .video-box {
        background: #606060;
        border-radius: 4px;
        width: 100%;
        height: 336px;
      }
    }
  }
}
