@import '../../../styles/mixins/global.scss';

.all-grid-content-alignment {
    padding: 80px 0;

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    .grid:last-child {
        padding: 0;
    }

    .fill-button {
        @include flex-center;
        justify-content: center;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
        align-items: center;
        padding: 0 0 80px 0;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .grid-items {
            p {
                @include heading-lg;
                color: var(--theme-text-black-100);
                margin: 0 0 20px 0;
            }

            span {
                @include normal18;
                color: var(--theme-text-gray-500);
            }

            .video-text-style {
                iframe {
                    height: 350px;

                    @include breakpoint("max-sm") {
                        height: 260px;
                    }
                }
            }
        }
    }
}