@import "../../../styles/mixins/index.scss";

.load-stuffing-results-section {
  padding: 24px 0 74px 0;

  .load-stuffing-results-alignment {
    .load-stuffing-top-details-alignment {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;

      @include breakpoint("max-md") {
        justify-content: center;
      }

      .load-stuffing-top-box-alignment {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.3);
        width: 384px;
        // max-width: 384px;
        // min-width: 384px;
        height: 100%;

        .load-stuffing-to-box-heading {
          padding: 18px 18px 34px 18px;

          h4 {
            color: #343434;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            text-align: center;
          }

          .stuffing-img-alignment {
            margin: 28px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .load-stuffing-bottom-details-alignment {
          border-top: 1px solid #bababa;
          display: flex;
          align-items: center;
          height: 100%;

          .load-stuffing-units-details {
            padding: 28px 64px 28px 43px;
            border-right: 1px solid #bababa;

            h6 {
              color: #00aeef;
              font-family: "Poppins", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              white-space: nowrap;
            }
          }

          .load-weight-volume-alignment {
            width: 100%;

            p {
              color: #606060;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border-bottom: 1px solid #bababa;
              padding: 10px 16px;
              margin: 0;

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  .load-stuffing-other-details-alignment {
    padding: 24px 0 0 0;

    .load-stuffing-other-details-box-alignment {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
      box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.3);
      margin: 0 0 24px 0;

      .load-stuffing-other-details-box-grid {
        display: grid;
        grid-template-columns: 408px 1fr;

        @include breakpoint("max-md") {
          grid-template-columns: 100%;
        }

        .load-stuffing-other-details-box-grid-item {
          .load-stuffing-other-details-box-left-side {
            padding: 24px;
            border-right: 1px solid #bababa;

            h6 {
              color: #343434;
              font-family: "Poppins", sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }

            .load-stuffing-other-img {
              margin: 30px 0 18px 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .load-stuffing-other-bottom-details {
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                color: #00aeef;
                font-family: "Poppins", sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .view-button-alignment {
                button {
                  padding: 8px 40px;
                  border-radius: 4px;
                  border: 1px solid #00aeef;
                  background: rgba(0, 174, 239, 0.1);
                  color: #00aeef;
                  font-family: "Poppins", sans-serif;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  cursor: pointer;
                }
              }
            }
          }

          .load-stuffing-other-details-box-right-side {
            padding: 16px 20px;
            border-bottom: 1px solid #bababa;

            .load-stuffing-other-details-box-right-top-grid {
              display: grid;
              grid-template-columns: 124px 1fr;
              gap: 46px;
              margin: 0 0 14px 0;

              &:last-child {
                margin: 0;
              }

              h6 {
                color: #343434;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
              }

              p {
                color: #606060;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }

          .load-stuffing-other-chart-alignment {
            padding: 20px;

            .load-stuffing-other-chart-grid {
              display: grid;
              grid-template-columns: 132px 1fr;
              gap: 64px;

              @include breakpoint("max-md") {
                grid-template-columns: 100%;
              }

              .load-stuffing-other-chart-grid-item {
                .load-stuffing-chart-details-alignment {
                  table {
                    width: 100%;

                    thead {
                      tr {
                        th {
                          color: #bababa;
                          font-family: "Poppins", sans-serif;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          padding: 0 14px 18px 0;
                          text-align: left;
                        }
                      }
                    }

                    tbody {
                      tr {
                        td {
                          padding: 0 15px 20px 0;

                          .load-stuffing-name-alignment {
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            .load-stuffing-colour-box {
                              width: 20px;
                              height: 20px;
                              border-radius: 4px;

                              &.blue-box {
                                background: #165ae5;
                              }

                              &.green-box {
                                background: #00a651;
                              }
                            }

                            h6 {
                              color: #343434;
                              font-family: "Poppins", sans-serif;
                              font-size: 20px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              text-transform: capitalize;
                            }
                          }

                          .load-stuffing-pakage-alignment {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            h6 {
                              color: #606060;
                              font-family: "Poppins", sans-serif;
                              font-size: 18px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: normal;
                              text-transform: capitalize;
                            }
                          }

                          color: #606060;
                          font-family: "Poppins",
                          sans-serif;
                          font-size: 18px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .load-stuffing-button-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 26px 0 0 0;

    .load-stuffing-back-button {
      button {
        padding: 12px 46px;
        border-radius: 4px;
        border: 1px solid #bababa;
        background: rgba(186, 186, 186, 0.2);
        color: #606060;
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @include breakpoint("max-sm") {
          font-size: 18px;
        }
      }
    }

    .export-pdf-button {
      button {
        padding: 12px 36px;
        border-radius: 4px;
        background: #00aeef;
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        border: none;

        @include breakpoint("max-sm") {
          font-size: 18px;
          padding: 12px 12px;
        }
      }
    }
  }
}