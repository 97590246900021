@import "../../styles/mixins/global.scss";

.ship-schedule-section {
    .ship-schedule-alignment {
        padding: 50px 0 60px 0;

        .ship-schedule-top-box-alignment {
            border-radius: 8px;
            background: #FFF;
            width: 246px;
            height: 52px;
            padding: 6px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border-radius: 8px;
            border: 0.5px solid rgba(0, 0, 0, 0.50);

            .ship-schedule-child-details-alignment {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                cursor: pointer;


                .ship-schedule-icon {
                    width: 18px;
                    height: 18px;
                    display: none;

                    &.ship-active-icon {
                        display: block;
                    }

                    &.default-active-icon {
                        display: block;
                    }
                }

                .ship-schedule-name {
                    p {
                        color: #606060;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        text-transform: capitalize;
                    }
                }

                &.active-option {
                    background: rgba(0, 174, 239, 0.1);
                    border-radius: 8px;

                    .ship-schedule-name p {
                        font-weight: 700;
                        color: #00AEEF;
                    }
                }
            }
        }

        .ship-schedule-filter-box-alignment {
            margin: 14px 0 0 0;
            width: 100%;
            height: 70px;
            border-radius: 8px;
            padding: 8px 14px;
            display: flex;
            align-items: center;
            background: #F1F1F1;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

            .ship-schedule-first-secrch-alignment {
                display: flex;
                align-items: center;
                gap: 24px;
                border-right: 1px solid #BABABA;

                @include breakpoint(max-sm) {
                    flex-wrap: wrap;
                }

                .ship-schedule-search-alignment {
                    border-radius: 8px;
                    border: 1px solid #00AEEF;
                    background: #FFF;
                    width: 300px;
                    height: 42px;
                    padding: 0 12px;

                    .search-number-alignment {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        height: 100%;

                        p {
                            color: #00AEEF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-style: 24px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        input {
                            border: none;
                            background: transparent;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            text-transform: capitalize;
                            width: 100%;
                            height: 100%;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {

                                color: #BABABA;
                            }
                        }
                    }
                }

                .second-ship-schedule-search-alignment {
                    border-radius: 8px;
                    background: transparent;
                    width: 300px;
                    height: 42px;

                    .second-search-number-alignment {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        height: 100%;

                        p {
                            color: #00AEEF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-style: 24px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        input {
                            border: none;
                            background: transparent;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            text-transform: capitalize;
                            width: 100%;
                            height: 100%;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {

                                color: #BABABA;
                            }
                        }
                    }
                }
            }

            .ship-date-search-alignment {
                border-right: 1px solid #BABABA;
                width: 200px;
                height: 100%;
                padding: 0 43px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                p {
                    color: #343434;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-transform: capitalize;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 82px;
                }
            }

            .ship-days-filter-alignment {
                border-right: 1px solid #BABABA;
                width: 200px;
                height: 100%;
                padding: 0 28px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                position: relative;

                p {
                    color: #343434;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .ship-day-filter-dropdown-box-alignment {
                    position: absolute;
                    width: 130px;

                    box-shadow: 1px -1px 6px rgba(0, 0, 0, 0.20);
                    background: #FFF;
                    left: 24px;
                    top: 48px;
                    border-radius: 4px;
                    overflow-y: auto;

                    &.ship-day-week-open-alignment {
                        max-height: 156px;
                        overflow-y: auto;
                        transition: 0.4s ease-in-out;
                    }

                    &.ship-day-week-close-alignment {
                        max-height: 0px;
                        overflow-y: hidden;
                        transition: 0.4s ease-in-out;
                    }

                    .ship-day-filter-details-alignment {
                        padding: 6px;

                        p {
                            padding: 6px;
                            color: #606060;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            cursor: pointer;
                            margin: 0 0 2px 0;

                            &:hover {
                                background: rgba(0, 174, 239, 0.1);
                                border-radius: 4px;
                            }
                        }
                    }
                }

            }

            .ship-all-filter-search-button-alignment {
                display: flex;
                align-items: center;
                gap: 30px;
                padding: 0 0 0 30px;

                .filter-icon {
                    img {
                        cursor: pointer;
                    }
                }

                .search-button-alignment {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }


        }

        .ship-schedule-filter-body-alignment {
            padding: 60px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 0;
            align-items: center;
            justify-content: center;

            p {
                max-width: 430px;
                margin: 0 auto;
                color: #606060;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                text-transform: capitalize;
            }
        }
    }
}