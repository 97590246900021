@import '../../../styles/mixins/global.scss';

.vision-all-section-content-alignment {
    padding: 80px 0;

    .page-title {
        padding: 0 0 100px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 80px 0;
        }

        h2 {
            color: var(--theme-text-black-300);
            @include text-center;
            margin: 0 0 20px 0;
            @include heading-2;
        }

        h3 {
            @include heading-3;
            color: var(--theme-text-black-300);
            @include text-center;
            margin: 0 0 20px 0;
        }

        p {
            @include normal18;
            color: var(--theme-text-gray-500);
            margin: 0;
            @include text-center;
        }
    }

    .all-grid-control {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px 40px;

    }

    .grid {

        .grid-items {



            .video-style {
                iframe {
                    height: 250px;

                    @include breakpoint("max-sm") {
                        height: 230px;
                    }
                }
            }

            h3 {
                @include heading-lg;
                font-size: 20px;
                line-height: 30px;
                color: var(--theme-text-black-100);
                margin: 40px 0 20px 0;
            }

            p {
                @include normal18;
                font-size: 14px;
                line-height: 24px;
                color: var(--theme-text-gray-500);
                margin: 0;

                a {
                    color: var(--theme-link-text-color);
                }
            }

        }
    }


    .vision-page-title {
        h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: #1E1E1E;
            position: relative;
            text-align: center;

            
            @include breakpoint("max-sm") {
                font-size: 24px;
                line-height: 32px;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background: #00AEEF;
                width: 81px;
                right: 50%;
                bottom: -11px;
                transform: translateX(50%);
            }
        }

        h5 {
            padding: 35px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.03em;
            text-align: center;
            color: #1E1E1E;
        }

        p {
            padding: 24px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #8A8C9A;

        }
    }


    .vision-all-grid-control {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
        padding: 60px 0 0 0;

        .vision-all-gridietem {
            background: #FFFFFF;
            box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.12);
            border-radius: 14px;
            padding: 20px;
            width: 350px;

            .video-alignment {
                width: 100%;

                iframe {
                    height: 180px;

                }
            }


            .vision-details-alignmenty {
                h3 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    letter-spacing: 0.03em;
                    color: #1E1E1E;
                    padding: 16px 0 0 0;
                }

                p {
                    padding: 14px 0 0 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.03em;
                    color: #8A8C9A;
                }
            }
        }

    }
}