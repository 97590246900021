@import "../../../styles/mixins/global.scss";

.lsq-banner-section {
    padding: 100px 0;

    .lsq-banner-grid {
        display: grid;
        grid-template-columns: 1fr 553px;
        gap: 8px;
        align-items: center;

        .lsq-banner-left-side-alignment {
            h4 {
                color: rgba(0, 0, 0, 0.8);
                font-size: 48px;
                font-weight: 700;
                line-height: 52px;
                text-align: left;
                color: #000000CC;
                // max-width: 512px;
            }

            p {
                padding: 30px 0 0 0;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .submit-button-alignment {
            padding: 30px 0 0 0;

            button {
                padding: 18px 68px;
                border-radius: 15px;
                background: #EC6917;
                color: #FFF;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                cursor: pointer;
                border: none;
            }
        }
    }
}