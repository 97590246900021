@import "../../styles/mixins/global.scss";

.container-dimensions-details-section {
  background: #fff;

  .container-dimensions-details-alignment {
    padding: 60px 0;

    @include breakpoint("max-sm") {
      padding: 30px 0;
    }

    .container-dimensions-details-heading-alignment {
      text-align: center;

      h4 {
        color: var(--theme-heading-text-color);
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.8;

        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .container-dimensions-details-body-alignment {
      width: 100%;
      margin: 42px 0 0 0;
      border-radius: 10px;  
      border: 1px solid rgba(0, 0, 0, 0.50);
      background: #FFF;
      box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.30);
      height: 100%;

      @include breakpoint("max-sm") {
        margin: 30px 0 0 0;
      }

      .container-dimensions-details-grid {
        padding: 32px 42px;
        display: grid;
        grid-template-columns: 540px 1fr;
        gap: 12px;

        @include breakpoint("max-sm") {
          padding: 24px;
          grid-template-columns: repeat(1, 1fr);
          gap: 24px;
        }

        .container-dimensions-details-grid-item {
          .type-heading {
            h5 {
              color: var(--theme-heading-text-color);
              font-family: "Poppins", sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }

          .container-dimensions-details-img {
            width: 100%;
            height: 278px;
            margin: 40px 0 0 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .container-dimensions-details-right-side-details {
            padding: 0 74px 0 0;

            @include breakpoint("max-sm") {
              padding: 0;
            }

            .container-dimensions-details-right-side-grid {
              display: grid;
              grid-template-columns: 268px 1fr;
              gap: 48px;

              @include breakpoint("max-sm") {
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                padding: 0 0 20px 0;
              }

              .container-dimensions-details-right-side-grid-item {
                p {
                  color: var(--theme-heading-text-color);
                  font-family: "Poppins", sans-serif;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 55px;

                  @include breakpoint("max-sm") {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .container-dimensions-details-description-alignment {
        padding: 18px 24px 35px 76px;

        @include breakpoint("max-sm") {
          padding: 20px 30px;
        }

        .description-heading {
          h5 {
            color: var(--theme-heading-text-color);
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 55px;

            @include breakpoint("max-sm") {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }

        .description-all-details-alignment {
          p {
            color: var(--theme-heading-text-color);
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            padding: 0 0 12px 0;

            @include breakpoint("max-sm") {
              font-size: 14px;
              line-height: 24px;
            }
          }

          ul {
            padding: 0 0 18px 0;

            li {
              color: var(--theme-heading-text-color);
              font-family: "Poppins", sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;

              @include breakpoint("max-sm") {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}