@import "../../../styles/mixins/global.scss";

.lsq-industry-insight-section {
    padding: 86px 0 0 0;

    .lsq-industry-heading-alignment {
        h6 {
            color: #000;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            padding: 0 0 0 32px;
        }
    }

    .lsq-industry-body-details-alignment {
        padding: 10px 0 0 0;

        .lsq-industry-body-details-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 22px;

            .lsq-industry-body-details-grid-item {
                align-self: center;
                .lsq-industry-left-side-alignment {
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    padding: 6px 16px 16px 32px;
                    width: 100%;

                    .lsq-top-heading-alignment {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        h6 {
                            color: #000;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 21px;
                            margin-bottom: 0px !important;
                        }

                        p {
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            margin-bottom: 0px !important;
                        }
                    }

                    .lsq-body-alignment {
                        padding: 28px 0 0 0;
                    }
                }

                .lsq-industry-right-side-alignment {
                    padding: 6px 14px;
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    .right-side-top-alignment-heding {
                        h6 {
                            color: #000;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 21px;
                        }
                    }

                    .lsq-industry-table-alignment {
                        width: 100%;

                        .lsq-industry-heading-alignment {
                            padding: 0 43px;

                            .lsq-industry-grid-alignment {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 1px;
                                padding: 8px 0 0 0;

                                .lsq-industry-grid-item-box {
                                    background: #00F;
                                    text-align: center;
                                    padding: 4px;

                                    p {
                                        color: #FFF;
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 21px;
                                        margin-bottom: 0px !important;
                                    }
                                }

                            }
                        }

                        .lsq-industry-img-chart {
                            padding: 8px 0 0 8px;
                        }
                    }
                }
            }
        }
    }

    .lsq-view-report-alignment {
        padding: 68px 0 0 0;

        a {
            color: #0000FF !important;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 18px;
            cursor: pointer;
        }

    }
}