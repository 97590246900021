@import "../../../styles/mixins/global.scss";

.compliance-all-content-alignment {
  padding: 0 0 100px 0;

  @include breakpoint("max-sm") {
    padding: 0 0 30px 0;
  }
  .title {
    padding: 0 0 80px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 30px 0;
    }
    h6 {
      color: #042953;
      font-size: 45px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 115.9%;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .grid {
    display: grid;
    gap: 80px 30px;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    &.grid-child {
      display: flex;
      padding: 80px 0 0 0;
      gap: 30px;
      justify-content: center;

      @include breakpoint("max-sm") {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }
      .grid-items {
        // min-width: 393px;
        max-width: 393px;

        @include breakpoint("max-sm") {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    .grid-items {
      .icon-center {
        display: flex;
        justify-content: center;
      }
      .text {
        padding: 30px 0 0 0;
        p {
          color: var(--theme-heading-text-color);
          margin: 0 0 20px 0;
          text-align: center;
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: 115.9%;

          @include breakpoint("max-sm") {
            font-size: 16px;
            line-height: 26px;
          }
        }

        span {
          display: block;
          color: rgba(0, 0, 0, 0.8);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          @include breakpoint("max-sm") {
            font-size: 14px;
          }
        }
      }
    }
  }
}
