@import "../../../styles/mixins/global.scss";

.reefer-cargo-delivery-all-content-alignment-for-page {
  padding: 24px 0 100px 0;


  @include breakpoint("max-sm") {
    padding: 24px 0 40px 0;
  }

  .white-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 40px 80px;


    @include breakpoint("max-sm") {
      padding: 40px 30px;
    }

   

    .three-col-grid {
      display: grid;
      grid-template-columns: 496px 1fr;
      gap: 47px;
      padding: 0 0 50px 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
      }
      .three-col-grid-items {
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          color: #343434;
          margin: 0 0 14px 0;
        }

        span {
          display: block;
          font-weight: 400;
          max-width: 521px;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
        }
      }
    }

    .sec-grid {
      display: grid;
      grid-template-columns: 1fr 340px;
      gap: 81px;
      padding: 0 0 50px 0;

      
      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }

      .sec-grid-items {
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 14px 0;
          color: #343434;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #606060;
          max-width: 521px;
        }
      }
    }

    .text-style {
      padding: 0 0 50px 0;
      p {
        max-width: 907px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #606060;
        margin: 0 auto;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 497px;
      gap: 51px;
      align-items: center;
      padding: 0 0 50px 0;

      @include breakpoint("max-md") {
        grid-template-columns: repeat(1 , 1fr);
        gap: 40px;
      }
      .grid-items {
        .image-style {
          img {
            width: 100%;
          }
        }

        .button-text-alignment {
          display: flex;
          padding: 30px 0 0 0;
          align-items: center;
          gap: 11px;

          a {
            display: block;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            line-height: 130.5%;
            color: #00aeef;
          }

          button {
            background: #00aeef;
            border-radius: 7px;
            padding: 6px 18px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            cursor: pointer;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 14px 0;
          color: #343434;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          padding: 0 0 40px 0;
          color: #606060;
          max-width: 521px;
        }
      }
    }
  }
}
