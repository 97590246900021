@import "../../../styles/mixins/global.scss";

.buyer-section-all-content-alignment {
  background-color: #fff;
  padding: 50px 0 100px 0;

  .all-content-new-page-alignment {
    .main-content:last-child {
        padding: 0;
    }
    .main-content {
        padding: 0 0 50px 0;
      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        margin: 0 0 25px 0;
        color: #343434;
      }

      .btn-center {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0 0 0;
        button {
          border: none;
          cursor: pointer;
          font-weight: 400;
          background: #00AEEF;
        border-radius: 4px;
        padding: 8px 31px;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;


        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1 , 1fr);
        }

        .grid-items {
          background: #ffffff;
          border: 1px solid #bababa;
          border-radius: 8px;
          cursor: pointer;
          .details {
            padding: 14px;

            .last-text {
              display: flex;
              align-items: center;
              gap: 11px;

              span {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #606060;

                display: block;
              }
            }

            h6 {
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: #343434;
              margin: 0 0 30px 0;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #606060;
              margin: 0 0 8px 0;
            }
          }

          .card-image {
            img {
              border-radius: 7px 7px 0px 0px;
              width: 100%;
              height: 172px;
            }
          }
        }
      }
    }
  }
}
