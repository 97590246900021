@import '../../styles/mixins/global.scss';

.company-details-section {
    background: #D3EAFD;

    .company-details-bg-alignment {
        background: url("../../assets/Image/contact-bg.png");
        width: 100%;
        height: 530px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        top: -36px;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint("max-sm") {
            height: 320px;
        }

        h4 {
            color: #434242;
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-weight: 800;
            line-height: 54px;
            letter-spacing: 4.05px;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .company-details-form-alignment {
        padding: 20px 0;

        .company-details-form-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 52px 20px;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 20px;
            }

            .input-alignment {
                input {
                    width: 100%;
                    height: 50px;
                    border-radius: 15px;
                    border: 1.5px solid rgba(0, 0, 0, 0.80);
                    background: transparent;
                    padding: 0 20px;
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;

                    &::placeholder {
                        color: var(--theme-heading-text-color);

                    }
                }
            }
        }

        .company-address-alignment {
            padding: 52px 0 0 0;

            @include breakpoint("max-sm") {
                padding: 20px 0 0 0;
            }

            textarea {
                width: 100%;
                height: 152px;
                border-radius: 15px;
                border: 1.5px solid rgba(0, 0, 0, 0.80);
                background: transparent;
                padding: 20px;
                color: var(--theme-heading-text-color);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
            }
        }
    }

    .company-submit-section {
        background: url("../../assets/Image/contact-bottom-bg.png");
        height: 558px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 108px 0 0 0;
        text-align: center;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
            height: 320px;
        }

        button {
            padding: 18px 78px;
            border-radius: 15px;
            background: #2478F4;
            color: #D3EAFD;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 34px;
            cursor: pointer;
            border: none;

            @include breakpoint("max-sm") {
                font-size: 14px;
                line-height: 20px;
                padding: 12px 24px;
            }
        }
    }
}