@import "../../styles/mixins/index.scss";

.on-site-inspection-section {
    background: #FFF;
    padding: 16px 0;

    .on-site-inspection-banner {

        .on-site-inspection-banner-grid {
            display: grid;
            grid-template-columns: 1fr 458px;
            gap: 24px;
            align-items: center;

            @include breakpoint("max-sm") {
                grid-template-columns: 1fr;
                gap: 24px;
            }

            .on-site-icon-box {
                width: 573px;
                height: 389px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    max-width: 300px;
                    margin: 16 auto;
                    padding: 40px;
                }

            }

            .on-site-banner-left-side-alignment {
                h4 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    opacity: 0.8;
                    font-size: 70px;
                    font-weight: 600;
                    line-height: 85.33px;
                    width: 500px;
                    text-align: left;
                    color: #042953;

                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        width: 300px !important;
                        line-height: 30px !important;

                    }
                }

                p {
                    padding: 22px 0 0 0;
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        padding: 8px 0 0 0;
                    }
                }
            }
        }
    }

    .on-site-inpection-all-details-alignment {
        padding: 122px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 40px 0 0 0;
        }

        .on-site-inpection-child-details-alignment {
            .on-site-inpection-grid-alignment-tow {
                display: grid;
                align-items: center;
                grid-template-columns: 1000px 1fr;
                gap: 24px;
                margin: 0 0 26px 0;

                @include breakpoint("max-sm") {
                    grid-template-columns: 1fr;
                    margin: 0 0 40px 0;
                    gap: 8px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 206px;
                    top: 0;
                    left: 0;

                    @include breakpoint("max-sm") {
                        display: none;
                    }

                }

                .on-site-img {
                    position: relative;
                    z-index: 99;
                }

                .on-site-right-text-alignment {
                    padding: 21px 0 0 0;

                    @include breakpoint("max-sm") {
                        padding: 8px 0 0 0;
                    }

                    h6 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-style: normal;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 39.01px;
                        color: #000000;

                        @include breakpoint("max-sm") {
                            font-size: 18px;
                            line-height: 30px;
                        }

                    }

                    p {
                        padding: 26px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #000000;

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            padding: 8px 0 0 0;
                        }
                    }
                }
            }

            .on-site-inpection-grid-alignment-one {
                display: grid;
                align-items: center;
                grid-template-columns: 457px 1fr;
                gap: 24px;
                margin: 0 0 26px 0;
                position: relative;

                @include breakpoint("max-sm") {
                    grid-template-columns: 1fr;
                    margin: 0 0 40px 0;
                    gap: 8px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 206px;
                    top: 0;
                    left: 0;

                    @include breakpoint("max-sm") {
                        display: none;
                    }

                }

                .on-site-img {
                    position: relative;
                    z-index: 99;
                }

                .on-site-right-text-alignment {
                    padding: 21px 0 0 0;

                    @include breakpoint("max-sm") {
                        padding: 8px 0 0 0;
                    }

                    h6 {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-style: normal;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 39.01px;
                        color: #000000;

                        @include breakpoint("max-sm") {
                            font-size: 18px;
                            line-height: 30px;
                        }

                    }

                    p {
                        padding: 26px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #000000;

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            padding: 8px 0 0 0;
                        }
                    }
                }

            }
        }
    }

    .Product-Title {
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 600;
        line-height: 39.01px;
        text-align: center;
        color: #000000;
        padding-bottom: 32px;
    }
}



// Responsive Scss Add Here...
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

// @media (max-width: $breakpoint-xs) {}


@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .on-site-inspection-banner {
        margin: 0 auto !important;
        .on-site-inspection-banner-grid {
            display: block !important;
        }
    }

    .on-site-banner-left-side-alignment {
        h4 {
            width: 100% !important;
            font-size: 52px !important;
            line-height: 50px !important;
        }
    }
    .on-site-inpection-grid-alignment-one {
        display: grid;
        grid-template-columns: 300px 1fr !important;

        .on-site-img {
            height: 300px !important;
            width: 250px !important;
        }

    }
    .on-site-inpection-child-details-alignment {
        .on-site-inpection-grid-alignment-tow {
            display: grid;
            grid-template-columns: 400px 1fr !important;
        }

        .on-site-img {
            height: 300px !important;
            width: 250px !important;
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .on-site-inspection-banner {
        margin: 0 auto !important;
        .on-site-inspection-banner-grid {
            display: grid !important;
        }
    }

    .on-site-banner-left-side-alignment {
        h4 {
            width: 100% !important;
            font-size: 52px !important;
            line-height: 50px !important;
        }
    }
    .on-site-inpection-grid-alignment-one {
        display: grid;
        grid-template-columns: 300px 1fr !important;

        .on-site-img {
            height: 300px !important;
            width: 250px !important;
        }

    }
    .on-site-inpection-child-details-alignment {
        .on-site-inpection-grid-alignment-tow {
            display: grid;
            grid-template-columns: 650px 1fr !important;
        }

        .on-site-img {
            height: 300px !important;
            width: 250px !important;
        }
    }
}