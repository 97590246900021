@import '../../../styles/mixins/global.scss';

.insurance-other-option-main {
    .insurance-other-option-alignment {
        padding: 60px 0;

        .insurance-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 36px;

            .insurance-grid-item-alignment {
                display: flex;
                align-items: center;
                gap: 15px;

                .insurance-icon-img {
                    width: 62px;
                    height: 62px;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }

                p {
                    color: rgba(0, 0, 0, .8);
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }

        .top-insure-main {
            margin: 50px 0 0 0;

            .top-insure-box {
                border-radius: 10px;
                background: rgba(230, 240, 255, 0.85);
                max-width: 464px;
                padding: 18px 12px;
                margin: 0 auto;

                h4 {
                    color: rgba(0, 0, 0, 0.8);
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                }

                .top-insure-grid-alignment {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 10px;
                    margin: 5px 0 0 0;
                }
            }
        }
    }
}