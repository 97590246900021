@import '../../styles/mixins/global.scss';

.company-contact-us-section {
    padding: 32px 0 86px 0;
    background-color: #f8f8f8;

    .company-contact-us-alignment {
        .company-contact-us-heading {
            h4 {
                color: rgba(0, 0, 0, 0.8);
                font-family: 'Poppins', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                  }
            }
        }

        .company-contact-us-top-details-alignment {
            margin: 42px 0 0 0;
            border-radius: 10px;
            background: #00AEEF;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            height: 252px;
            padding: 0 118px 0 72px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            @include breakpoint("max-sm") {
                height: 100%;   
            }
            @include breakpoint("max-md") {
                height: 100%;
                margin: 16px 0 0 0;
                padding: 16px;
                // flex-direction: column-reverse;
            }

            .compnay-contact-us-left-side-details {
                padding: 22px 0 42px 0;

                @include breakpoint("max-sm") {
                    padding: 16px 0;
                }

                h6 {
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                .company-contact-address-alignment {
                    display: flex;
                    align-items: flex-start;
                    gap: 14px;
                    // padding: 26px 0 0 0;

                    @include breakpoint("max-sm") {
                        padding: 10px 0 0 0;
                    }

                    p {
                        color: #FFF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        max-width: 348px;

                        @include breakpoint("max-sm") {
                            font-size: 12px;
                            line-height: 14px;
                            max-width: 100%;
                        }
                    }
                }

                .company-location-area-details-alignment {
                    // padding: 22px 0 0 8px;

                    @include breakpoint("max-sm") {
                        padding: 16px 0 0 0;
                    }

                    .company-location-area-flex {
                        display: flex;
                        align-items: center;
                        gap: 14px;
                        // padding: 0 0 21px 0;

                        @include breakpoint("max-sm") {
                            padding: 0 0 12px 0;
                            gap: 8px;
                        }

                        &:last-child {
                            padding: 0;
                        }

                        .fleg-img-alignment {
                            width: 34px;
                            height: 26px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                            }

                            @include breakpoint("max-sm") {
                                width: 20px;
                                height: 16px;
                            }
                        }

                        p {
                            color: #FFF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;

                            @include breakpoint("max-sm") {
                                font-size: 12px;
                                line-height: 14px;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }

            .company-contact-img {
                @include breakpoint("max-sm") {
                    max-width: 150px;
                    margin: 0 auto;
                }
            }
        }

        .company-contact-form-details-alignment {
            padding: 36px 0 0 0;

            @include breakpoint("max-sm") {
                padding: 16px 0 0 0;
            }

            .company-contact-form-box-alignment {
                padding: 32px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

                @include breakpoint("max-sm") {
                    padding: 16px;
                }

                h6 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                    }
                }

                .compnay-contact-form-alignment {
                    padding: 42px 0 0 0;

                    @include breakpoint("max-sm") {
                        padding: 24px 0 0 0;
                    }

                    .company-contact-form-grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 42px 20px;

                        @include breakpoint("max-sm") {
                            grid-template-columns: 1fr;
                            gap: 24px;
                        }

                        .company-contact-form-grid-item {
                            label {
                                color: var(--theme-heading-text-color);
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                display: block;
                                padding: 0 0 8px 0;
                            }

                            .contact-input {
                                input {
                                    width: 100%;
                                    height: 55px;
                                    border-radius: 5px;
                                    border: 1px solid rgba(96, 96, 96, 0.80);
                                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                                    color: rgba(0, 0, 0, 0.80);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    padding: 0 20px;

                                    @include breakpoint("max-sm") {
                                        height: 40px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .company-contact-message-supplier-alignment {
                        padding: 42px 0 0 0;

                        @include breakpoint("max-sm") {
                            padding: 24px 0 0 0;
                        }

                        label {
                            color: var(--theme-heading-text-color);
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: block;
                            padding: 0 0 8px 0;
                        }

                        .company-contct-textarea-alignment {

                            textarea {
                                width: 100%;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px solid rgba(96, 96, 96, 0.80);
                                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                                color: rgba(0, 0, 0, 0.80);
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                padding: 20px;


                                @include breakpoint("max-sm") {
                                    height: 140px;
                                    font-size: 14px;
                                    padding: 10px 20px;
                                }
                            }


                        }
                    }

                    .company-contact-button-alignment {
                        padding: 42px 0 0 0;
                        text-align: right;

                        @include breakpoint("max-sm") {
                            padding: 24px 0 0 0;
                        }

                        button {
                            padding: 14px 36px;
                            border-radius: 5px;
                            background: #00AEEF;
                            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                            color: #FFF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            cursor: pointer;
                            border: none;
                            @include breakpoint("max-sm") {
                                padding: 8px 18px;
                                font-size: 14px;
                            }
                        }
                    }
                }

            }
        }
    }
}