@import "../../styles/mixins/global.scss";

.truck-types-parameter-section {
  .truck-types-parameter-alignment {
    padding: 60px 0;

    @include breakpoint("max-sm") {
      padding: 30px 0;
    }

    .truck-types-parameter-heading {
      h4 {
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;

        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .truck-types-parameter-body-alignment {
      padding: 42px 0 0 0;

      .truck-types-parameter-box-alignment {
        border-radius: 10px;
        border: 1px solid #d0d0d0;
        background: #fff;
        box-shadow: 0px 0px 12px 0px rgba(202, 202, 202, 0.5);
        padding: 30px 42px;

        @include breakpoint("max-sm") {
          padding: 16px;
        }

        .truck-types-parameter-grid-alignment {
          display: grid;
          grid-template-columns: 260px 1fr;
          gap: 66px;

          @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
          }

          .truck-types-parameter-grid-item-alignment {
            h6 {
              color: #000;
              font-family: "Poppins", sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            
            
            }

            .truck-types-parameter-img {
              margin: 95px 0 0 0;
              // width: 100%;
              // height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
              }
            }

            .truck-types-parameter-right-side-alignment {
              display: grid;
              grid-template-columns: 135px 1fr;
              gap: 55px;


              @include breakpoint("max-sm") {
                gap: 20px;
              }

              .child-left-side-alignment {
                p {
                  opacity: 0.7;
                  color: rgba(0, 0, 0, 0.7);
                  font-family: "Poppins", sans-serif;
                  font-size: 16px;
                  cursor: pointer;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  padding: 0 0 14px 0;

                  @include breakpoint("max-sm") {
                    font-size: 14px;
                  }

                  &:nth-of-type(odd) {
                    color: #000;
                  }

                  &:last-child {
                    padding: 0;
                  }
                }
              }

              .child-right-side-alignment {
                h6 {
                  color: #000;
                  font-family: "Poppins", sans-serif;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  padding: 0 0 26px 0;

                  @include breakpoint("max-sm") {
                    font-size: 14px;
                  } 
                }

                p {
                  color: #000;
                  font-family: "Poppins", sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}
