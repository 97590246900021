@import "../../../styles/mixins/global.scss";

.home-new-testimonials-section {
    padding: 132px 0 0 0;
    overflow-x: hidden;
    
    @include breakpoint("max-sm") {
        padding: 0 0 60px 0;
    }

    .home-new-testimonials-heading {
        h2 {
            color: #FFF;
            font-family: 'Poppins', sans-serif;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;

            @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .home-new-testimonials-body-alignment {
        padding: 60px 0 0 0;

        .second-slider-new-terminal-alignment {
            padding: 52px 0 0 0;


            @include breakpoint("sm-max") {
                padding: 20px 0 0 0;
            }
        }

        .home-new-testimonials-padding {
            padding: 0 8px;

            .home-new-testimonials-box-alignment {
                width: 100%;
                border-radius: 15px;
                border: 1px solid #00AEEF;
                background: rgba(249, 249, 249, 0.10);
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                transition: 0.4s all;
                padding: 20px 24px 24px 24px;
                display: flex;
                min-height: 157px;
                align-items: flex-start;
                gap: 22px;

                .home-new-testimonials-profile-img {
                    width: 75px;
                    min-width: 75px;
                    max-width: 75px;
                    height: 75px;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                        display: block;
                    }
                }

                .home-new-teminals-details-alignment {
                    h6 {
                        color: #FFF;
                        font-family: 'Open Sans';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    span {
                        color: #FFF;
                        font-family: 'Open Sans';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 12px 0 0 0;
                        display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;


                        @include breakpoint("max-sm") {
                            min-height: auto;
                        }
                    }
                }



            }
        }
    }
}