@import "../../styles/mixins/index.scss";

.shipping-lines-section {
    padding: 42px 0;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .shipping-lines-alignment {
        .shipping-lines-heading {
            text-align: center;

            h4 {
                color: #000;
                font-family: 'Poppins', sans-serif;
                font-size: 42px;
                font-weight: 600;
                line-height: normal;
                opacity: 0.8;
                padding: 28px 0 0 0;
            }
        }

        .shipping-lines-body-alignment {
            padding: 56px 0 0 0;

            .shipping-lines-box-alignment {
                border-radius: 10px;
                background: #F5FCFF;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 26px 54px;

                .shipping-lines-box-heading {
                    h6 {
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .shipping-lines-box-details {
                    padding: 34px 0 0 0;

                    .shipping-lines-grid-alignment {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 16px 72px;

                        @include breakpoint("max-sm") {
                            grid-template-columns: repeat(2, 1fr) !important;
                            gap: 10px;
                        }

                        @include breakpoint("max-md") {
                            grid-template-columns: repeat(3, 1fr);
                            gap: 10px;
                        }

                        .shipping-lines-grid-item {


                            p {
                                color: #000;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: uppercase;
                            }
                        }
                    }


                }
            }

            .shipping-lines-bottom-alignment {
                padding: 65px 16px 65px 38px;

                .shipping-lines-second-box-alignment {
                    border-radius: 10px;
                    background: #F5FCFF;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                    padding: 16px 16px 40px 16px;

                    .shipping-lines-second-heading {
                        h6 {
                            color: #000;
                            font-family: 'Poppins', sans-serif;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            opacity: 0.8;
                        }
                    }

                    .shipping-lines-second-body-alignment {
                        padding: 16px 0 0 0;

                        .shipping-lines-second-grid-alignment {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            gap: 16px 72px;

                            @include breakpoint("max-sm") {
                                grid-template-columns: repeat(2, 1fr) !important;
                                gap: 10px;
                            }

                            @include breakpoint("max-md") {
                                grid-template-columns: repeat(3, 1fr);
                                gap: 10px;
                            }

                            .shipping-lines-second-grid-item {
                                p {
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }

                .shipping-lines-other-details-alignment {
                    padding: 45px 0 0 0;

                    .shipping-lines-other-child-box {
                        border-radius: 10px;
                        background: #F5FCFF;
                        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                        padding: 20px 28px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 0 32px 0;
                        flex-wrap: wrap;

                        &:last-child {
                            margin: 0;
                        }

                        .get-started-button {
                            button {
                                border-radius: 10px;
                                background: #00AEEF;
                                border: none;
                                color: #FFF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                padding: 14px 34px;
                                cursor: pointer;
                            }
                        }

                        .shipping-lines-left-side-alignment {
                            display: flex;
                            align-items: flex-start;
                            gap: 28px;

                            .shipping-icon-box-alignment {}

                            .shipping-other-details-alignment {
                                h6 {
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    padding: 0 0 14px 0;
                                }

                                p {
                                    color: #000;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    max-width: 753px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}