@import "../../../styles/mixins/global.scss";

.make-customs-easy-section {
    .make-customs-easy-flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .make-customs-child-details {
            flex: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .make-customs-easy-box {
                width: 260px;
                height: 220px;
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            h6 {
                padding: 28px 0 0 0;
                color: #000;
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                text-align: center;
            }
        }
    }
}