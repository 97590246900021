.container {
    padding: 26px 16px !important;

    .ContainerGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 16px;
        margin-top: 86px;

        .MessageGrid {
            width: 500px;
            margin: 0 auto !important;

            h2 {
                font-family: Montserrat;
                font-size: 36px;
                font-weight: 600;
                line-height: 43.88px;
                text-align: left;
                color: #042953;
            }

            .InfoMessageText {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
                color: #000000;
            }
        }

        .ContainerImage {
            position: relative;
            width: 100%;
            height: 500px;
            margin-left: -46px;

            .IMAGE1 {
                width: 350px;
                height: 300px;
                top: 50px;
                left: 0px;
                gap: 0px;
                opacity: 0px;
                position: absolute;
                z-index: 999;
            }

            .IMAGE2 {
                width: 290px;
                height: 250px;
                top: -70px;
                left: 315px;
                gap: 0px;
                opacity: 0px;
                position: absolute;
            }

            .IMAGE3 {
                width: 250px;
                height: 240px;
                top: 150px;
                left: 362px;
                gap: 0px;
                opacity: 0px;
                position: absolute;
            }

        }
    }
}

.bgouterContainer {
    width: 100%;
    gap: 0px;
    opacity: 0px;
    background-color: #E1F7FF;

    .informationDetails {
        h2 {
            font-family: Montserrat;
            font-size: 32px;
            font-weight: 600;
            line-height: 39.01px;
            text-align: left;
            color: #000000;
            padding-top: 16px;
        }

        div {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            line-height: 21.94px;
            text-align: left;
            color: #3E3E3E;
            padding-right: 32px;
            padding-top: 16px;
        }
    }

    .helplinedetailsalign {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        gap: 32px;
        margin-top: 32px;

        img {
            width: 62px;
            height: 62px;
            gap: 0px;
            opacity: 0.8px;
            cursor: pointer;
        }

        h4 {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            color: #000000;
            margin-top: -74px;
        }
    }

    .InformationContainer {
        padding: 16px 99px 56px 99px;

        h1 {
            font-family: Montserrat;
            font-size: 32px;
            font-weight: 600;
            line-height: 39.01px;
            color: #000000;
            padding-top: 32px;
        }

        .InformationGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;

            div {
                h4 {
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.94px;
                    text-align: left;
                    color: #000000;
                }

                div {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.5px;
                    text-align: left;
                    color: #000000;

                }
            }
        }
    }
}

.ContainerTowGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center !important;
    gap: 16px;

    .GridBoxContainer {
        width: 367px;
        margin: 0 auto !important;
        border-radius: 123px 123px 0px 0px;
        text-align: center;

        .imagegrid {
            border: 1px solid #4547EC;
            border-radius: 123px 123px 0px 0px;
            padding: 16px;
            background: #EDF2FF;
            height: 232px;
            margin-top: 64px;

            .img {
                width: 293.84px;
                height: 195.7px;
                top: 1222px;
                left: 287px;
                gap: 0px;
                opacity: 0px;
            }
        }

        .detailsinfo {
            border: 1px solid #00000080;
            border-radius: 0px;
            background-color: #fff;
            width: 100%;
            height: 240px;
            padding: 8px 16px;
            border-top: none;
            margin-bottom: 16px;

            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                color: #042953;
                margin-top: 16px;
            }

            .message {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.94px;
                text-align: center;
                color: #000000;
            }
        }
    }
}

.ContainerThreeGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center !important;
    gap: 5px;

    .GridBoxContainer {
        width: 367px;
        text-align: center;
        .imagegrid {
            border: 1px solid #4547EC;
            border-radius: 123px 123px 0px 0px;
            padding: 16px;
            background: #EDF2FF;
            height: 232px;
            margin-top: 64px;
            .img {
                width: 293.84px;
                height: 195.7px;
                top: 1222px;
                left: 287px;
                gap: 0px;
                opacity: 0px;
            }
        }

        .detailsinfo {
            border: 1px solid #00000080;
            border-radius: 0px;
            background-color: #fff;
            width: 100%;
            height: 240px;
            border-top: none;
            padding: 8px 16px;
            margin-bottom: 16px;

            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                color: #042953;
                margin-top: 16px;
            }

            .message {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.94px;
                text-align: center;
                color: #000000;
            }
        }
    }
}



// Define breakpoint variables
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@media (max-width: $breakpoint-xs) {
    .container {
        padding: 16px !important;

        .ContainerGrid {
            display: block !important;
            margin-top: 32px !important;

            .MessageGrid {
                width: 100% !important;
                margin-bottom: 76px !important;
            }

            .ContainerImage {
                width: 100% !important;
                height: 400px;
                margin-left: 0px;

                .IMAGE1 {
                    width: 230px;
                    height: 200px;
                }

                .IMAGE2 {
                    width: 180px;
                    height: 180px;
                    top: -70px;
                    left: 150px;
                }

                .IMAGE3 {
                    width: 180px;
                    height: 170px;
                    top: 100px;
                    left: 158px;
                }

            }
        }
    }

    .bgouterContainer {
        .informationDetails {
            h2 {
                font-size: 22px !important;
            }
        }

        .helplinedetailsalign {
            display: block;

            h4 {
                font-size: 18px !important;
            }
        }

        .InformationContainer {
            padding: 16px !important;

            h1 {
                font-size: 26 !important;
                font-weight: 600;
            }

            .InformationGrid {
                display: block !important;

                div {
                    h4 {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }

    .ContainerTowGrid {
        display: block !important;

        .GridBoxContainer {
            width: 100% !important;
            margin-top: 16px !important;
        }
    }

    .ContainerThreeGrid {
        display: block !important;

        .GridBoxContainer {
            width: 100% !important;

            .imagegrid {
                margin-top: 16px !important;
            }
        }
    }
}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .container {
        padding: 16px !important;

        .ContainerGrid {
            display: block !important;
            padding-top: 0px !important;

            .MessageGrid {
                width: 70% !important;
                padding-bottom: 100px !important;
            }

            .ContainerImage {
                margin: 0 auto !important;
                .IMAGE1 {
                    width: 350px;
                    height: 300px;
                }

                .IMAGE2 {
                    width: 280px !important;
                    height: 220px;
                    top: -70px;
                    left: 290px;
                }

                .IMAGE3 {
                    width: 280px;
                    height: 220px;
                    top: 140px;
                    left: 300px;
                }

            }
        }
    }



    .ContainerTowGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .GridBoxContainer {
            width: 100% !important;
            margin-top: 16px !important;
        }
    }

    .ContainerThreeGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .GridBoxContainer {
            width: 100% !important;

            .imagegrid {
                margin-top: 16px !important;
            }
        }
    }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .container {
        padding: 16px !important;

        .ContainerGrid {
            display: block !important;
            padding-top: 0px !important;

            .MessageGrid {
                width: 70% !important;
                padding-bottom: 100px !important;
            }

            .ContainerImage {
                margin: 0 auto !important;
                .IMAGE1 {
                    width: 350px;
                    height: 300px;
                }

                .IMAGE2 {
                    width: 280px !important;
                    height: 220px;
                    top: -70px;
                    left: 290px;
                }

                .IMAGE3 {
                    width: 280px;
                    height: 220px;
                    top: 140px;
                    left: 300px;
                }

            }
        }
    }



    .ContainerTowGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .GridBoxContainer {
            width: 100% !important;
            margin-top: 16px !important;
        }
    }

    .ContainerThreeGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .GridBoxContainer {
            width: 100% !important;

            .imagegrid {
                margin-top: 16px !important;
            }
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .container {
        padding: 0px 16px !important;
        .ContainerGrid {
            gap: 10px;
            .MessageGrid {
                width: 380px;    
            }
    
            .ContainerImage {
                margin-left: -36px;
    
                .IMAGE1 {
                    width: 300px;
                    height: 280px;
                }
    
                .IMAGE2 {
                    width: 250px;
                    height: 200px;
                    left: 180px;
                }
    
                .IMAGE3 {
                    width: 250px;
                    height: 220px;
                    top: 120px;
                    left: 210px;
                }
    
            }
        }
    }
    
    .bgouterContainer {
        width: 100%;
        gap: 0px;
        opacity: 0px;
        background-color: #E1F7FF;
    
        .informationDetails {
            h2 {
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                line-height: 39.01px;
                text-align: left;
                color: #000000;
                padding-top: 16px;
            }
    
            div {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                line-height: 21.94px;
                text-align: left;
                color: #3E3E3E;
                padding-right: 32px;
                padding-top: 16px;
            }
        }
    
        .helplinedetailsalign {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            gap: 32px;
            margin-top: 32px;
    
            img {
                width: 62px;
                height: 62px;
                gap: 0px;
                opacity: 0.8px;
                cursor: pointer;
            }
    
            h4 {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #000000;
                margin-top: -74px;
            }
        }
    
        .InformationContainer {
            padding: 16px 99px 56px 99px;
    
            h1 {
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 600;
                line-height: 39.01px;
                color: #000000;
                padding-top: 32px;
            }
    
            .InformationGrid {
                display: grid;
                grid-template-columns: 1fr 1fr;
    
                div {
                    h4 {
                        font-family: Montserrat;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 21.94px;
                        text-align: left;
                        color: #000000;
                    }
    
                    div {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19.5px;
                        text-align: left;
                        color: #000000;
    
                    }
                }
            }
        }
    }
    
    .ContainerTowGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center !important;
        gap: 16px;
        margin-top: 64px;
    
        .GridBoxContainer {
            width: 367px;
            margin: 0 auto !important;
            border-radius: 123px 123px 0px 0px;
            text-align: center;
    
            .imagegrid {
                border: 1px solid #4547EC;
                border-radius: 123px 123px 0px 0px;
                padding: 16px;
                background: #EDF2FF;
                height: 232px;
    
                .img {
                    width: 293.84px;
                    height: 195.7px;
                    top: 1222px;
                    left: 287px;
                    gap: 0px;
                    opacity: 0px;
                }
            }
    
            .detailsinfo {
                border: 1px solid #00000080;
                border-radius: 0px;
                background-color: #fff;
                width: 100%;
                height: 240px;
                padding: 8px 16px;
                border-top: none;
    
                h3 {
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 34.13px;
                    color: #042953;
                    margin-top: 16px;
                }
    
                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.94px;
                    text-align: center;
                    color: #000000;
                }
            }
        }
    }
    
    .ContainerThreeGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center !important;
        gap: 5px;
        margin-top: 64px;
    
        .GridBoxContainer {
            width: 367px;
            margin: 0 auto !important;
            border-radius: 123px 123px 0px 0px;
            text-align: center;
    
            .imagegrid {
                border: 1px solid #4547EC;
                border-radius: 123px 123px 0px 0px;
                padding: 16px;
                background: #EDF2FF;
                height: 232px;
    
                .img {
                    width: 293.84px;
                    height: 195.7px;
                    top: 1222px;
                    left: 287px;
                    gap: 0px;
                    opacity: 0px;
                }
            }
    
            .detailsinfo {
                border: 1px solid #00000080;
                border-radius: 0px;
                background-color: #fff;
                width: 100%;
                height: 240px;
                border-top: none;
                padding: 8px 16px;
    
                h3 {
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 34.13px;
                    color: #042953;
                    margin-top: 16px;
                }
    
                .message {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.94px;
                    text-align: center;
                    color: #000000;
                }
            }
        }
    }
    
}