:root {

    --primary-font: 'Poppins', sans-serif;

    --body-background-color: #EEEFF3;

    --theme-white-color: #fff;
    --theme-card-white-color: #fff;

    --theme-text-black-300: #343434;
    --theme-text-black-100: #010101;
    --theme-text-gray-500: #515151;
    --theme-link-text-color: #00AEEF;

    --theme-header-text-hover-color: #00AEEF;

    --theme-icon-color: #00AEEF;

    --theme-button-primary-background-color: #00AEEF;
    --theme-button-primary-text-color: #fff;
    --theme-button-primary-outline-color: #00AEEF;
    --theme-button-primary-outline-text-color: #00AEEF;

    --theme-heading-text-color: #000;
}