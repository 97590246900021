@import "../../../styles/mixins/global.scss";


.route-planner-map {
  position: relative;
  iframe {
    border: none;
    width: 100%;
    height: 500px;
  }

  .white-box {
    background: #ffffff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 54px 160px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    @include breakpoint("max-sm") {
      width: calc(100% - 20px);
      padding: 40px;
    }

    .icon-center {
      display: flex;
      justify-content: center;
      padding: 0 0 24px 0;
      align-items: center;
    }

    .btn-center {
      display: flex;
      padding: 20px 0 0 0;
      justify-content: center;

      button {
        border: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        background: #00aeef;
        border-radius: 4px;
        color: #ffffff;
        padding: 11px 16px;
      }
    }

    h6 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--theme-heading-text-color);
      text-align: center;
      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
