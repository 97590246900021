@import "../../../styles/mixins/global.scss";

.api-container-tracking-section {
    padding: 60px 0 0 0;

    @include breakpoint("max-md") {
        padding: 60px 60px 0 60px;
    }

    @include breakpoint("max-sm") {
        padding: 30px 0 0 0;
    }

    .api-container-tracking-grid {
        display: grid;
        grid-template-columns: 1fr 600px;
        gap: 107px;
        align-items: center;

        @media only screen and (max-width:"1099px") {
            grid-template-columns: 1fr 500px;
            gap: 70px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: 1fr;
            gap: 20px;
        }

        .api-container-tracking-gridItem {
            &:first-child {
                @include breakpoint("max-md") {
                    order: 2;
                }
            }

            &:last-child {
                @include breakpoint("max-md") {
                    order: 1;
                }
            }

            .api-container-box {
                border-radius: 8px;
                background: #FFF;
                box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.12);
                height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .api-container-details-alignment {
                h5 {
                    color: #343434;
                   font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;

                    @include breakpoint("max-md") {
                        text-align: center;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                p {
                    padding: 24px 0 0 0;
                    color: #343434;
                   font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    opacity: 0.5;
                    max-width: 398px;


                    @include breakpoint("max-md") {
                        max-width: 100%;
                        text-align: center;
                    }

                    @include breakpoint("max-sm") {
                        padding: 16px 0 0 0;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}