@import "../../../styles/mixins/global.scss";

.lsq-other-details-section {
    padding: 5px 0 76px 0;

    .lsq-other-details-tab-alignment {
        padding: 0 30px 12px 30px;
        display: flex;
        align-items: center;
        gap: 72px;

        p {
            color: #000000;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            cursor: pointer;
        }
    }

    .lsq-other-details-box-alignment {
        background: #FFF;
        width: 100%;

        .lsq-other-details-box-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1px;
            padding: 14px 18px 6px 20px;
            border-bottom: 1px solid #000;

            &:last-child {
                border-bottom: none;
            }

            .lsq-other-details-box-grid-item {
                border-right: 1px solid #000;

                &:last-child {
                    border-right: 0;
                }

                .lsq-other-details-box-child {
                    padding: 14px 16px;
                    border: 1px solid #FFF;
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    h6 {
                        color: #F00;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                    }


                    .lsq-other-child-details-alignment {
                        padding: 28px 0 0 0;

                        &.second-alignment {
                            padding: 4px 0 0 0;
                        }

                        .lsq-other-child-grid {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 16px;

                            .lsq-other-name-alignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                span {
                                    color: #636363;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 14px;
                                }

                                p {
                                    color: #000;
                                    font-size: 12px;
                                    font-weight: 700;
                                    line-height: 14px;
                                    margin-bottom: 0px !important;
                                }
                            }
                        }
                    }

                    .lsq-other-details-notes {
                        padding: 12px 0 0 0;

                        p {
                            color: #000;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14px;
                        }
                    }

                    .gold-medal-box-details {
                        padding: 4px 0 0 0;
                    }

                    .lsq-upgrade-button {
                        padding: 25px 0 0 0;

                        button {
                            border-radius: 10px;
                            border: 1px solid #00F;
                            padding: 10px 12px;
                            color: #00F;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14px;
                            background: transparent;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .lsq-check-more-button-alignment {
        padding: 40px 0 0 0;
        text-align: center;

        button {
            padding: 10px;
            border-radius: 10px;
            background: #00F;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            border: none;
            cursor: pointer;
        }
    }
}