@import "../../styles/mixins/index.scss";

.quility-section {
    background: #FFF;
    padding: 16px 0;

    .bordercolor {
        border: 1px solid #00AEEF;
        margin-bottom: -56px;
        margin-top: 32px;
    }

    .bordercolorFlex {
        border-bottom: 1px solid #00AEEF;
        text-align: right;
        margin-left: -110px;
        margin-top: -155px !important;
        margin-right: 99px;

        span {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            color: #000000;
            padding-right: 16px;
            cursor: pointer;
        }
    }

    .quility-banner {
        .quility-banner-grid {
            display: grid;
            grid-template-columns: 1fr 750px;
            gap: 24px;
            align-items: center;

            @include breakpoint("max-sm") {
                grid-template-columns: 1fr;
                gap: 24px;
            }

            .quilitymessage-box {
                position: relative;
                width: 628px;
                height: 546px;

                .addtext-messagebox {
                    position: absolute;
                    top: 200px;
                    left: 110px;
                    rotate: -10deg;
                }

                .Aazikp-quility-title {
                    font-family: Montserrat;
                    font-size: 42px;
                    font-weight: 600;
                    line-height: 51.2px;
                    text-align: center;
                    color: #000000;
                }
            }

            .on-site-banner-left-side-alignment {
                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    opacity: 0.8;
                    font-size: 70px;
                    font-weight: 600;
                    line-height: 85.33px;
                    width: 500px;
                    text-align: left;
                    color: #042953;

                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        width: 300px !important;
                        line-height: 30px !important;

                    }
                }

                p {
                    padding: 22px 0 0 0;
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        padding: 8px 0 0 0;
                    }
                }
            }
        }
    }


}

.traferentbg {
    background: #00000066;
    opacity: 5px;
    width: 100% !important;
    padding: 16px 0px;

    .quilityTowGridtrasferentbg {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        .BoxDesignbg {
            width: 560px;

            div {
                img {
                    width: 100%;
                    height: 216.84px;
                    top: 29.85px;
                    left: 58.75px;
                    gap: 0px;
                    opacity: 0px;
                    border: 1px solid #e0e0e0;
                    border-radius: 10px;
                }
            }

            div {
                h3 {
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 34.13px;
                    text-align: left;
                    color: #000000;
                    padding: 16px 0px;
                }

                div {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21.94px;
                    text-align: left;
                    color: #000000;
                    padding: 16px;
                }
            }
        }
    }
}

.quilityTowGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    .BoxDesign {
        width: 560px;
        height: 533px;
        top: 1277px;
        left: 150px;
        gap: 0px;
        border-radius: 15px;
        border: 1px;
        opacity: 0px;
        border: 1px solid #00AEEF;
        box-shadow: 0px 0px 4px 0px #00000040;
        background: #F2FBFF80;
        padding: 16px;

        div {
            img {
                width: 176.69px;
                height: 216.84px;
                top: 29.85px;
                left: 58.75px;
                gap: 0px;
                opacity: 0px;

            }
        }

        div {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 500;
            line-height: 21.94px;
            text-align: center;
            color: #000000;
            padding: 16px 16px;

            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                text-align: center;
                color: #000000;
                padding-top: 26px;
            }
        }

    }
}

.spaccingalign {
    margin-top: 64px;

    .quilityTowgridbox {
        display: grid;
        grid-template-columns: 1fr 510px;
        align-items: center;

        div {
            padding-right: 99px;

            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                text-align: left;
                color: #000000;
                padding-bottom: 16px;
            }

            span {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 500;
                line-height: 21.94px;
                text-align: left;
                color: #000000;
            }
        }

        div {
            img {
                width: 407px;
                height: 316px;
                top: 2228px;
                left: 1290px;
                gap: 0px;
                border: 3px 0px 0px 0px;
                opacity: 0px;
            }
        }
    }

    .quilityTowgridboxReturn {
        display: grid;
        grid-template-columns: 510px 1fr;
        align-items: center;

        div {
            img {
                width: 407px;
                height: 316px;
                top: 2228px;
                left: 1290px;
                gap: 0px;
                border: 3px 0px 0px 0px;
                opacity: 0px;
            }
        }

        div {
            padding-right: 99px;

            h3 {
                font-family: Montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 34.13px;
                text-align: left;
                color: #000000;
                padding-bottom: 16px;
            }

            span {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 500;
                line-height: 21.94px;
                text-align: left;
                color: #000000;
            }
        }
    }

    .GetStartBox {
        width: 794px;
        height: 350px;
        border-radius: 15px;
        background: #F2FBFF;
        box-shadow: 0px 0px 8px 3px #1C1C1C40;
        margin: 0 auto !important;
        padding: 32px 46px;

        h2 {
            color: #042953;
            font-family: Montserrat;
            font-size: 45px;
            font-weight: 600;
            line-height: 54.86px;
            text-align: center;
            margin-top: 32px;

        }

        div {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
            line-height: 24.38px;
            text-align: center;
            margin-top: 16px;
            margin-bottom: 32px;
        }

        button {
            width: 150px;
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 600;
            line-height: 26.82px;
            text-align: center;
            color: #fff;
            background: #F48120;
            border: none;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            justify-content: center;
            margin: 0 auto !important;
        }
    }
}



// Responsive Scss Add Here...
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@media (max-width: $breakpoint-xs) {
    .quility-section {
        .bordercolor {
            margin-bottom: 1px !important;
            height: 100% !important;
        }

        .bordercolorFlex {
            width: 90% !important;
            margin-left: 16px !important;
            margin-top: -200px !important;
            text-align: center !important;
            display: flex !important;
            justify-content: center !important;
        }

        .quility-banner {
            .quility-banner-grid {
                display: block !important;

                .quilitymessage-box {
                    width: 100% !important;

                    img {
                        width: 100% !important;
                    }

                    .addtext-messagebox {
                        top: 120px !important;
                        left: 60px !important;
                    }

                    .Aazikp-quility-title {
                        font-size: 26px !important;
                        line-height: 30px !important;
                    }
                }

            }
        }
    }

    .traferentbg {
        .quilityTowGridtrasferentbg {
            display: block !important;

            .BoxDesignbg {
                width: 100% !important;

                div {
                    padding-top: 32px !important;
                }
            }
        }
    }

    .quilityTowGrid {
        display: block !important;

        .BoxDesign {
            width: 100% !important;
            height: 100% !important;
            margin-top: 16px !important;
        }
    }

    .spaccingalign {
        .quilityTowgridbox {
            display: block !important;

            div {
                padding-right: 0px !important;
                margin-bottom: 16px !important;
            }

            div {
                margin: 0 auto !important;
            }
        }

        .quilityTowgridboxReturn {
            display: block !important;

            div {
                padding-right: 0px !important;
                margin-bottom: 16px !important;
            }
        }

        .GetStartBox {
            width: 100% !important;
            height: 100% !important;
            padding: 32px 16px !important;
        }
    }
}


@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    .quility-section {
        .bordercolorFlex {
            margin-left: 0px !important;
            margin-top: 0px !important;
            margin-right: 0px !important;
        }
    
        .quility-banner {
            .quility-banner-grid {
                display: block !important;
            }
        }
    
    
    }
    
    .traferentbg {
        width: 100% !important;
        .quilityTowGridtrasferentbg {
            gap: 10px !important;
            .BoxDesignbg {
                width: 100% !important;
            }
        }
    }
    
    .quilityTowGrid {
        gap: 10px !important;
    
        .BoxDesign {
            width: 100% !important;
            height: 600px !important;
        }
    }
    
    .spaccingalign {
        .quilityTowgridbox {
            grid-template-columns: 1fr 250px !important;
            div {
                padding-right: 0px !important;
                padding-left: 16px !important;
            }
        }
        .quilityTowgridboxReturn {
            grid-template-columns: 250px 1fr !important;
            div {
                padding-right: 0px !important;
                padding-left: 16px !important;
            }
        }
        .GetStartBox {
            width: 100% !important;
        }
    }
    
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .quility-section {
        .bordercolorFlex {
            margin-left: 0px !important;
            margin-top: 0px !important;
            margin-right: 0px !important;
        }
    
        .quility-banner {
            .quility-banner-grid {
                display: block !important;
            }
        }
    
    
    }
    
    .traferentbg {
        width: 100% !important;
        .quilityTowGridtrasferentbg {
            gap: 10px !important;
            .BoxDesignbg {
                width: 100% !important;
            }
        }
    }
    
    .quilityTowGrid {
        gap: 10px !important;
    
        .BoxDesign {
            width: 100% !important;
            height: 600px !important;
        }
    }
    
    .spaccingalign {
        .quilityTowgridbox {
            grid-template-columns: 1fr 250px !important;
            div {
                padding-right: 0px !important;
                padding-left: 16px !important;
            }
        }
        .quilityTowgridboxReturn {
            grid-template-columns: 250px 1fr !important;
            div {
                padding-right: 0px !important;
                padding-left: 16px !important;
            }
        }
        .GetStartBox {
            width: 100% !important;
        }
    }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .quility-section {
        .bordercolorFlex {
            margin-left: -110px !important;
            margin-top: -188px !important;
            margin-right: 0px !important;
    
            span {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
                color: #000000;
                padding-right: 16px;
                cursor: pointer;
            }
        }
        p{
            width: 400px !important;
        }
    
        .quility-banner {
            .quility-banner-grid {
                grid-template-columns: 1fr 450px !important;
                gap: 16px !important;
    
                .quilitymessage-box {
                    width: 500px !important;
                    .addtext-messagebox {
                        top: 170px !important;
                        left: 88px;
                    }
                    .Aazikp-quility-title {
                        font-size: 36px !important;
                        line-height: 40.2px !important;
                    }
                }
            }
        }
    
    
    }
    
    .traferentbg {
        .quilityTowGridtrasferentbg {
            gap: 16px !important;
    
            .BoxDesignbg {
                width: 100% !important;
            }
        }
    }
    
    .quilityTowGrid {
        gap: 16px !important;
        .BoxDesign {
            width: 100% !important;
            gap: 16px !important;
        }
    }
    
    .spaccingalign {
        .quilityTowgridbox {
          margin: 0 auto !important;
            div {
                padding-right: 32px !important;
            }
        }
    
        .quilityTowgridboxReturn {
          
    
            div {
                padding-right: 32px !important;
            }
        }
    
        .GetStartBox {
            width: 100% !important; 
        }
    }
}