@import "../../styles/mixins/global.scss";

// .gallery-page-contnet-banner {
//     padding: 80px 0;
//     background: rgba(0, 174, 239, 0.1);

//     h1 {
//         @include heading-1;
//         color: var(--theme-text-black-300);
//         @include text-center;
//     }
// }

// .gallery-all-contnet-view-alignment {
//     padding: 80px 0;

//     .tab {
//         @include flex-center;

//         @include breakpoint("max-md") {
//             white-space: nowrap;
//             overflow-x: auto;
//             padding: 0 0 20px 0;
//         }
//         // span:first-child {
//         //     color: var(--theme-link-text-color);
//         // }
//         .active {
//             color: var(--theme-link-text-color);
//         }

//         span {
//             display: block;
//             @include medium16;
//             font-size: 20px;
//             padding-right: 25px;
//             @include pointer;
//             color: var(--theme-text-black-300);
//             transition: .3s ease-in-out;

//             &:hover {
//                 color: var(--theme-link-text-color);
//                 transition: .3s ease-in-out;
//             }
//         }

//     }

//     .grid {
//         padding: 60px 0 0 0;
//         display: grid;
//         grid-template-columns: repeat(3, 1fr);
//         gap: 30px;

//         @include breakpoint("max-md") {
//             grid-template-columns: repeat(2, 1fr);
//             gap: 20px;
//         }

//         @include breakpoint("max-sm") {
//             grid-template-columns: repeat(1, 1fr);
//             gap: 20px;
//         }

//         .grid-items {
//             .image-style {
//                 img {
//                     width: 100%;
//                     height: 360px;
//                     object-fit: cover;
//                 }
//                 iframe{
//                     height: 360px;
//                     width: 360px;

//                 }
//             }

//             .cardDetails {
//                 background: rgba(196, 196, 196, 0.1);
//                 margin-top: -4px;
//                 padding: 12px;
//                 border-radius: 0 0 8px 8px;

//                 p {
//                     @include text-sm;
//                     color: #606060;
//                     display: -webkit-box;
//                     -webkit-line-clamp: 3;
//                     -webkit-box-orient: vertical;
//                     overflow: hidden;
//                 }

//                 .boxTitleAlignment {
//                     @include flex-center;
//                     // padding: 12px;
//                     justify-content: space-between;

//                     .moreIcon {
//                         position: relative;
//                     }

//                     .popupHidden {
//                         max-height: 0px;
//                         overflow: hidden;
//                         transition: .3s ease-in-out;
//                     }

//                     .popupShow {
//                         max-height: calc(100vh - 150px);
//                         transition: .3s ease-in-out;
//                         overflow: hidden;
//                     }

//                     .popup {
//                         position: absolute;
//                         right: 0;
//                         min-width: 82px;
//                         z-index: 999;

//                         .popupDesign {
//                             box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);
//                             border-radius: 4px;
//                             padding: 12px;
//                             background-color: #FFF;

//                             ul {
//                                 list-style: none;
//                                 li {
//                                     @include text-sm;
//                                     color: #343434;
//                                     padding: 0 0 12px 0;
//                                     transition: .3s ease-in-out;
//                                     @include pointer;

//                                     &:hover {
//                                         color: #00AEEF;
//                                     }
//                                 }

//                                 li:last-child {
//                                     padding: 0;
//                                 }
//                             }
//                         }
//                     }

//                     div {
//                         h2 {
//                             @include text-md-bold;
//                             color: #343434;
//                             margin: 0;
//                         }

//                         img {
//                             width: 3px;
//                             object-fit: initial;
//                             height: 16px;
//                             @include pointer;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// .loader5 {
//     border: 16px solid #f3f3f3;
//     /* Light grey */
//     border-top: 16px solid #3498db;
//     /* Blue */
//     border-radius: 50%;
//     width: 120px;
//     height: 120px;
//     animation: spin 2s linear infinite;
// }

// @keyframes spin {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(360deg);
//     }
// }

// .felxMyitem {
//     display: flex;
//     justify-content: center;
//     padding: 5%
// }

.gallery-page-details-alignment {
  .gallery-top-heading-alignment {
    border-radius: 0px 0px 30px 30px;
    background: #0d1232;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 373px;
    height: 121px;
    margin: 0 auto;
    position: relative;

    @include breakpoint("max-sm") {
      height: auto;
      width: 100%;
      padding: 15px;
    }
    h1 {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 52px;
      font-style: normal;
      font-weight: 800;
      line-height: 86%;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }

    // &::before {
    //   position: absolute;
    //   content: '';
    //   width: 314px;
    //   height: 314px;
    //   background: radial-gradient(50.00% 50.00% at 50.00% 50.00%, #E3ECF2 0%, #D5DDE3 2.00%, #A2A9AD 11.00%, #777C7F 21.00%, #535759 30.00%, #383A3C 39.00%, #242627 48.00%, #181A1B 57.00%, #151617 65.00%, #121314 66.00%, #070808 71.00%, #010101 79.00%, #000 100%);
    //   top: 0;
    //   right: 0;
    //   z-index: 99;
    // }
  }

  .gallery-tab-alignment {
    padding: 28px 0 0 0;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 22px;

      @include breakpoint("max-sm") {
        gap: 16px;
      }

      li {
        border-radius: 10px;
        background: #0d1232;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 86%;
        padding: 9px 26px;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        @include breakpoint("max-sm") {
          font-size: 14px;
          padding: 9px 20px;
        }
        &:hover {
          border-radius: 10px;
          border: 1px solid #000;
          background: #fff;
          color: #0d1232;
          transition: 0.4s ease-in-out;
        }

        &.active-tab {
          border-radius: 10px;
          border: 1px solid #000;
          background: #fff;
          color: #0d1232;
        }
      }
    }
  }

  .gallery-all-page-details-alignment {
    padding: 72px 0;

    @include breakpoint("max-sm") {
      padding: 40px 0;
    }
  }
}
