@import '../../../styles/mixins/global.scss';

.associated-section-all-contnet-alignment {
    padding: 70px 0;
    background: rgba(226, 242, 249, 0.5);

    .page-title {
        padding: 0 0 60px 0;

        h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: #1E1E1E;
            position: relative;
            text-align: center;

            
            @include breakpoint("max-sm") {
                font-size: 24px;
                line-height: 32px;
            }

            &:before {
                position: absolute;
                content: '';
                height: 3px;
                background: #00AEEF;
                width: 81px;
                right: 50%;
                bottom: -11px;
                transform: translateX(50%);
            }
        }

    }

    .all-image-center-alignment {
        @include flex-center;
        justify-content: space-between;

        @include breakpoint("max-md") {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        @include breakpoint("max-sm") {
            display: block;
        }

        div {
            @include breakpoint("max-md") {
                margin: 0 20px 20px 0;
            }

            @include breakpoint("max-sm") {
                margin: 0 0 20px 0;
                @include flex-center;
                justify-content: center;
            }

            img {
                max-width: 210px;
            }
        }
    }
}