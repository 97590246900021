@import '../../styles/mixins/index.scss';

.custom-loader-design {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/Image/star-bg.png") , #000;
    background-size: cover;
    width: 100%;
display: flex;
align-items: center;
    justify-content: center;
    z-index: -1;

    @keyframes crescendo {
        0%   {transform: scale(.8);}
        100% {transform: scale(1.5);}
      }

    @include breakpoint("max-md") {
        width: 100%;
        height: 100vh;
        z-index: -1;
    }

    @include breakpoint("max-sm") {
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }

    .logo-text-alignment {
        padding: 16px 0 0 0;
    }

    .logo-center-alignment,
    .logo-text-alignment {
       display: flex;
       align-items: center;
        justify-content: center;

        img {
            width: 245px;
            animation: crescendo 1.5s alternate infinite ease-in;

            @include breakpoint ("max-sm"){
                width: 80px;
            }
        }
    }
}