@import "../../../styles/mixins/global.scss";

.customs-clearance-all-content-alignment {
    padding: 24px 0 100px 0;

    @include breakpoint("max-sm") {
        padding: 24px 0 40px 0;
    }

    .box {
        background: #ffffff;
        border-radius: 8px;
        padding: 50px 80px;

        @include breakpoint("max-sm") {
            padding: 40px 30px;
        }

        .sub-box {
            background: #ffffff;
            border: 1px solid #bababa;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border-radius: 4px;
            margin: 0 0 50px 0;
            padding: 30px 0;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
                margin: 0;
            }

            .sub-box-items:nth-child(1) {
                border-right: 1px solid #bababa;
                padding-right: 30px;
                padding-left: 30px;
            }

            .sub-box-items:nth-child(2) {
                padding-right: 30px;
                padding-left: 30px;
            }

            .icon {
                height: calc(67px + 24px);
            }

            h4 {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin: 0 0 14px 0;
                color: #343434;
            }

            span {
                display: block;
                font-weight: 400;
                font-size: 16px;
                line-height: 33px;
                color: #606060;
            }
        }

        .most-grid {
            display: grid;
            grid-template-columns: 1fr 442px;
            gap: 77px;
            padding: 0 0 50px 0;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .most-grid-items {
                .image-style {
                    img {
                        width: 100%;
                    }
                }

                button {
                    border: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130.5%;
                    color: #ffffff;
                    padding: 9px 25px;
                    background: #00aeef;
                    border-radius: 7px;
                    cursor: pointer;
                }

                p {
                    font-weight: 400;
                    font-size: 16px;
                    margin: 0 0 40px 0;
                    line-height: 23px;
                    color: #606060;
                }
            }
        }

        .text-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 60px;
            padding: 0 0 190px 0;

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .text-grid-items {
                ul {
                    padding-left: 1.25rem;

                    li {
                        font-weight: 400;
                        font-size: 16px;
                        max-width: 453px;
                        line-height: 34px;
                        color: #606060;
                    }
                }
            }
        }

        .sub-text {
            h6 {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                color: #343434;
                max-width: 524px;
                margin: 0 auto;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                margin: 10px 0 30px 0;
                color: #606060;
            }
        }

        .center-text {
            padding: 0 0 50px 0;

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                margin: 0 auto;
                color: #606060;
                max-width: 907px;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr 513px;
            gap: 35px;
            padding: 0 0 50px 0;
            align-items: center;

            @include breakpoint("max-md") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            .grid-items {
                img {
                    height: 377px;
                    width: 100%;

                    @include breakpoint("max-sm") {
                        height: 100%;
                    }

                }

                .button-text-alignment {
                    display: flex;
                    align-items: center;
                    gap: 11px;
                    padding: 30px 0 0 0;

                    a {
                        display: block;
                        cursor: pointer;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 130.5%;
                        color: #00aeef;
                    }

                    button {
                        background: #00aeef;
                        border-radius: 7px;
                        padding: 6px 18px;
                        border: none;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: #fff;
                        cursor: pointer;
                    }
                }

                p {
                    margin: 0 0 14px 0;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    color: #343434;
                }

                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    color: #606060;
                    max-width: 521px;
                    display: block;
                }
            }
        }
    }

    .essace-customs-clener-alignment {
        padding: 50px 0 0 0;

        .essace-customs-clener-grid {
            display: grid;
            grid-template-columns: 509px 1fr;
            gap: 50px;

            @include breakpoint("max-md") {
                grid-template-columns: 1fr;
                gap: 40px;
            }

            .essace-customs-clener-gridItem {
                .essace-custom-clener-img {
                    width: 100%;
                    height: 365px;

                    @include breakpoint("max-sm") {
                        height: 100%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }

                .essace-custom-clener-all-details {

                    h6 {
                        color: #343434;
                        font-size: 20px;
                       font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: #606060;
                        font-size: 16px;
                       font-family: 'Poppins', sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130.5%;
                        padding: 16px 0 0 0;


                    }

                    .essace-button-alignment {
                        padding: 36px 0 0 0;

                        button {
                            width: 160px;
                            height: 40px;
                            border-radius: 7px;
                            background: #00AEEF;
                            color: #FFF;
                            font-size: 16px;
                           font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            line-height: 130.5%;
                            cursor: pointer;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .stages-clener-all-content-alignment {
        padding: 50px 0 0 0;

        h6 {
            color: #343434;
            font-size: 20px;
           font-family: 'Poppins', sans-serif;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            padding: 0 0 14px 0;
        }

        p {
            max-width: 904px;
            color: #606060;
            text-align: center;
            font-size: 16px;
           font-family: 'Poppins', sans-serif;
            font-weight: 400;
            line-height: 147%;
            margin: 0 auto;
        }
    }

    .custom-clener-other-option-alignment {
        padding: 46px 0 50px 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;

        .custom-clener-other-option-name {
            .custom-clener-other-option-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                color: #606060;
                text-align: center;
                font-size: 16px;
               font-family: 'Poppins', sans-serif;
                font-weight: 400;
                line-height: 130.5%;
                max-width: 250px;
                margin: -32px auto 0 auto;
            }
        }
    }
}