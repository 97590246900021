
@import "../../styles/mixins/global.scss";

.blog-main-section {
  background: #e5e5e5;
  
  .slide-section {
    position: relative;
    .slide-img {
      width: 100%;

      img {
        width: 100%;
      }
    }
    .slider-text {
      margin: auto;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: auto;
      right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      h2 {
        font-weight: 600;
        font-size: 54px;
        line-height: 65px;
        font-family: 'Poppins', sans-serif;
        color: #fff;
        margin-bottom: 20px;
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #f2f2f2;
        font-family: 'Poppins', sans-serif;
        width: 35%;
        margin: 0 auto;

        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  .tab-section {
    margin-bottom: 40px;
    .tab-main {
      display: flex;
      justify-content: center;
      background: #ffffff;
      margin: 0;
      li {
        list-style: none;
        padding: 40px 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #606060;
        display: inline;
        cursor: pointer;

        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 22px;
        }
        &.active {
          color: #00aeef;
          padding-bottom: 5px;
        }
      }
    }
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    .post-section {
      padding-bottom: 100px;
      .grid-main {
        display: grid;
        grid-template-columns: 385px 1fr;
        gap: 36px;
        .grid-item {
          .resent-item {
            h2 {
              font-weight: 700;
              font-size: 24px;
              line-height: 29px;
              color: #343434;
              margin-top: 0;
              @include breakpoint("max-sm") {
                font-size: 20px;
                line-height: 30px;
              }
              
            }
            .post-item {
              display: flex;
              padding: 10px;
              background: #ffffff;
              border-radius: 5px;
              margin-bottom: 14px;
              .image-text {
                padding-left: 12px;
                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  color: #606060;
                  font-family: 'Poppins', sans-serif;
                  margin-bottom: 25px;
                  margin-top: 0;
                }
                button {
                  color: #00aeef;
                  text-decoration: underline;
                  border: none;
                  background: none;
                }
              }
            }
          }
          .grid-section {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
            margin-bottom: 40px;
            
            .post-main{
                background: #FFFFFF;
                border-radius: 5px;
                padding: 12px;
                .post-img{
                    border-radius: 5px;
                    margin-bottom: 15px;
                    width: 100%;
                    height: 250px;
                    img{
                      width: 100%;
                      height: 100%;
                      display: block;
                      @include pointer;
                    }
                }
                .img-caption{
                    display: flex;
                    margin-bottom: 20px;
                    .icon-section{
                        display: flex;
                        align-items: center;
                        margin-right: 24px;
                        span{
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            color: #606060;
                            padding-left: 5px;
                        }
                    }
                }
                .img-content{
                    h2{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #343434;
                       font-family: 'Poppins', sans-serif;
                        margin-bottom: 12px;
                    }
                    p{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #606060;
                       font-family: 'Poppins', sans-serif;
                        margin-bottom: 18px;
                    }
                    button{
                        color: #00aeef;
                        text-decoration: underline;
                        border: none;
                        background: none;
                    }
                }
            }
          }
          .pegination{
            display: flex;
            gap: 12px;
            .number{
                background: #FFFFFF;
                border: 1px solid #00AEEF;
                color: #00AEEF;
                border-radius: 5px;
                padding: 8px 15px;
                &.arrow{
                    border: 1px solid #CCCCCC;

                }
                &.active{
                    background: #00AEEF;
                    color: #fff;
                }
            }
          }
        }
      }
    }
  }
}
