@import "../../../styles/mixins/global.scss";

.what-is-lsq-section {
    padding: 102px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 40px 0  0 0;
    }

    .what-is-lsq-grid {
        display: grid;
        grid-template-columns: 1fr 344px;
        gap: 42px;

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px;
        }

        .what-is-lsq-grid-item {
            .what-is-lsq-ledt-details-alignment {
                h6 {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26px;
                    padding: 0 0 14px 0;

                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    color: var(--theme-heading-text-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    padding: 0 0 30px 0;
                    border-bottom: 1px solid #000;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }

                .lsq-number-details-alignmwnt {
                    padding: 24px 80px 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @include breakpoint("max-sm") {
                        padding: 30px 0 40px 0;
                        display: grid;
                        grid-template-columns: repeat(1 , 1fr);
                        gap: 20px;
                    }

                    p {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 21px;
                        border-bottom: none;
                        padding: 0;
                    }

                    span {
                        color: var(--theme-heading-text-color);
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}