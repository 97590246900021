@import "../../../styles/mixins/global.scss";

.route-planner-contnet-all-alignment {
  padding: 50px 0 100px 0;


  @include breakpoint("max-sm") {
    padding: 50px 0;
  }

  .ready-content {
    h6 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #00aeef;
      margin: 0 0 24px 0;
      text-align: center;

      @include breakpoint("max-sm") {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .video-image {
      max-width: 588px;
      margin: 0 auto;
      padding: 50px 0 0 0;

      img {
        width: 100%;
      }
    }

    .btn-center {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        border: none;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding: 10px 41px;
        background: #00aeef;
        border-radius: 4px;
        color: #ffffff;

        cursor: pointer;
      }
    }
  }

  .text-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 101px;
    padding: 0 0 50px 0;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .text-grid-items {
      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        color: #343434;
        margin: 0 0 22px 0;
      }

      ul {
        padding-left: 1.25rem;

        li {
          font-weight: 400;
          font-size: 15px;
          padding: 0 0 10px 0;
          line-height: 22px;
          text-transform: capitalize;
          color: #606060;
        }
      }

      p {
        margin: 0 0 24px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-transform: capitalize;
        color: #606060;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;
    padding: 0 0 50px 0;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .grid-items {
      background: #ffffff;
      padding: 57px 37px;
      box-shadow: 4px 4px 28px rgba(0, 0, 0, 0.06);
      border-radius: 8px;

      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin: 23px 0;
        color: #343434;

        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 30px;
        }
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #606060;
        opacity: 0.6;
      }
    }
  }
}