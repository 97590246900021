@import '../../../styles/mixins/global.scss';

.online-freight-marketplace-section {
    .online-freight-marketplace-box {
        border-radius: 8px;
        background: #EBF9FE;
        padding: 24px;
        width: 100%;
        height: 402px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        h6 {
            color: #343434;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-transform: capitalize;
        }

        p {
            color: #606060;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-transform: capitalize;
            padding: 5px 0 0 0;
            max-width: 620px;
            margin: 0 auto;
        }
    }
}