@import "../../styles/mixins/index.scss";
$breakpoint-xs: 576px; //New Scss
$breakpoint-sm: 768px; //New Scss

.oprations-counsil-all-content-alignment {
  background-color: #f8f8f8;

  .we-care-section {
    padding: 0 0 65px 0;

    .background {
      background: #4c7f92;
      border-radius: 10px;
      padding: 40px 0;

      @include breakpoint("max-sm") {
        border-radius: 0px;
      }

      .image-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint("max-sm") {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
        }

        div {
          background-color: #fff;
          padding: 10px;
        }
      }
    }

    .heading-text {
      padding: 0 0 40px 0;

      p {
        color: rgba(0, 0, 0, 0.8);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        line-height: 46px;
        line-height: normal;

        @include breakpoint("max-sm") {
          font-size: 20px;
        }
      }
    }
  }

  .text {
    padding: 40px 0 100px 0;

    @include breakpoint("max-sm") {
      padding: 40px 0;
    }

    p {
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      max-width: 80%;
      font-style: normal;
      margin: 0 0 24px 0;
      line-height: 26px;
      font-weight: 500;

      @include breakpoint("max-sm") {
        font-size: 14px;
        line-height: 24px;
        max-width: 100%;
      }
    }
  }

  .first-grid {
    padding: 80px 0;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 40px;
    align-items: center;

    @include breakpoint("max-sm") {
      display: grid;
      padding: 60px 0;
      grid-template-columns: repeat(1, 1fr);
    }

    .first-grid-items:last-child {
      @include breakpoint("max-sm") {
        display: flex;
        justify-content: center;
      }
    }

    h4 {
      color: rgba(0, 0, 0, 0.8);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      line-height: normal;
      max-width: 80%;
      margin: 0 0 24px 0;

      @include breakpoint("max-sm") {
        max-width: 100%;
        font-size: 20px;
        line-height: 34px;
      }
    }

    span {
      display: block;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      max-width: 80%;
      font-style: normal;
      margin: 0 0 24px 0;
      line-height: 26px;
      font-weight: 500;

      @include breakpoint("max-sm") {
        font-size: 14px;
        line-height: 24px;
        max-width: 100%;
      }
    }
  }

  .founder {
    display: grid;
    grid-template-columns: 384px 1fr;
    gap: 100px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .founder-items {
      img {
        width: 100%;
      }

      h4 {
        color: rgba(0, 0, 0, 0.8);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
        line-height: normal;
        max-width: 80%;
        margin: 0 0 24px 0;

        @include breakpoint("max-sm") {
          max-width: 100%;
          font-size: 20px;
          line-height: 34px;
        }
      }

      p {
        max-width: 416px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 32px;

        @include breakpoint("max-sm") {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}


// New scss  add here...
// Media Queries
$breakpoint-xs-minus-1: calc(#{$breakpoint-xs} - 1px);

@media (max-width: $breakpoint-xs-minus-1) {

  .oprations-counsil-all-content-alignment {
    .first-grid {
      display: block;
      grid-template-columns: auto;
    }

    .founder {
      display: block;
      grid-template-columns: auto;
    }
  }

}

@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {

  .oprations-counsil-all-content-alignment {
    .first-grid {
      display: block;
      grid-template-columns: auto;
    }

    .founder {
      display: block;
      grid-template-columns: 1fr 250px;
    }
  }

}

.oprations-counsil-all-content-alignment {
  .first-grid {
    @media (min-width: 769px) and (max-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 250px;
      gap: 5px;
    }
  }

  .founder {
    @media (min-width: 769px) and (max-width: 992px) {
      display: grid;
    grid-template-columns: 350px 1fr;
    gap: 50px;

    }
  }
}

// Working On This... 